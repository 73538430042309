var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import { mobileDevice } from "style/constants";
var Navbar = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: 20% 60% 20%;\n  grid-template-rows: 20px;\n"], ["\n  display: grid;\n  grid-template-columns: 20% 60% 20%;\n  grid-template-rows: 20px;\n"])));
Navbar.ArrowLeft = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  grid-area: 1/1/1/1;\n  display: flex;\n  justify-content: center;\n  > svg {\n    cursor: pointer;\n  }\n"], ["\n  grid-area: 1/1/1/1;\n  display: flex;\n  justify-content: center;\n  > svg {\n    cursor: pointer;\n  }\n"])));
Navbar.Caption = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-family: \"Roboto-bold\";\n  font-size: 18px;\n  grid-area: 1/2/1/2;\n  display: flex;\n  justify-content: center;\n  text-transform: capitalize;\n"], ["\n  font-family: \"Roboto-bold\";\n  font-size: 18px;\n  grid-area: 1/2/1/2;\n  display: flex;\n  justify-content: center;\n  text-transform: capitalize;\n"])));
Navbar.ArrowRight = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  grid-area: 1/3/1/3;\n  display: flex;\n  justify-content: center;\n  > svg {\n    cursor: pointer;\n  }\n"], ["\n  grid-area: 1/3/1/3;\n  display: flex;\n  justify-content: center;\n  > svg {\n    cursor: pointer;\n  }\n"])));
export var WeekDay = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-family: \"Roboto-medium\";\n  font-size: 15px;\n  color: #495057;\n  padding: 10px !important;\n  text-transform: uppercase;\n  text-align: center;\n  @media ", " {\n    padding: 0px !important;\n  }\n"], ["\n  font-family: \"Roboto-medium\";\n  font-size: 15px;\n  color: #495057;\n  padding: 10px !important;\n  text-transform: uppercase;\n  text-align: center;\n  @media ", " {\n    padding: 0px !important;\n  }\n"])), mobileDevice);
export var Day = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  width: 40px;\n  height: 40px;\n  justify-content: center;\n  border-radius: 50%;\n  align-items: center;\n  padding: 7px;\n  &:hover {\n    cursor: pointer;\n  }\n  @media ", " {\n    width: 25px;\n    height: 25px;\n  }\n"], ["\n  display: flex;\n  width: 40px;\n  height: 40px;\n  justify-content: center;\n  border-radius: 50%;\n  align-items: center;\n  padding: 7px;\n  &:hover {\n    cursor: pointer;\n  }\n  @media ", " {\n    width: 25px;\n    height: 25px;\n  }\n"])), mobileDevice);
Day.Value = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-family: \"Roboto\";\n  font-size: 15px;\n  text-align: center;\n  @media ", " {\n  }\n"], ["\n  font-family: \"Roboto\";\n  font-size: 15px;\n  text-align: center;\n  @media ", " {\n  }\n"])), mobileDevice);
export default Navbar;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
