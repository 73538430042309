var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
var RuleSelection = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  > div {\n    box-sizing: border-box;\n    min-height: 255px;\n    width: 100%;\n  }\n"], ["\n  > div {\n    box-sizing: border-box;\n    min-height: 255px;\n    width: 100%;\n  }\n"])));
RuleSelection.Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  margin-top: 20px;\n  width: 100%;\n"], ["\n  display: flex;\n  flex-direction: column;\n  margin-top: 20px;\n  width: 100%;\n"])));
var Section = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
Section.Title = styled.h2(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 24px;\n  font-style: normal;\n  font-weight: bold;\n  line-height: 28px;\n  margin: 0;\n  padding: 0;\n"], ["\n  color: ", ";\n  font-family: ", ";\n  font-size: 24px;\n  font-style: normal;\n  font-weight: bold;\n  line-height: 28px;\n  margin: 0;\n  padding: 0;\n"])), function (props) { return props.theme.colors.black212; }, function (props) { return props.theme.fontFamily.primary; });
Section.Help = styled.span(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: ", ";\n  font-family: ", ";\n  font-size: 14px;\n  font-style: normal;\n  font-weight: normal;\n  line-height: 20px;\n"], ["\n  color: ", ";\n  font-family: ", ";\n  font-size: 14px;\n  font-style: normal;\n  font-weight: normal;\n  line-height: 20px;\n"])), function (props) { return props.theme.colors.gray495; }, function (props) { return props.theme.fontFamily.primary; });
RuleSelection.RuleItem = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  background-color: ", ";\n  border-radius: 4px;\n  display: flex;\n  flex-direction: row;\n  margin-bottom: 10px;\n  padding: 15px;\n"], ["\n  background-color: ", ";\n  border-radius: 4px;\n  display: flex;\n  flex-direction: row;\n  margin-bottom: 10px;\n  padding: 15px;\n"])), function (props) { return props.theme.colors.grayf8f; });
RuleSelection.Data = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  width: 100%;\n"], ["\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  width: 100%;\n"])));
RuleSelection.MainData = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n"], ["\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n"])));
RuleSelection.RuleName = styled.span(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  align-items: center;\n  color: ", ";\n  display: flex;\n  font-family: ", ";\n  font-size: 14px;\n  font-style: normal;\n  font-weight: bold;\n  line-height: 16px;\n  margin-right: 5px;\n"], ["\n  align-items: center;\n  color: ", ";\n  display: flex;\n  font-family: ", ";\n  font-size: 14px;\n  font-style: normal;\n  font-weight: bold;\n  line-height: 16px;\n  margin-right: 5px;\n"])), function (props) { return props.theme.colors.black212; }, function (props) { return props.theme.fontFamily.primary; });
RuleSelection.RuleDescription = styled.span(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  align-items: center;\n  color: ", ";\n  font-family: ", ";\n  font-size: 14px;\n  font-style: normal;\n  font-weight: normal;\n  line-height: 16px;\n"], ["\n  align-items: center;\n  color: ", ";\n  font-family: ", ";\n  font-size: 14px;\n  font-style: normal;\n  font-weight: normal;\n  line-height: 16px;\n"])), function (props) { return props.theme.colors.gray495; }, function (props) { return props.theme.fontFamily.primary; });
RuleSelection.Extra = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
RuleSelection.DateRangeLegend = styled.span(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 14px;\n  margin: 5px;\n"], ["\n  color: ", ";\n  font-size: 14px;\n  margin: 5px;\n"])), function (props) { return props.theme.colors.black212; });
RuleSelection.DeleteContainer = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  align-items: center;\n  color: ", ";\n  cursor: pointer;\n  display: flex;\n  font-family: Roboto-Bold;\n  font-size: 12px;\n  text-align: center;\n  width: 15%;\n"], ["\n  align-items: center;\n  color: ", ";\n  cursor: pointer;\n  display: flex;\n  font-family: Roboto-Bold;\n  font-size: 12px;\n  text-align: center;\n  width: 15%;\n"])), function (props) { var _a; return (_a = props.theme.fonts) === null || _a === void 0 ? void 0 : _a.red; });
var Controls = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  flex-direction: row;\n  padding: 10px 0;\n  width: 100%;\n\n  & > div {\n    width: 100%;\n  }\n"], ["\n  align-items: center;\n  display: flex;\n  flex-direction: row;\n  padding: 10px 0;\n  width: 100%;\n\n  & > div {\n    width: 100%;\n  }\n"])));
var DropContainer = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
var Selectable = styled.div(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
var NewButton = styled.div(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  cursor: pointer;\n  font-size: 16px;\n  color: ", ";\n  font-family: Roboto;\n"], ["\n  cursor: pointer;\n  font-size: 16px;\n  color: ", ";\n  font-family: Roboto;\n"])), function (props) { var _a, _b; return (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.colors) === null || _b === void 0 ? void 0 : _b.primary; });
export { RuleSelection, Section, Controls, DropContainer, Selectable, NewButton, };
export default RuleSelection;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17;
