import React from "react";
import { ToolTip, ToolTipText } from "./styled";
var Component = function (props) {
    var children = props.children, content = props.content;
    return (React.createElement(ToolTip, null,
        children,
        React.createElement(ToolTipText, null, content)));
};
export { Component as Tooltip };
export default Component;
