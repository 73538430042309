var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { useFormContext } from "react-hook-form";
import { CustomInput, CustomSelect } from "components";
import { UserForm, Controls } from "./styled";
import { mockUserTypes } from "mock";
var Component = function () {
    var _a, _b, _c, _d;
    var _e = useFormContext(), register = _e.register, errors = _e.formState.errors;
    return (React.createElement(UserForm, null,
        React.createElement(Controls, null,
            React.createElement(CustomInput, __assign({ label: "Nombre", placeholder: "Escribe un nombre..." }, register("name"), { error: (_a = errors.name) === null || _a === void 0 ? void 0 : _a.message })),
            React.createElement(CustomInput, __assign({ label: "Apellido", placeholder: "Escribe un apellido..." }, register("lastName"), { error: (_b = errors.lastName) === null || _b === void 0 ? void 0 : _b.message })),
            React.createElement(CustomInput, __assign({ label: "Correo electronico", placeholder: "Escribe un correo electronico..." }, register("email"), { error: (_c = errors.email) === null || _c === void 0 ? void 0 : _c.message })),
            React.createElement(CustomSelect, __assign({ label: "Tipo de usuario", placeholder: "Seleccione un tipo" }, register("userType"), { error: (_d = errors.userType) === null || _d === void 0 ? void 0 : _d.message, data: mockUserTypes })))));
};
export { Component as UserForm };
export default Component;
