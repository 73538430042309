var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import { getImageSize } from "utils/images";
import attachImage from "assets/attach-image.svg";
var CustomFileInput = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  border-radius: 5px;\n  cursor: pointer;\n  height: ", ";\n  padding: 0 5px 5px 5px;\n  width: 200px;\n"], ["\n  background-color: ", ";\n  border-radius: 5px;\n  cursor: pointer;\n  height: ", ";\n  padding: 0 5px 5px 5px;\n  width: 200px;\n"])), function (props) { var _a; return (_a = props === null || props === void 0 ? void 0 : props.theme) === null || _a === void 0 ? void 0 : _a.colors.blueeaf; }, function (props) { return getImageSize(props.size); });
CustomFileInput.Image = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-image: url(", ");\n  background-repeat: no-repeat;\n  background-position-x: center;\n  background-position-y: center;\n  background-size: initial;\n  border-radius: 5px;\n  height: 100%;\n  width: 100%;\n"], ["\n  background-image: url(", ");\n  background-repeat: no-repeat;\n  background-position-x: center;\n  background-position-y: center;\n  background-size: initial;\n  border-radius: 5px;\n  height: 100%;\n  width: 100%;\n"])), attachImage);
CustomFileInput.File = styled.input(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: none;\n"], ["\n  display: none;\n"])));
export default CustomFileInput;
var templateObject_1, templateObject_2, templateObject_3;
