var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
var Profile = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 100%;\n  padding: 10px;\n  font-family: \"Roboto\";\n  width: 100%;\n"], ["\n  height: 100%;\n  padding: 10px;\n  font-family: \"Roboto\";\n  width: 100%;\n"])));
Profile.DataContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 10px;\n"], ["\n  padding: 10px;\n"])));
Profile.Data = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  align-items: center;\n  color: ", ";\n  display: flex;\n  height: 38px;\n  margin-bottom: 15px;\n"], ["\n  align-items: center;\n  color: ", ";\n  display: flex;\n  height: 38px;\n  margin-bottom: 15px;\n"])), function (props) { var _a; return (_a = props === null || props === void 0 ? void 0 : props.theme) === null || _a === void 0 ? void 0 : _a.colors.black212; });
Profile.Edit = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 14px;\n  font-weight: normal;\n  text-decoration: none;\n  cursor: pointer;\n  user-select: none;\n"], ["\n  color: ", ";\n  font-size: 14px;\n  font-weight: normal;\n  text-decoration: none;\n  cursor: pointer;\n  user-select: none;\n"])), function (props) { var _a; return (_a = props === null || props === void 0 ? void 0 : props.theme) === null || _a === void 0 ? void 0 : _a.colors.blue00c5; });
Profile.Name = styled.h1(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-size: 32px;\n  margin-right: 16px;\n"], ["\n  font-size: 32px;\n  margin-right: 16px;\n"])));
Profile.Email = styled.h5(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  color: ", ";\n  font-family: \"Roboto\";\n  font-size: 16px;\n  margin-right: 16px;\n"], ["\n  color: ", ";\n  font-family: \"Roboto\";\n  font-size: 16px;\n  margin-right: 16px;\n"])), function (props) { var _a; return (_a = props === null || props === void 0 ? void 0 : props.theme) === null || _a === void 0 ? void 0 : _a.colors.gray495; });
export default Profile;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
