var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useContext } from "react";
import Context from "app/Context";
import { useFormContext } from "react-hook-form";
import { ImageEdit, InputTitle, Row } from "./styled";
import { CustomInput, Segment, Label, SelectImage } from "components";
var Component = function () {
    var _a, _b;
    var _c = useContext(Context), isSpanish = _c.isSpanish, isEnglish = _c.isEnglish;
    var _d = useFormContext(), register = _d.register, control = _d.control, errors = _d.formState.errors;
    return (React.createElement(Segment, null,
        React.createElement(Row, null,
            React.createElement(InputTitle, null,
                isSpanish && (React.createElement(CustomInput, __assign({ label: "Nombre" }, register("itemGallery.title"), { placeholder: "e.g., Excursionismo", error: (_b = (_a = errors.itemGallery) === null || _a === void 0 ? void 0 : _a.title) === null || _b === void 0 ? void 0 : _b.message }))),
                isEnglish && (React.createElement(CustomInput, __assign({ label: "Name" }, register("itemGallery.englishTitle"), { placeholder: "e.g., Hiking" })))),
            React.createElement(ImageEdit, null,
                React.createElement(Label, null, "Imagen"),
                React.createElement(SelectImage, { fieldName: "itemGallery.picture" })))));
};
export default Component;
export { Component as TitleImage };
