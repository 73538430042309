var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { mobileDevice } from "style/constants";
import styled from "styled-components";
import Component from "../";
import Styled from "../styled";
var CarouselHeader = styled(Component)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", " {\n    margin: -125px 0 0 0;\n    @media ", " {\n      margin: -50px 0 0 0;\n    }\n  }\n  ", " {\n    width: 100%;\n    height: 700px;\n    justify-content: unset;\n    > * {\n      max-width: none;\n      border-radius: unset;\n      margin-left: 0;\n      margin-right: 0;\n    }\n\n    @media ", " {\n      height: 300px;\n    }\n  }\n"], ["\n  ", " {\n    margin: -125px 0 0 0;\n    @media ", " {\n      margin: -50px 0 0 0;\n    }\n  }\n  ", " {\n    width: 100%;\n    height: 700px;\n    justify-content: unset;\n    > * {\n      max-width: none;\n      border-radius: unset;\n      margin-left: 0;\n      margin-right: 0;\n    }\n\n    @media ", " {\n      height: 300px;\n    }\n  }\n"])), Styled.Carousel, mobileDevice, Styled.Content, mobileDevice);
export default CarouselHeader;
var templateObject_1;
