var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { keyframes } from "styled-components";
var toastAnimaitionFadeIn = keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  from {\n    transform: translateY(-100%);\n  }\n  to {\n    transform: translateY(0)\n  }\n"], ["\n  from {\n    transform: translateY(-100%);\n  }\n  to {\n    transform: translateY(0)\n  }\n"])));
var toastAnimaitionFadeOut = keyframes(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n   from {\n    transform: translateY(0);\n  }\n  to {\n    transform: translateY(-100%)\n  }\n"], ["\n   from {\n    transform: translateY(0);\n  }\n  to {\n    transform: translateY(-100%)\n  }\n"])));
var toastAnimaitionSlideIn = keyframes(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  from {\n    transform: translateY(-100%, 0);\n  }\n"], ["\n  from {\n    transform: translateY(-100%, 0);\n  }\n"])));
export var ToastContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: 14px;\n  box-sizing: border-box;\n  position: fixed;\n  transition: transform 0.6s ease-in-out;\n  left: 45%;\n  right: 50%;\n  top: 15px;\n  animation: ", " ease 0.3s;\n"], ["\n  font-size: 14px;\n  box-sizing: border-box;\n  position: fixed;\n  transition: transform 0.6s ease-in-out;\n  left: 45%;\n  right: 50%;\n  top: 15px;\n  animation: ", " ease 0.3s;\n"])), toastAnimaitionFadeIn);
export var Toast = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  background-color: ", ";\n  transition: 0.3s ease;\n  position: relative;\n  pointer-events: auto;\n  overflow: hidden;\n  width: fit-content;\n  min-width: 350px;\n  height: 60px;\n  border-radius: 10px;\n  padding: 10px;\n  display: flex;\n  justify-content: space-between;\n  box-sizing: border-box;\n  color: ", ";\n"], ["\n  background-color: ", ";\n  transition: 0.3s ease;\n  position: relative;\n  pointer-events: auto;\n  overflow: hidden;\n  width: fit-content;\n  min-width: 350px;\n  height: 60px;\n  border-radius: 10px;\n  padding: 10px;\n  display: flex;\n  justify-content: space-between;\n  box-sizing: border-box;\n  color: ", ";\n"])), function (props) {
    return props.type == "success"
        ? props.theme.colors.greenDEF
        : props.type == "danger"
            ? props.theme.colors.redFFD
            : props.theme.colors.yellowFFF;
}, function (props) {
    return props.type == "success"
        ? props.theme.colors.green219
        : props.type == "danger"
            ? props.theme.colors.red9E0
            : props.theme.colors.yellowAE7;
});
export var Button = styled.button(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  position: relative;\n  float: right;\n  font-weight: 700;\n  color: ", ";\n  outline: none;\n  border: none;\n  text-shadow: 0 1px 0 #fff;\n  opacity: 0.8;\n  line-height: 1;\n  padding: 0;\n  cursor: pointer;\n  background: 0 0;\n  border: 0;\n  font-size: 20px;\n"], ["\n  position: relative;\n  float: right;\n  font-weight: 700;\n  color: ", ";\n  outline: none;\n  border: none;\n  text-shadow: 0 1px 0 #fff;\n  opacity: 0.8;\n  line-height: 1;\n  padding: 0;\n  cursor: pointer;\n  background: 0 0;\n  border: 0;\n  font-size: 20px;\n"])), function (props) { return props.theme.colors.gray; });
export var ToastTitle = styled.p(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-weight: 700;\n  font-size: 16px;\n  font-family: ", ";\n  align-self: center;\n"], ["\n  font-weight: 700;\n  font-size: 16px;\n  font-family: ", ";\n  align-self: center;\n"])), function (props) { var _a, _b; return (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.fontFamily) === null || _b === void 0 ? void 0 : _b.primary; });
export var Icon = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  align-self: center;\n  font-size: 20px;\n"], ["\n  align-self: center;\n  font-size: 20px;\n"])));
export var Content = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 10px;\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 10px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
