var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from "react";
import { navigate } from "@reach/router";
import { useStoreActions, useStoreState } from "state/hooks";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Loader, Title, UserForm, Segment, ActionButtons } from "components";
import { MainColumn } from "components/Layout";
var schema = yup.object().shape({
    name: yup.string().required("Ingrese su nombre"),
    lastName: yup.string().required("Ingrese su apellido"),
    email: yup
        .string()
        .email("Se debe de ingresar un email correcto")
        .required("Ingrese el correo"),
    userType: yup.string().required("Ingrese el tipo de usuario"),
});
var EditUser = function () {
    var _a, _b, _c, _d;
    var _e = useStoreState(function (store) { return store; }).user, selectedUser = _e.user, isLoading = _e.isLoading;
    var _f = useStoreActions(function (store) { return store; }).user, setUser = _f.setUser, saveUser = _f.saveUser, updateUser = _f.updateUser;
    var title = selectedUser === null ? "Nuevo" : "Editar";
    var userToShow = {
        name: (_a = selectedUser === null || selectedUser === void 0 ? void 0 : selectedUser.name) !== null && _a !== void 0 ? _a : "",
        lastName: (_b = selectedUser === null || selectedUser === void 0 ? void 0 : selectedUser.lastName) !== null && _b !== void 0 ? _b : "",
        email: (_c = selectedUser === null || selectedUser === void 0 ? void 0 : selectedUser.email) !== null && _c !== void 0 ? _c : "",
        password: "",
        confirmPassword: "",
        userType: (_d = selectedUser === null || selectedUser === void 0 ? void 0 : selectedUser.typeUser) !== null && _d !== void 0 ? _d : "",
    };
    var methods = useForm({
        resolver: yupResolver(schema),
        defaultValues: userToShow,
    });
    var handleSubmit = methods.handleSubmit;
    var goBack = function () {
        navigate("/admin/usuarios");
    };
    var onSubmit = function (user) { return __awaiter(void 0, void 0, void 0, function () {
        var name, lastName, email, userType;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    name = user.name, lastName = user.lastName, email = user.email, userType = user.userType;
                    if (!selectedUser) return [3 /*break*/, 2];
                    return [4 /*yield*/, updateUser({
                            user: {
                                id: selectedUser.id,
                                name: name,
                                lastName: lastName,
                                email: email,
                                typeUser: userType,
                                isActive: true,
                            },
                        })];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, saveUser({
                        user: {
                            name: name,
                            lastName: lastName,
                            email: email,
                            typeUser: userType,
                            isActive: true,
                        },
                    })];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4:
                    setUser(null);
                    goBack();
                    return [2 /*return*/];
            }
        });
    }); };
    var onCancel = function () {
        setUser(null);
        goBack();
    };
    var renderLoader = isLoading && React.createElement(Loader, null);
    return (React.createElement(MainColumn, null,
        React.createElement(Segment, null,
            renderLoader,
            React.createElement(Title, { variant: "segment" }, title + " usuario"),
            React.createElement(FormProvider, __assign({}, methods),
                React.createElement(UserForm, null))),
        React.createElement(ActionButtons, { handleSubmit: handleSubmit(onSubmit), handleCancel: onCancel })));
};
export { EditUser };
export default EditUser;
