var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var DropdownContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  cursor: pointer;\n  height: 100%;\n  position: relative;\n"], ["\n  cursor: pointer;\n  height: 100%;\n  position: relative;\n"])));
export var Header = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: grid;\n  padding: 16px;\n  border: solid 1px ", ";\n  border-radius: 4px;\n  font-size: 14px;\n  font-family: \"Roboto\";\n  background-color: ", ";\n  grid-template:\n    \"headerContent icon\" 100%/\n    95% 5%;\n  align-items: center;\n"], ["\n  display: grid;\n  padding: 16px;\n  border: solid 1px ", ";\n  border-radius: 4px;\n  font-size: 14px;\n  font-family: \"Roboto\";\n  background-color: ", ";\n  grid-template:\n    \"headerContent icon\" 100%/\n    95% 5%;\n  align-items: center;\n"])), function (props) { return props.theme.colors.gray; }, function (props) { return props.theme.colors.white; });
export var HeaderContent = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  grid-area: headerContent;\n  font-size: 14px;\n"], ["\n  grid-area: headerContent;\n  font-size: 14px;\n"])));
export var Icon = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  grid-area: icon;\n  display: flex;\n  justify-content: center;\n  font-size: 1.5em;\n"], ["\n  grid-area: icon;\n  display: flex;\n  justify-content: center;\n  font-size: 1.5em;\n"])));
export var DropdownListContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: absolute;\n  height: auto;\n  top: 70%;\n  width: 100%;\n  z-index: 2;\n"], ["\n  position: absolute;\n  height: auto;\n  top: 70%;\n  width: 100%;\n  z-index: 2;\n"])));
export var DropdownList = styled.ul(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  background-color: ", ";\n  border: solid 1px ", ";\n  border-radius: 4px;\n  height: 100%;\n  padding-left: 0;\n  position: relative;\n  overflow-y: auto;\n"], ["\n  background-color: ", ";\n  border: solid 1px ", ";\n  border-radius: 4px;\n  height: 100%;\n  padding-left: 0;\n  position: relative;\n  overflow-y: auto;\n"])), function (props) { return props.theme.colors.white; }, function (props) { return props.theme.colors.gray; });
export var ListItem = styled.li(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  height: 1.5rem;\n  list-style: none;\n  margin: unset;\n  padding: 1em;\n  font-family: \"Roboto\";\n  background-color: ", ";\n  &:hover {\n    background-color: ", ";\n  }\n"], ["\n  height: 1.5rem;\n  list-style: none;\n  margin: unset;\n  padding: 1em;\n  font-family: \"Roboto\";\n  background-color: ", ";\n  &:hover {\n    background-color: ", ";\n  }\n"])), function (props) { return props.theme.colors.white; }, function (props) { return props.theme.colors.grayf8f; });
export var IconItem = styled.img(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  width: 1.5rem;\n  height: 1.5rem;\n"], ["\n  width: 1.5rem;\n  height: 1.5rem;\n"])));
export var Item = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  height: 100%;\n  width: 100%;\n\n  > img {\n    margin-right: 1%;\n  }\n"], ["\n  align-items: center;\n  display: flex;\n  height: 100%;\n  width: 100%;\n\n  > img {\n    margin-right: 1%;\n  }\n"])));
export var Label = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  font-family: \"Roboto-Medium\";\n  font-size: 14px;\n  margin-bottom: 5px;\n"], ["\n  font-family: \"Roboto-Medium\";\n  font-size: 14px;\n  margin-bottom: 5px;\n"])));
export var Error = styled.span(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  color: ", ";\n  font-family: \"Roboto-Medium\";\n  font-size: 12px;\n  font-weight: 500;\n"], ["\n  color: ", ";\n  font-family: \"Roboto-Medium\";\n  font-size: 12px;\n  font-weight: 500;\n"])), function (params) { var _a; return (_a = params === null || params === void 0 ? void 0 : params.theme) === null || _a === void 0 ? void 0 : _a.colors.redff6; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
