import React from "react";
import { PageInfo, ImageContainer, TitleContainer, Description, } from "./styled";
import Image from "components/ImgItem";
import Title from "components/Title";
var Component = function (props) {
    var data = props.data;
    var title = data.title, image = data.image, description = data.description;
    return (React.createElement(PageInfo, { isRequiredImage: image },
        image && (React.createElement(ImageContainer, null,
            React.createElement(Image, { src: image }))),
        React.createElement(TitleContainer, null,
            React.createElement(Title, null, title)),
        React.createElement(Description, null, description)));
};
export { Component as PageInfo };
export default Component;
