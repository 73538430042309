var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import { mobileDevice } from "style/constants";
export var MyExplorerItem = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: grid;\n  width: 400px;\n  height: fit-content;\n  grid-template:\n    \"image\" 124px\n    \"title\" 40px\n    \"description\" 60px\n    \"link\" auto\n    / 100%;\n  justify-content: center;\n  box-sizing: border-box;\n  @media ", " {\n    grid-template-columns: 100%;\n    grid-template-rows: 100px repeat(3, auto);\n  }\n"], ["\n  display: grid;\n  width: 400px;\n  height: fit-content;\n  grid-template:\n    \"image\" 124px\n    \"title\" 40px\n    \"description\" 60px\n    \"link\" auto\n    / 100%;\n  justify-content: center;\n  box-sizing: border-box;\n  @media ", " {\n    grid-template-columns: 100%;\n    grid-template-rows: 100px repeat(3, auto);\n  }\n"])), mobileDevice);
export var ImageContainer = styled.a(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  grid-area: image;\n  width: 124px;\n  height: 124px;\n  justify-self: center;\n  @media ", " {\n    width: 100px;\n    height: 100px;\n  }\n"], ["\n  grid-area: image;\n  width: 124px;\n  height: 124px;\n  justify-self: center;\n  @media ", " {\n    width: 100px;\n    height: 100px;\n  }\n"])), mobileDevice);
export var Title = styled.a(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  grid-area: title;\n  font-family: \"Roboto-Bold\";\n  font-size: 24px;\n  justify-self: center;\n  text-align: center;\n  margin: 20px 0 0 0;\n  text-decoration: none;\n  color: ", ";\n  @media ", " {\n    font-size: 18px;\n    margin-top: 16px;\n    height: fit-content;\n  }\n"], ["\n  grid-area: title;\n  font-family: \"Roboto-Bold\";\n  font-size: 24px;\n  justify-self: center;\n  text-align: center;\n  margin: 20px 0 0 0;\n  text-decoration: none;\n  color: ", ";\n  @media ", " {\n    font-size: 18px;\n    margin-top: 16px;\n    height: fit-content;\n  }\n"])), function (props) { return props.theme.colors.black; }, mobileDevice);
export var Description = styled.p(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 389px;\n  grid-area: description;\n  margin: 24px 0 0 0;\n  font-family: \"Roboto\";\n  font-size: 16px;\n  justify-self: center;\n  text-align: center;\n  @media ", " {\n    width: 90%;\n    font-size: 14px;\n    margin-top: 16px;\n  }\n"], ["\n  width: 389px;\n  grid-area: description;\n  margin: 24px 0 0 0;\n  font-family: \"Roboto\";\n  font-size: 16px;\n  justify-self: center;\n  text-align: center;\n  @media ", " {\n    width: 90%;\n    font-size: 14px;\n    margin-top: 16px;\n  }\n"])), mobileDevice);
export var Link = styled.a(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  grid-area: link;\n  width: fit-content;\n  margin: 24px 0 0 0;\n  font-family: \"Roboto\";\n  font-size: 16px;\n  color: #00c5ff;\n  justify-self: center;\n  text-decoration: none;\n  @media ", " {\n    font-size: 14px;\n    margin-top: 16px;\n  }\n"], ["\n  grid-area: link;\n  width: fit-content;\n  margin: 24px 0 0 0;\n  font-family: \"Roboto\";\n  font-size: 16px;\n  color: #00c5ff;\n  justify-self: center;\n  text-decoration: none;\n  @media ", " {\n    font-size: 14px;\n    margin-top: 16px;\n  }\n"])), mobileDevice);
export default MyExplorerItem;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
