import React, { useContext, useEffect } from "react";
import { CarouselHeader, PageInfo, Services } from "components";
import { Content, Container } from "components/Layout";
import Context from "app/Context";
import { useStoreActions, useStoreState } from "state/hooks";
var PAGE = "home";
var Home = function (_a) {
    var children = _a.children;
    var _b = useContext(Context), language = _b.language, isEnglish = _b.isEnglish;
    var getContentByPage = useStoreActions(function (actions) { return actions.content; }).getContentByPage;
    var contentByPage = useStoreState(function (store) { return store.content; }).contentByPage;
    useEffect(function () {
        getContentByPage(PAGE);
    }, []);
    var imagesToShow = contentByPage &&
        contentByPage
            .filter(function (content) { return content.type === "banner"; })
            .map(function (content) { return content.picture[0].url; });
    var pageInfoToRender = contentByPage
        .filter(function (content) { return content.type === "general"; })
        .map(function (content, index) {
        var pageInfo = {
            title: isEnglish ? content.englishTitle : content.title,
            description: isEnglish
                ? content.englishDescription
                : content.description,
        };
        return React.createElement(PageInfo, { data: pageInfo, key: "pageInfo-" + index });
    });
    var services = contentByPage
        .filter(function (content) { return content.type === "service"; })
        .map(function (service) {
        var _a;
        return {
            title: isEnglish ? service.englishTitle : service.title,
            description: isEnglish
                ? service.englishDescription
                : service.description,
            image: (_a = service.picture[0]) === null || _a === void 0 ? void 0 : _a.url,
            url: service.href
        };
    });
    return (React.createElement(Content, null,
        React.createElement(CarouselHeader, { images: imagesToShow, imagesToShow: 1, infiniteLoop: true }),
        React.createElement(Container, null,
            pageInfoToRender,
            React.createElement(Services, { data: services }))));
};
export { Home };
export default Home;
