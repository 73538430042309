var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { TitleDescription, ActionButtons, MapSelector, Title, Loader, Alert, } from "components";
import { MainColumn } from "components/Layout";
import { useStoreActions, useStoreState } from "state/hooks";
import { partition } from "lodash";
var PAGE = "colocation";
var Ubication = function () {
    var methods = useForm({
        defaultValues: {
            position: null,
            generals: {},
        },
    });
    var _a = useStoreActions(function (store) { return store; }), _b = _a.config, getConfig = _b.getConfig, saveConfig = _b.saveConfig, updateConfig = _b.updateConfig, cleanMessage = _b.cleanMessage, _c = _a.content, getContentByPage = _c.getContentByPage, createContentItems = _c.createContentItems, updateContentItems = _c.updateContentItems;
    var _d = useStoreState(function (store) { return store; }), _e = _d.config, config = _e.config, isLoading = _e.isLoading, message = _e.message, contentByPage = _d.content.contentByPage;
    var handleSubmit = methods.handleSubmit, reset = methods.reset;
    useEffect(function () {
        cleanMessage();
        getConfig({
            config: "contact",
        });
        getContentByPage(PAGE);
    }, []);
    useEffect(function () {
        var generals = (contentByPage === null || contentByPage === void 0 ? void 0 : contentByPage.filter(function (contentItem) { return contentItem.type === "general"; })[0]) || {};
        if (!config)
            return;
        var data = JSON.parse(config.value);
        reset({ position: data, generals: generals });
    }, [config, contentByPage]);
    var onSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var jsonData, generals, _a, contentItemsWithId, contentItemsWithOutId, _b, contentItemsToDelete, contentItemsToUpdate;
        return __generator(this, function (_c) {
            jsonData = JSON.stringify(data.position);
            generals = __assign(__assign({}, data.generals), { type: "general", page: PAGE });
            if (config === null || config === void 0 ? void 0 : config.id) {
                updateConfig({
                    config: __assign(__assign({}, config), { value: jsonData }),
                });
            }
            else {
                saveConfig({
                    config: {
                        key: "contact",
                        value: jsonData,
                    },
                });
            }
            _a = __read(partition([generals], "id"), 2), contentItemsWithId = _a[0], contentItemsWithOutId = _a[1];
            _b = __read(partition(contentItemsWithId, "toDelete"), 2), contentItemsToDelete = _b[0], contentItemsToUpdate = _b[1];
            if (contentItemsWithOutId.length) {
                createContentItems(contentItemsWithOutId);
            }
            if (contentItemsToUpdate.length) {
                updateContentItems(contentItemsToUpdate);
            }
            return [2 /*return*/];
        });
    }); };
    var renderLoading = isLoading && React.createElement(Loader, null);
    var renderMessage = message && (React.createElement(Alert, { message: message.text, type: message.success ? "success" : "danger", cleanError: cleanMessage }));
    return (React.createElement(MainColumn, null,
        React.createElement(Title, null, "Contacto"),
        renderLoading,
        renderMessage,
        React.createElement(FormProvider, __assign({}, methods),
            React.createElement(TitleDescription, null),
            !isLoading && React.createElement(MapSelector, null)),
        React.createElement(ActionButtons, { handleSubmit: handleSubmit(onSubmit) })));
};
export { Ubication };
export default Ubication;
