var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import { Link } from "@reach/router";
import logo from "assets/logo-white.png";
export var AdminMenu = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: grid;\n  height: 100vh;\n  min-height: 940px;\n  max-width: 250px;\n  margin: 0 auto;\n  background-color: ", ";\n  grid-template:\n    \"top\" 70%\n    \"blankSpace\" 9%\n    \"bottom\" 21%\n    / 100%;\n  overflow: hidden;\n"], ["\n  display: grid;\n  height: 100vh;\n  min-height: 940px;\n  max-width: 250px;\n  margin: 0 auto;\n  background-color: ", ";\n  grid-template:\n    \"top\" 70%\n    \"blankSpace\" 9%\n    \"bottom\" 21%\n    / 100%;\n  overflow: hidden;\n"])), function (props) { return props.theme.colors.blue004e; });
export var TopContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  grid-area: top;\n  display: flex;\n  flex-direction: column;\n"], ["\n  grid-area: top;\n  display: flex;\n  flex-direction: column;\n"])));
export var BottomContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  grid-area: bottom;\n  display: flex;\n  flex-direction: column;\n"], ["\n  grid-area: bottom;\n  display: flex;\n  flex-direction: column;\n"])));
export var Logo = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  height: 85px;\n  width: 160px;\n  margin: 0 auto;\n  justify-self: center;\n  background-image: url(", ");\n  background-position: center;\n  background-repeat: no-repeat;\n  justify-self: flex-start;\n"], ["\n  height: 85px;\n  width: 160px;\n  margin: 0 auto;\n  justify-self: center;\n  background-image: url(", ");\n  background-position: center;\n  background-repeat: no-repeat;\n  justify-self: flex-start;\n"])), logo);
export var UserNameContainer = styled(Link)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  justify-content: flex-start;\n  align-content: center;\n  align-items: center;\n  background-color: ", ";\n  padding: 1.3em 0;\n  cursor: pointer;\n  text-decoration: unset;\n  &:hover {\n    background-color: ", ";\n  }\n"], ["\n  width: 100%;\n  display: flex;\n  justify-content: flex-start;\n  align-content: center;\n  align-items: center;\n  background-color: ", ";\n  padding: 1.3em 0;\n  cursor: pointer;\n  text-decoration: unset;\n  &:hover {\n    background-color: ", ";\n  }\n"])), function (props) {
    return props.$isActive ? props.theme.colors.blue1960 : "unset";
}, function (props) { return props.theme.colors.blue1960; });
export var UserInitials = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin: 0 1em;\n"], ["\n  margin: 0 1em;\n"])));
export var UserName = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-family: \"Roboto-Bold\";\n  font-size: 1em;\n  color: ", ";\n"], ["\n  font-family: \"Roboto-Bold\";\n  font-size: 1em;\n  color: ", ";\n"])), function (props) { return props.theme.colors.white; });
export var LanguageButtonContainer = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  padding: 1em 1.5em;\n"], ["\n  padding: 1em 1.5em;\n"])));
export var LinkItem = styled(Link)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  font-family: \"Roboto\";\n  font-size: 1em;\n  padding: 1em 1.5em;\n  border-top: 1px solid;\n  border-color: ", ";\n  color: ", ";\n  cursor: pointer;\n  text-decoration: unset;\n  background-color: ", ";\n  > svg {\n    margin-right: 0.7em;\n  }\n  &:hover {\n    background-color: ", ";\n  }\n"], ["\n  font-family: \"Roboto\";\n  font-size: 1em;\n  padding: 1em 1.5em;\n  border-top: 1px solid;\n  border-color: ", ";\n  color: ", ";\n  cursor: pointer;\n  text-decoration: unset;\n  background-color: ", ";\n  > svg {\n    margin-right: 0.7em;\n  }\n  &:hover {\n    background-color: ", ";\n  }\n"])), function (props) { return props.theme.colors.blue1960; }, function (props) {
    return props.$isSignOut ? props.theme.colors.redF16D : props.theme.colors.white;
}, function (props) {
    return props.$isActive ? props.theme.colors.blue1960 : "unset";
}, function (props) { return props.theme.colors.blue1960; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
