import React from "react";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IncrementDecrement from "./styled";
var Component = function (props) {
    var limit = props.limit, valueSelect = props.valueSelect, setValueSelect = props.setValueSelect;
    var personIncrement = function () {
        var newValue = valueSelect + 1;
        if (newValue <= limit || limit == 0) {
            setValueSelect(newValue);
        }
    };
    var personReduce = function () {
        var newValue = valueSelect - 1;
        if (newValue >= 0) {
            setValueSelect(newValue);
        }
    };
    var minDisable = valueSelect === 0;
    var maxDisable = valueSelect === limit;
    return (React.createElement(IncrementDecrement, null,
        React.createElement(IncrementDecrement.Icon, { isDisable: minDisable, onClick: personReduce },
            React.createElement(FontAwesomeIcon, { icon: faMinus })),
        React.createElement(IncrementDecrement.Value, null, valueSelect),
        React.createElement(IncrementDecrement.Icon, { isDisable: maxDisable, onClick: personIncrement },
            React.createElement(FontAwesomeIcon, { icon: faPlus }))));
};
export default Component;
export { Component as IncrementDecrement };
