var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomInput from "components/CustomInput";
import { Alert, Loader, SolidButton } from "components";
import Login from "./styled";
var schema = yup.object().shape({
    email: yup
        .string()
        .email("Se debe ingresar un email correcto")
        .required("Por favor ingrese un correo electronico"),
    password: yup.string().required("Por favor ingrese su contraseña"),
});
var Component = function (props) {
    var _a, _b;
    var signIn = props.signIn, isLoading = props.isLoading, error = props.error, cleanError = props.cleanError;
    var _c = useForm({
        resolver: yupResolver(schema),
    }), register = _c.register, handleSubmit = _c.handleSubmit, errors = _c.formState.errors;
    var onSubmit = function (data) { return signIn(data); };
    var renderSubmitButton = !isLoading && (React.createElement(Login.ButtonContainer, null,
        React.createElement(SolidButton, null, "Iniciar sesi\u00F3n")));
    var renderErrorMessage = error && (React.createElement(Alert, { type: "warning", message: error, cleanError: cleanError }));
    var renderLoading = isLoading && (React.createElement(Login.Loading, null,
        React.createElement(Loader, null)));
    return (React.createElement(Login, null,
        React.createElement(Login.Form, { onSubmit: handleSubmit(onSubmit) },
            React.createElement(Login.Title, null, "Iniciar sesi\u00F3n"),
            renderErrorMessage,
            React.createElement(Login.InputContainer, null,
                React.createElement(CustomInput, __assign({ label: "Correo", placeholder: "Escribe tu correo..." }, register("email"), { error: (_a = errors.email) === null || _a === void 0 ? void 0 : _a.message }))),
            React.createElement(Login.InputContainer, null,
                React.createElement(CustomInput, __assign({ type: "password", label: "Contraseña", placeholder: "Escribe tu contraseña..." }, register("password"), { error: (_b = errors.password) === null || _b === void 0 ? void 0 : _b.message }))),
            renderSubmitButton,
            renderLoading)));
};
export { Component as Login };
export default Component;
