var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { ContentStandarMargin, LeftColumn, MainColumn, RightColumnNoOverflow, TwoColumnsLayout, } from "components/Layout";
import { FormProvider, useForm } from "react-hook-form";
import { ActionButtons, Alert, AmenitiesEditor, ConfirmModal, ListItem, Loader, Segment, Title, } from "components";
import { useStoreActions, useStoreState } from "state/hooks";
import { yupResolver } from "@hookform/resolvers/yup";
var Amenities = function () {
    var _a = __read(useState(-1), 2), amenitySelected = _a[0], setAmenitySelected = _a[1];
    var _b = __read(useState(false), 2), showModal = _b[0], setShowModal = _b[1];
    var schema = yup.object().shape({
        name: yup
            .string()
            .required("Completa este campo")
            .test("Unique", "Amenidad registrada", function (value) {
            return !amenities.some(function (_a) {
                var name = _a.name;
                return name.toLowerCase() === value.toLowerCase();
            });
        }),
        iconId: yup.number().required("Completa este campo"),
    });
    var _c = useStoreActions(function (actions) { return actions; }).amenity, getAmenities = _c.getAmenities, cleanMessage = _c.cleanMessage, createAmenity = _c.createAmenity, modifyAmenity = _c.modifyAmenity, deleteAmenity = _c.deleteAmenity, setLoading = _c.setLoading;
    var _d = useStoreState(function (store) { return store; }).amenity, amenities = _d.amenities, isLoading = _d.isLoading, message = _d.message;
    var methods = useForm({ resolver: yupResolver(schema) });
    var handleSubmit = methods.handleSubmit, reset = methods.reset;
    useEffect(function () {
        setAmenitySelected(-1);
        getAmenities();
        cleanMessage();
        setLoading(false);
    }, []);
    useEffect(function () {
        reset(amenities[amenitySelected]);
    }, [amenitySelected, amenities]);
    var onAddItem = function () {
        if (amenitySelected === -1)
            return;
        setAmenitySelected(-1);
        cleanForm();
    };
    var handleDelete = function () {
        setShowModal(false);
        deleteAmenity({
            amenity: {
                id: amenities[amenitySelected].id,
            },
        });
        cleanForm();
    };
    var cleanForm = function () {
        setAmenitySelected(-1);
        reset({ name: "", iconId: -1 });
    };
    var onSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            data.name = data.name.toLowerCase();
            if (amenitySelected === -1) {
                delete data.id;
                createAmenity({
                    amenity: data,
                });
                cleanForm();
                return [2 /*return*/];
            }
            modifyAmenity({
                amenity: data,
            });
            cleanForm();
            return [2 /*return*/];
        });
    }); };
    var items = amenities &&
        (amenities === null || amenities === void 0 ? void 0 : amenities.map(function (_a) {
            var name = _a.name;
            return {
                title: name,
            };
        }));
    var renderMessage = message && (React.createElement(ContentStandarMargin, null,
        React.createElement(Alert, { message: message.text, type: message.success ? "success" : "danger", cleanError: cleanMessage })));
    var renderLoading = isLoading && React.createElement(Loader, null);
    var renderModal = showModal && (React.createElement(ConfirmModal, { showModal: showModal, confirmButtonText: "Eliminar", text: "\u00BFEst\u00E1 seguro que desea eliminar la amenidad " + amenities[amenitySelected].name, onClose: function () { return setShowModal(false); }, onConfirm: handleDelete }));
    var subTitle = (amenitySelected === -1 ? "Nueva" : "Modificar") + " amenidad";
    var defineHandleDelete = amenitySelected !== -1 ? function () { return setShowModal(true); } : undefined;
    return (React.createElement(MainColumn, null,
        renderModal,
        React.createElement(Title, null, "Amenidades"),
        renderMessage,
        renderLoading,
        React.createElement(FormProvider, __assign({}, methods),
            React.createElement(TwoColumnsLayout, null,
                React.createElement(LeftColumn, null,
                    React.createElement(ListItem, { optionSelected: amenitySelected, onAddItem: onAddItem, onClickItem: setAmenitySelected, textForNewItem: "Agregar amenidad", items: items })),
                React.createElement(RightColumnNoOverflow, null,
                    React.createElement(Segment, null,
                        React.createElement(Title, { variant: "segment" }, subTitle),
                        React.createElement(AmenitiesEditor, null)),
                    React.createElement(ActionButtons, { handleSubmit: handleSubmit(onSubmit), handleDelete: defineHandleDelete }))))));
};
export { Amenities };
export default Amenities;
