var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import { ContainerShadow } from "style/common";
export var Segment = ContainerShadow(styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 16px 16px 24px 16px;\n  margin-bottom: 30px;\n  border-radius: 10px;\n  background-color: ", ";\n"], ["\n  padding: 16px 16px 24px 16px;\n  margin-bottom: 30px;\n  border-radius: 10px;\n  background-color: ", ";\n"])), function (props) { var _a, _b; return (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.colors) === null || _b === void 0 ? void 0 : _b.whitefff; }));
export var SubSegment = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  background-color: ", ";\n  padding: 20px;\n  margin-bottom: 20px;\n  border-radius: 10px;\n  align-items: end;\n"], ["\n  display: flex;\n  flex-direction: column;\n  background-color: ", ";\n  padding: 20px;\n  margin-bottom: 20px;\n  border-radius: 10px;\n  align-items: end;\n"])), function (props) {
    return (props === null || props === void 0 ? void 0 : props.restore) ? props.theme.colors.redLight : props.theme.colors.grayf8f;
});
var templateObject_1, templateObject_2;
