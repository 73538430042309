var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import * as yup from "yup";
import Context from "app/Context";
import CustomInput from "components/CustomInput";
import PeopleNumber from "components/PeopleNumber";
import { SolidButton, DateRange } from "components";
import React, { useContext, useState } from "react";
import { formatPrice } from "utils/bungalowFactory";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import BungalowsPrice from "./components/BungalowsPrice";
import { ReservationDetails, ButtonContainer, DatesContainer, Description, NameContainer, PersonsContainer, PhoneNumberContainer, Total, Taxes, SubTotal, } from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "components/Tooltip";
var schema = yup.object().shape({
    name: yup
        .string()
        .required("Indique su nombre completo")
        .min(7, "Indique un nombre vàlido de al menos 7 letras"),
    phone: yup
        .string()
        .matches(/(\(\d{3}\)[.-]?|\d{3}[.-]?)?\d{3}[.-]?\d{4}/, "Indique un teléfono válido")
        .required("Indique su número telefónico"),
});
var Component = function (props) {
    var _a, _b;
    var data = props.data, buttonName = props.buttonName, search = props.search, onSubmitReservation = props.onSubmitReservation, option = props.option;
    var subtotal = data.subtotal, taxes = data.taxes, total = data.total, iva = data.iva, ish = data.ish;
    var _c = __read(useState(null), 2), bungalowDetailId = _c[0], setBungalowDetailId = _c[1];
    var _d = search !== null && search !== void 0 ? search : {}, arrival = _d.arrival, departure = _d.departure, guests = _d.guests;
    var _e = guests !== null && guests !== void 0 ? guests : {}, adults = _e.adults, children = _e.children, infants = _e.infants;
    var _f = __read(useState(false), 2), isOpen = _f[0], setIsOpen = _f[1];
    var isEnglish = useContext(Context).isEnglish;
    var methods = useForm({
        resolver: yupResolver(schema),
    });
    var register = methods.register, errors = methods.formState.errors, handleSubmit = methods.handleSubmit;
    var toogleDetails = function () {
        setIsOpen(!isOpen);
    };
    var detailsClassName = "" + (isOpen ? "open" : "");
    var toogleBungalowDetails = function (id) {
        if (bungalowDetailId === id)
            id = null;
        setBungalowDetailId(id);
    };
    var renderBungalowsRules = data.bungalows.map(function (bungalow) {
        var id = bungalow.id, name = bungalow.name, total = bungalow.total, charges = bungalow.charges, transactions = bungalow.transactions;
        return (React.createElement(BungalowsPrice, { id: id, name: name, total: total, charges: charges, showDetails: id === bungalowDetailId, onClick: toogleBungalowDetails, key: "bungalow-option-data-" + option + "-" + id, option: option + "-" + id, transactions: transactions }));
    });
    var from = search ? arrival : undefined;
    var to = search ? departure : undefined;
    var onSubmit = function (customer) {
        var reservation = __assign(__assign({}, data), customer);
        onSubmitReservation(reservation);
    };
    return (React.createElement(FormProvider, __assign({}, methods),
        React.createElement(ReservationDetails, { className: "" + detailsClassName, isOpen: isOpen },
            React.createElement(DatesContainer, { isOpen: isOpen, isNegative: true },
                React.createElement(DateRange, { fromValue: from, toValue: to, isReadonly: true })),
            React.createElement(PersonsContainer, { isOpen: isOpen, isNegative: true },
                React.createElement(PeopleNumber, { isReadonly: true, defaultAdults: adults, defaultChildren: children, defaultInfants: infants })),
            React.createElement(NameContainer, { isOpen: isOpen, isNegative: true },
                React.createElement(CustomInput, __assign({ label: isEnglish ? "Full name" : "Nombre completo", placeholder: isEnglish
                        ? "Enter your full name..."
                        : "Escribe tu nombre completo..." }, register("name"), { error: (_a = errors.name) === null || _a === void 0 ? void 0 : _a.message }))),
            React.createElement(PhoneNumberContainer, { isOpen: isOpen, isNegative: true },
                React.createElement(CustomInput, __assign({ label: isEnglish ? "Phone number" : "Teléfono", placeholder: isEnglish
                        ? "Enter your phone number"
                        : "Escribe tu número de teléfono..." }, register("phone"), { error: (_b = errors.phone) === null || _b === void 0 ? void 0 : _b.message }))),
            React.createElement(ButtonContainer, { isOpen: isOpen, isNegative: true },
                React.createElement(SolidButton, { children: buttonName, onClick: handleSubmit(onSubmit) })),
            React.createElement(Description, { isOpen: isOpen, isNegative: true }, renderBungalowsRules),
            React.createElement(SubTotal, { isOpen: isOpen, isNegative: true },
                React.createElement("p", null, "Subtotal"),
                React.createElement("p", { className: "totalPrice" }, "" + formatPrice(subtotal, "es"))),
            React.createElement(Taxes, { isOpen: isOpen, isNegative: true },
                React.createElement("p", null,
                    "Tasas e impuestos de ocupaci\u00F3n",
                    React.createElement(Tooltip, { content: iva + "% IVA / " + ish + "% ISH" },
                        React.createElement(FontAwesomeIcon, { icon: faInfoCircle }))),
                React.createElement("p", { className: "totalPrice" }, "" + formatPrice(taxes, "es"))),
            React.createElement(Total, { isOpen: isOpen, isNegative: true },
                React.createElement("p", null, "Total"),
                React.createElement("p", { className: "totalPrice" }, "" + formatPrice(total, "es"))))));
};
export default Component;
export { Component as ReservationDetails };
