var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
var FeatureContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  width: 100%;\n"], ["\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  width: 100%;\n"])));
var SuggestionContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-color: ", ";\n  border: ", ";\n  border-radius: 6px;\n  cursor: pointer;\n  display: flex;\n  flex-direction: row;\n  font-family: \"Roboto\";\n  justify-content: space-between;\n  padding: 18px;\n"], ["\n  background-color: ", ";\n  border: ", ";\n  border-radius: 6px;\n  cursor: pointer;\n  display: flex;\n  flex-direction: row;\n  font-family: \"Roboto\";\n  justify-content: space-between;\n  padding: 18px;\n"])), function (props) { var _a, _b; return (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.colors) === null || _b === void 0 ? void 0 : _b.whitefff; }, function (props) { var _a, _b; return "solid 1px " + ((_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.colors) === null || _b === void 0 ? void 0 : _b.gray); });
var Input = styled.input(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background-color: transparent;\n  border: none;\n  color: #212529;\n  cursor: pointer;\n  font-size: 14px;\n  margin-right: 5px;\n  width: 50%;\n  -webkit-appearance: none;\n  -moz-appearance: textfield;\n  &:focus {\n    outline: none;\n  }\n"], ["\n  background-color: transparent;\n  border: none;\n  color: #212529;\n  cursor: pointer;\n  font-size: 14px;\n  margin-right: 5px;\n  width: 50%;\n  -webkit-appearance: none;\n  -moz-appearance: textfield;\n  &:focus {\n    outline: none;\n  }\n"])));
var Suggestions = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  width: 50%;\n"], ["\n  display: flex;\n  flex-direction: row;\n  width: 50%;\n"])));
var Suggestion = styled.span(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  background-color: ", ";\n  border-radius: 5px;\n  font-size: 0.7rem;\n  margin-left: 5px;\n  padding: 5px 10px;\n"], ["\n  background-color: ", ";\n  border-radius: 5px;\n  font-size: 0.7rem;\n  margin-left: 5px;\n  padding: 5px 10px;\n"])), function (props) { return props.theme.colors.grayeae; });
export { FeatureContainer, SuggestionContainer, Input, Suggestions, Suggestion, };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
