var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useStoreActions } from "state/hooks";
import { SolidButton, DateRange, PeopleNumber } from "components";
import React, { useContext } from "react";
import { Search, FormContainer, DateRangeContainer, PeopleNumberContainer, ButtonContainer, } from "./styled";
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { navigate } from "@reach/router";
import Context from "app/Context";
var schema = yup.object().shape({
    startDate: yup
        .date()
        .required("Indique la fecha de llegada")
        .nullable()
        .transform(function (curr, orig) { return (orig === "" ? null : curr); }),
    endDate: yup.date().required("Indique la fecha de partida").nullable(),
    capacity: yup.object().shape({
        adults: yup
            .number()
            .required("Indique la cantidad de adultos")
            .min(1, "Debe seleccionar al menos 1 adulto"),
        children: yup.number(),
        infants: yup.number(),
    }),
});
var Component = function (_a) {
    var showSearch = _a.showSearch;
    var methods = useForm({
        resolver: yupResolver(schema),
    });
    var handleSubmit = methods.handleSubmit;
    var isEnglish = useContext(Context).isEnglish;
    if (!showSearch) {
        return null;
    }
    var setSearch = useStoreActions(function (actions) { return actions; }).availability.setSearch;
    var onSubmit = function (data) {
        var startDate = data.startDate, endDate = data.endDate, capacity = data.capacity;
        setSearch({
            arrival: startDate,
            departure: endDate,
            guests: capacity,
        });
        navigate("/disponibilidad");
    };
    return (React.createElement(FormProvider, __assign({}, methods),
        React.createElement(Search, null,
            React.createElement(FormContainer, null,
                React.createElement(DateRangeContainer, null,
                    React.createElement(DateRange, null)),
                React.createElement(PeopleNumberContainer, null,
                    React.createElement(PeopleNumber, null)),
                React.createElement(ButtonContainer, null,
                    React.createElement(SolidButton, { onClick: handleSubmit(onSubmit), children: isEnglish ? "Search bungalow" : "Buscar bungalow" }))))));
};
export default Component;
export { Component as SearchComponent };
