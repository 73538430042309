var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { keyframes } from "styled-components";
var RotateAnimation = keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  0%   { transform: rotate(0deg); }\n\t100% { transform: rotate(360deg); }\n"], ["\n  0%   { transform: rotate(0deg); }\n\t100% { transform: rotate(360deg); }\n"])));
var RotateAnimation2 = keyframes(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  0%   { transform: rotate(0deg); }\n\t100% { transform: rotate(220deg); }\n"], ["\n  0%   { transform: rotate(0deg); }\n\t100% { transform: rotate(220deg); }\n"])));
var RotateAnimation3 = keyframes(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  0%   { transform: rotate(-140deg); }\n\t50%  { transform: rotate(-160deg); }\n\t100% { transform: rotate(140deg); }\n"], ["\n  0%   { transform: rotate(-140deg); }\n\t50%  { transform: rotate(-160deg); }\n\t100% { transform: rotate(140deg); }\n"])));
var LoaderContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  justify-content: center;\n  width: 100%;\n"], ["\n  align-items: center;\n  display: flex;\n  justify-content: center;\n  width: 100%;\n"])));
var Loader = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  height: 32px;\n  width: 32px;\n  -webkit-animation: ", " 4.8s linear infinite;\n  animation: ", " 4.8s linear infinite;\n"], ["\n  height: 32px;\n  width: 32px;\n  -webkit-animation: ", " 4.8s linear infinite;\n  animation: ", " 4.8s linear infinite;\n"])), RotateAnimation, RotateAnimation);
var Circle = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: block;\n  position: absolute;\n  top: 0;\n  left: 0;\n  bottom: 0;\n  right: 0;\n  margin: auto;\n  height: 32px;\n  width: 32px;\n  clip: rect(0, 32px, 32px, 16px);\n  -webkit-animation: ", " 1.2s linear infinite;\n  animation: ", " 1.2s linear infinite;\n  &:after {\n    content: \"\";\n    position: absolute;\n    top: 0;\n    left: 0;\n    bottom: 0;\n    right: 0;\n    margin: auto;\n    height: 32px;\n    width: 32px;\n    clip: rect(0, 32px, 32px, 16px);\n    border: 3px solid ", ";\n    border-radius: 50%;\n    -webkit-animation: ", " 1.2s cubic-bezier(0.77, 0, 0.175, 1)\n      infinite;\n    animation: ", " 1.2s cubic-bezier(0.77, 0, 0.175, 1) infinite;\n    -webkit-box-sizing: border-box;\n    box-sizing: border-box;\n  }\n"], ["\n  display: block;\n  position: absolute;\n  top: 0;\n  left: 0;\n  bottom: 0;\n  right: 0;\n  margin: auto;\n  height: 32px;\n  width: 32px;\n  clip: rect(0, 32px, 32px, 16px);\n  -webkit-animation: ", " 1.2s linear infinite;\n  animation: ", " 1.2s linear infinite;\n  &:after {\n    content: \"\";\n    position: absolute;\n    top: 0;\n    left: 0;\n    bottom: 0;\n    right: 0;\n    margin: auto;\n    height: 32px;\n    width: 32px;\n    clip: rect(0, 32px, 32px, 16px);\n    border: 3px solid ", ";\n    border-radius: 50%;\n    -webkit-animation: ", " 1.2s cubic-bezier(0.77, 0, 0.175, 1)\n      infinite;\n    animation: ", " 1.2s cubic-bezier(0.77, 0, 0.175, 1) infinite;\n    -webkit-box-sizing: border-box;\n    box-sizing: border-box;\n  }\n"])), RotateAnimation2, RotateAnimation2, function (props) { return props.theme.colors.blue00c5; }, RotateAnimation3, RotateAnimation3);
export { LoaderContainer, Loader, Circle };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
