var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from "react";
import { SolidButton } from "components";
import { ListItem, Item, ButtonContainer } from "./styled";
var Component = function (props) {
    var items = props.items, optionSelected = props.optionSelected, textForNewItem = props.textForNewItem, onClickItem = props.onClickItem, onAddItem = props.onAddItem;
    var _a = __read(useState([]), 2), listItems = _a[0], setListItems = _a[1];
    useEffect(function () {
        var itemsToRender = items.map(function (item, index) { return (React.createElement(Item, { isSelected: optionSelected === index, key: "item-" + index, onClick: function () { return onClickItem(index); } }, item.title)); });
        setListItems(itemsToRender);
    }, [items, optionSelected]);
    return (React.createElement(ListItem, null,
        listItems,
        React.createElement(ButtonContainer, null,
            React.createElement(SolidButton, { children: "+ " + textForNewItem, onClick: onAddItem }))));
};
export default Component;
export { Component as ListItem };
