var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import { getImageSize } from "utils/images";
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  padding: 0 5px 5px 5px;\n  width: 100%;\n"], ["\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  padding: 0 5px 5px 5px;\n  width: 100%;\n"])));
var ImageEditable = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-color: ", ";\n  background-size: cover;\n  background-position: center;\n  background-repeat: no-repeat;\n  border-radius: 5px;\n  height: ", ";\n  position: relative;\n  width: ", ";\n  &:hover > div {\n    display: flex;\n  }\n"], ["\n  background-color: ", ";\n  background-size: cover;\n  background-position: center;\n  background-repeat: no-repeat;\n  border-radius: 5px;\n  height: ", ";\n  position: relative;\n  width: ", ";\n  &:hover > div {\n    display: flex;\n  }\n"])), function (props) { return props.theme.colors.grayeae; }, function (props) { return getImageSize(props.size); }, function (props) { return (props.multiple ? "200px" : "100%"); });
ImageEditable.Restore = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  align-items: center;\n  color: ", ";\n  font-family: ", ";\n  cursor: pointer;\n  display: flex;\n  height: 100%;\n  justify-content: center;\n  width: 100%;\n"], ["\n  align-items: center;\n  color: ", ";\n  font-family: ", ";\n  cursor: pointer;\n  display: flex;\n  height: 100%;\n  justify-content: center;\n  width: 100%;\n"])), function (props) { var _a; return (_a = props === null || props === void 0 ? void 0 : props.theme) === null || _a === void 0 ? void 0 : _a.colors.whitefff; }, function (props) { return props.theme.fontFamily.primary; });
ImageEditable.RestoreBackground = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background-color: ", ";\n  cursor: pointer;\n  height: 100%;\n  opacity: 0.3;\n  position: absolute;\n  width: 100%;\n"], ["\n  background-color: ", ";\n  cursor: pointer;\n  height: 100%;\n  opacity: 0.3;\n  position: absolute;\n  width: 100%;\n"])), function (props) { return props.theme.colors.redff6; });
var Controls = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  align-items: center;\n  background-color: ", ";\n  color: ", ";\n  cursor: pointer;\n  display: none;\n  font-family: ", ";\n  height: 100%;\n  justify-content: center;\n  left: 0;\n  opacity: 0.9;\n  position: absolute;\n  top: 0;\n  width: 100%;\n  z-index: 1;\n"], ["\n  align-items: center;\n  background-color: ", ";\n  color: ", ";\n  cursor: pointer;\n  display: none;\n  font-family: ", ";\n  height: 100%;\n  justify-content: center;\n  left: 0;\n  opacity: 0.9;\n  position: absolute;\n  top: 0;\n  width: 100%;\n  z-index: 1;\n"])), function (props) { var _a; return (_a = props === null || props === void 0 ? void 0 : props.theme) === null || _a === void 0 ? void 0 : _a.colors.whitefff; }, function (props) { return props.theme.colors.redff6; }, function (props) { return props.theme.fontFamily.primary; });
Controls.Image = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  height: 30px;\n  font-size: 1.5em;\n  justify-content: center;\n  width: 30px;\n  z-index: 2;\n"], ["\n  align-items: center;\n  display: flex;\n  height: 30px;\n  font-size: 1.5em;\n  justify-content: center;\n  width: 30px;\n  z-index: 2;\n"])));
Controls.Text = styled.span(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  height: 36px;\n  user-select: none;\n  z-index: 1;\n"], ["\n  align-items: center;\n  display: flex;\n  height: 36px;\n  user-select: none;\n  z-index: 1;\n"])));
var SelectableContainer = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  padding: 10px;\n"], ["\n  display: flex;\n  flex-direction: row;\n  padding: 10px;\n"])));
var MainImageLabel = styled.label(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  cursor: pointer;\n  font-family: ", ";\n  font-size: 0.9rem;\n"], ["\n  cursor: pointer;\n  font-family: ", ";\n  font-size: 0.9rem;\n"])), function (props) { return props.theme.fontFamily.primary; });
var MainImageInput = styled.input(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  margin: 2px 3px;\n"], ["\n  margin: 2px 3px;\n"])));
export { ImageEditable, Controls, Container, SelectableContainer, MainImageLabel, MainImageInput, };
export default ImageEditable;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
