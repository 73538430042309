var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { mobileDevice } from "style/constants";
import styled, { css } from "styled-components";
export var Title = styled.h2(function (props) {
    var _a = props.theme, _b = _a.colors, black212 = _b.black212, blue004e = _b.blue004e, _c = _a.fontFamily, primary = _c.primary, secondaryBold = _c.secondaryBold, _d = props.withSubtitle, withSubtitle = _d === void 0 ? false : _d, _e = props.variant, variant = _e === void 0 ? "default" : _e;
    var defaultStyle = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: 100%;\n    text-align: center;\n    margin: 0;\n    color: ", ";\n    font-family: ", ";\n    font-size: 32px;\n    @media ", " {\n      font-size: 24px;\n    }\n  "], ["\n    width: 100%;\n    text-align: center;\n    margin: 0;\n    color: ", ";\n    font-family: ", ";\n    font-size: 32px;\n    @media ", " {\n      font-size: 24px;\n    }\n  "])), black212, secondaryBold, mobileDevice);
    switch (variant.toLowerCase()) {
        case "segment":
            defaultStyle = css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n        ", "\n        color: black212;\n        font-family: ", ";\n        font-size: 24px;\n        font-style: normal;\n        font-weight: bold;\n        height: fit-content;\n        text-align: left;\n        line-height: 28px;\n        margin: 0 0 ", ";\n        padding: 0;\n      "], ["\n        ", "\n        color: black212;\n        font-family: ", ";\n        font-size: 24px;\n        font-style: normal;\n        font-weight: bold;\n        height: fit-content;\n        text-align: left;\n        line-height: 28px;\n        margin: 0 0 ", ";\n        padding: 0;\n      "])), defaultStyle, primary, withSubtitle ? 0 : "20px");
            break;
        case "subtitle":
            defaultStyle = css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n        ", "\n        color: ", ";\n        font-size: 14px;\n        line-height: 28px;\n        margin: 0 0 20px;\n        text-align: left;\n      "], ["\n        ", "\n        color: ", ";\n        font-size: 14px;\n        line-height: 28px;\n        margin: 0 0 20px;\n        text-align: left;\n      "])), defaultStyle, blue004e);
            break;
        case "normal":
            defaultStyle = css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n        ", "\n        font-family: \"Roboto-Medium\";\n        font-size: 14px;\n        margin-bottom: 5px;\n        text-align: left;\n      "], ["\n        ", "\n        font-family: \"Roboto-Medium\";\n        font-size: 14px;\n        margin-bottom: 5px;\n        text-align: left;\n      "])), defaultStyle);
            break;
        default:
            defaultStyle = css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n        ", "\n        margin-bottom: 40px;\n      "], ["\n        ", "\n        margin-bottom: 40px;\n      "])), defaultStyle);
            break;
    }
    return defaultStyle;
});
export default Title;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
