var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useState, useEffect } from "react";
import { mobile } from "style/constants";
import useDeviceSize from "./useWindowSize";
var useWindowSize = function () {
    var _a = __read(useState(false), 2), isMobileSize = _a[0], setIsMobileSize = _a[1];
    var windowSize = useDeviceSize();
    useEffect(function () {
        if (windowSize.width && windowSize.width <= mobile) {
            setIsMobileSize(true);
        }
        else {
            setIsMobileSize(false);
        }
    }, [windowSize]);
    return { isMobileSize: isMobileSize };
};
export default useWindowSize;
