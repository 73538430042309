var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useContext, useState } from "react";
import { Link } from "@reach/router";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container } from "components/Layout";
import Header from "./styled";
import { links } from "data";
import SwitchLanguage from "components/SwitchLanguage";
import Context from "app/Context";
var Component = function (props) {
    var isEnglish = useContext(Context).isEnglish;
    var _a = __read(useState(false), 2), isOpen = _a[0], setIsOpen = _a[1];
    var renderMenuItems = function () {
        return links.map(function (_a, index) {
            var name = _a.name, englishName = _a.englishName, path = _a.path;
            return (React.createElement(Header.MenuItem, { key: "menu-option-" + index },
                React.createElement(Link, { to: path }, isEnglish ? englishName : name)));
        });
    };
    var toogleMenu = function () {
        setIsOpen(!isOpen);
    };
    var menuClassName = "" + (isOpen ? "open" : "");
    return (React.createElement(Header, null,
        React.createElement(Container, null,
            React.createElement(Header.Logo, null),
            React.createElement(Header.MenuTrigger, null,
                React.createElement(FontAwesomeIcon, { icon: faBars, onClick: toogleMenu })),
            React.createElement(Header.Menu, { className: menuClassName },
                renderMenuItems(),
                React.createElement(SwitchLanguage, null)))));
};
export { Component as Header };
export default Component;
