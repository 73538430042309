var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useContext, useEffect, useState } from "react";
import Context from "app/Context";
import { FormProvider, useForm } from "react-hook-form";
import { useStoreActions, useStoreState } from "state/hooks";
import { TwoColumnsLayout, LeftColumn, MainColumn, RightColumnNoOverflow, ContentStandarMargin, } from "components/Layout";
import { ActionButtons, General, ListItem, Title, Prices, Extra, Alert, Loader, ConfirmModal, } from "components";
import { cleanMockCompleteRules, disscount } from "mock";
var Rules = function (_a) {
    var children = _a.children;
    var language = useContext(Context).language;
    var _b = __read(useState(-1), 2), ruleSelected = _b[0], setRuleSelected = _b[1];
    var _c = __read(useState(false), 2), showForm = _c[0], setShowForm = _c[1];
    var _d = __read(useState(false), 2), showModal = _d[0], setShowModal = _d[1];
    var _e = useStoreActions(function (actions) { return actions; }).rule, getRules = _e.getRules, createRule = _e.createRule, cleanMessage = _e.cleanMessage, updateRule = _e.updateRule, deleteRule = _e.deleteRule;
    var _f = useStoreState(function (store) { return store; }).rule, rules = _f.rules, message = _f.message, isLoading = _f.isLoading;
    useEffect(function () {
        getRules();
        cleanMessage();
    }, []);
    useEffect(function () {
        var _a;
        var ruleStayDiscount = ((_a = rules[ruleSelected]) !== null && _a !== void 0 ? _a : []).ruleStayDiscount;
        var newStayDiscount = disscount.map(function (_a) {
            var _b;
            var nightsStay = _a.nightsStay;
            return ((_b = ruleStayDiscount === null || ruleStayDiscount === void 0 ? void 0 : ruleStayDiscount.filter(function (night) { return night.nightsStay === nightsStay; })[0]) !== null && _b !== void 0 ? _b : {
                nightsStay: nightsStay,
                discount: 0,
            });
        });
        var currentRule = __assign(__assign(__assign({}, cleanMockCompleteRules), rules[ruleSelected]), { ruleStayDiscount: newStayDiscount });
        setShowForm(true);
        reset(currentRule);
    }, [ruleSelected, rules]);
    var methods = useForm();
    var handleSubmit = methods.handleSubmit, reset = methods.reset;
    var onAddItem = function () {
        if (ruleSelected === -1 && rules.length > 0)
            return;
        setShowForm(true);
        reset(cleanMockCompleteRules);
        setRuleSelected(-1);
    };
    var items = rules &&
        (rules === null || rules === void 0 ? void 0 : rules.map(function (_a) {
            var name = _a.name;
            return {
                title: name,
            };
        }));
    var onSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var ruleStayDiscount, ruleData;
        return __generator(this, function (_a) {
            ruleStayDiscount = data.ruleStayDiscount;
            delete data.ruleStayDiscount;
            ruleData = {
                rule: __assign({}, data),
                ruleStayDiscount: ruleStayDiscount,
            };
            if (ruleSelected === -1) {
                delete ruleData.rule.id;
                createRule(ruleData);
                return [2 /*return*/];
            }
            delete ruleData.rule.isBaseRule;
            updateRule(ruleData);
            return [2 /*return*/];
        });
    }); };
    var handleDelete = function () {
        setShowModal(false);
        deleteRule({
            rule: {
                id: rules[ruleSelected].id,
            },
        });
    };
    var defineHandleDelete = ruleSelected !== -1 ? function () { return setShowModal(true); } : undefined;
    var renderForm = showForm && (React.createElement(RightColumnNoOverflow, null,
        React.createElement(General, null),
        React.createElement(Prices, null),
        React.createElement(Extra, null),
        React.createElement(ActionButtons, { handleSubmit: handleSubmit(onSubmit), handleDelete: defineHandleDelete })));
    var renderMessage = message && (React.createElement(ContentStandarMargin, null,
        React.createElement(Alert, { message: message.text, type: message.success ? "success" : "danger", cleanError: cleanMessage })));
    var renderLoading = isLoading && React.createElement(Loader, null);
    var renderModal = showModal && (React.createElement(ConfirmModal, { showModal: showModal, confirmButtonText: "Eliminar", text: "\u00BFEst\u00E1 seguro que desea eliminar la regla " + rules[ruleSelected].name, onClose: function () { return setShowModal(false); }, onConfirm: handleDelete }));
    var renderContent = !isLoading && (React.createElement(FormProvider, __assign({}, methods),
        React.createElement(TwoColumnsLayout, null,
            React.createElement(LeftColumn, null,
                React.createElement(ListItem, { optionSelected: ruleSelected, onAddItem: onAddItem, onClickItem: setRuleSelected, textForNewItem: "Agregar regla", items: items })),
            renderForm)));
    return (React.createElement(MainColumn, null,
        renderModal,
        React.createElement(Title, null, "Reglas"),
        renderMessage,
        renderLoading,
        renderContent));
};
export { Rules };
export default Rules;
