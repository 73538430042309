var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Image, ImageIndex } from "components";
import React, { useState, useEffect, useRef } from "react";
import useDeviceSize from "hooks/useDeviceSize";
import Carousel from "./styled";
var delay = 4000;
var Component = function (props) {
    var images = props.images, imagesToShow = props.imagesToShow, className = props.className, infiniteLoop = props.infiniteLoop;
    var _a = __read(useState(0), 2), currentIndex = _a[0], setCurrentIndex = _a[1];
    var _b = __read(useState(images.length), 2), length = _b[0], setLength = _b[1];
    var _c = __read(useState(null), 2), content = _c[0], setContent = _c[1];
    var _d = __read(useState(null), 2), touchPosition = _d[0], setTouchPosition = _d[1];
    var isMobileSize = useDeviceSize().isMobileSize;
    var timeoutRef = useRef(null);
    var resetTimeout = function () {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    };
    useEffect(function () {
        resetTimeout();
        timeoutRef.current = setTimeout(function () { return next(); }, delay);
        return function () {
            clearTimeout(timeoutRef.current);
        };
    }, [currentIndex]);
    useEffect(function () {
        setLength(images.length);
        var content = images.map(function (image, index) { return (React.createElement(Image, { key: "carousel-image-" + index, src: image })); });
        setContent(content);
    }, [images]);
    useEffect(function () {
        setCurrentIndex(0);
    }, [isMobileSize]);
    var showleftArrow = infiniteLoop ? true : currentIndex !== 0;
    var _imagesToShow = isMobileSize ? 1 : imagesToShow;
    var showRightArrow = infiniteLoop
        ? true
        : currentIndex < length - _imagesToShow;
    var handleTouchStart = function (event) {
        var _touchPosition = event.touches[0].clientX;
        setTouchPosition(_touchPosition);
    };
    var handleTouchMove = function (event) {
        if (touchPosition === null) {
            return;
        }
        var currentTouchPosition = event.touches[0].clientX;
        var diff = touchPosition - currentTouchPosition;
        if (diff < -100 && showleftArrow) {
            prev();
        }
        else if (diff > 100 && showRightArrow) {
            next();
        }
    };
    var prev = function () {
        var prevIndex = isMobileSize ? 1 : imagesToShow;
        if (infiniteLoop) {
            if (currentIndex > 0) {
                setCurrentIndex(function (prevState) { return prevState - 1; });
            }
            else {
                setCurrentIndex(length - prevIndex);
            }
            setTouchPosition(null);
            return;
        }
        if (currentIndex > 0) {
            setCurrentIndex(function (prevState) { return prevState - 1; });
        }
        setTouchPosition(null);
    };
    var next = function () {
        var nextIndex = isMobileSize ? 0 : imagesToShow - 1;
        if (infiniteLoop) {
            if (currentIndex + nextIndex < length - 1) {
                setCurrentIndex(function (prevState) { return prevState + 1; });
            }
            else {
                setCurrentIndex(0);
            }
            setTouchPosition(null);
            return;
        }
        if (currentIndex + nextIndex < length - 1) {
            setCurrentIndex(function (prevState) { return prevState + 1; });
        }
        setTouchPosition(null);
    };
    return (React.createElement(Carousel, { className: className },
        React.createElement(Carousel.Wrapper, null,
            showleftArrow && (React.createElement(Carousel.ArrowLeft, { onClick: prev },
                React.createElement(FontAwesomeIcon, { icon: faArrowLeft }))),
            React.createElement(Carousel.ContentWrapper, { onTouchStart: handleTouchStart, onTouchMove: handleTouchMove },
                React.createElement(Carousel.Content, { currentIndex: currentIndex, imagesToShow: _imagesToShow | 1, totalImages: length }, content)),
            showRightArrow && (React.createElement(Carousel.ArrowRight, { onClick: next },
                React.createElement(FontAwesomeIcon, { icon: faArrowRight }))),
            React.createElement(Carousel.ImageIndexContainer, null,
                React.createElement(ImageIndex, { totalImages: length, active: currentIndex + 1 })))));
};
export default Component;
export { Component as Carousel };
