var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
var PersonCounter = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: grid;\n  width: 250px;\n  grid-template:\n    \"title select\" 1fr\n    \"description select\" 1fr\n    /60% 40%;\n"], ["\n  display: grid;\n  width: 250px;\n  grid-template:\n    \"title select\" 1fr\n    \"description select\" 1fr\n    /60% 40%;\n"])));
PersonCounter.Title = styled.h2(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  grid-area: title;\n  font-family: ", ";\n  font-size: 16px;\n  font-weight: 500;\n  color: ", ";\n  margin-bottom: 0px;\n"], ["\n  grid-area: title;\n  font-family: ", ";\n  font-size: 16px;\n  font-weight: 500;\n  color: ", ";\n  margin-bottom: 0px;\n"])), function (props) { var _a, _b; return (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.fontFamily) === null || _b === void 0 ? void 0 : _b.medium; }, function (props) { var _a, _b; return (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.fonts) === null || _b === void 0 ? void 0 : _b.secondary; });
PersonCounter.Description = styled.p(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  grid-area: description;\n  font-family: ", ";\n  font-size: 14px;\n  color: ", ";\n  margin: 0px;\n"], ["\n  grid-area: description;\n  font-family: ", ";\n  font-size: 14px;\n  color: ", ";\n  margin: 0px;\n"])), function (props) { var _a, _b; return (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.fontFamily) === null || _b === void 0 ? void 0 : _b.primary; }, function (props) { var _a, _b; return (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.fonts) === null || _b === void 0 ? void 0 : _b.secondaryText; });
PersonCounter.Select = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  grid-area: select;\n  width: 100%;\n  align-self: center;\n"], ["\n  grid-area: select;\n  width: 100%;\n  align-self: center;\n"])));
export default PersonCounter;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
