var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from "styled-components";
export var InputContainer = styled.div(function (props) {
    var _a = props.variant, variant = _a === void 0 ? "default" : _a;
    var defaultStyle = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    margin-top: 31px;\n    width: 275px;\n  "], ["\n    margin-top: 31px;\n    width: 275px;\n  "])));
    switch (variant.toLowerCase()) {
        case "fluid":
            defaultStyle = css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n        ", "\n        width: 100%;\n      "], ["\n        ", "\n        width: 100%;\n      "])), defaultStyle);
            break;
        default:
            defaultStyle;
            break;
    }
    return defaultStyle;
});
export var InputFullWith = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n  height: fit-content;\n  margin-top: 19px;\n"], ["\n  width: 100%;\n  height: fit-content;\n  margin-top: 19px;\n"])));
var templateObject_1, templateObject_2, templateObject_3;
