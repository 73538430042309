import React from "react";
import { useFormContext } from "react-hook-form";
import { Segment, Title, Map, Subtitle } from "components";
var Component = function () {
    var _a = useFormContext(), setValue = _a.setValue, watch = _a.watch;
    var position = watch("position");
    var onPositionChange = function (value) {
        setValue("position", value);
    };
    return (React.createElement(Segment, null,
        React.createElement(Title, { variant: "segment", withSubtitle: true }, "Mapa"),
        React.createElement(Subtitle, null, "Posiciona el pin en la ubicaci\u00F3n de tu negocio."),
        React.createElement(Map, { draggable: true, onPositionChange: onPositionChange, positionSaved: position })));
};
export { Component as MapSelector };
export default Component;
