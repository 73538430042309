import React from "react";
import { useFormContext, Controller, useFieldArray } from "react-hook-form";
import NumberFormat from "react-number-format";
import { CustomInput, Segment, Title } from "components";
import { Row, Column, TwoColumns } from "./styled";
import { faDollarSign, faPercent } from "@fortawesome/free-solid-svg-icons";
var Component = function () {
    var control = useFormContext().control;
    var fields = useFieldArray({
        control: control,
        name: "ruleStayDiscount",
    }).fields;
    var renderStayDiscounts = fields.map(function (ruleStayDiscountItem, index) {
        var nightsStay = ruleStayDiscountItem.nightsStay;
        return (React.createElement(Column, { key: "nightsToStay-" + index },
            React.createElement(Controller, { control: control, name: "ruleStayDiscount." + index + ".discount", render: function (_a) {
                    var _b = _a.field, value = _b.value, onChange = _b.onChange;
                    return (React.createElement(NumberFormat, { thousandSeparator: true, customInput: CustomInput, icon: faPercent, label: "Por " + nightsStay + " noches", value: value, onValueChange: function (_a) {
                            var floatValue = _a.floatValue;
                            onChange(floatValue);
                        } }));
                } })));
    });
    return (React.createElement(Segment, null,
        React.createElement(Title, { variant: "subtitle" }, "PRECIO BASE"),
        React.createElement(Row, null,
            React.createElement(TwoColumns, null,
                React.createElement(Controller, { control: control, name: "basePrice", render: function (_a) {
                        var _b = _a.field, value = _b.value, onChange = _b.onChange;
                        value = value !== null && value !== void 0 ? value : NaN;
                        return (React.createElement(NumberFormat, { thousandSeparator: true, customInput: CustomInput, icon: faDollarSign, iconPosition: "before", label: "Precio por noche", helpText: "(domingo-jueves)", value: value, onValueChange: function (_a) {
                                var floatValue = _a.floatValue;
                                onChange(floatValue);
                            }, isNumericString: true }));
                    } })),
            React.createElement(TwoColumns, null,
                React.createElement(Controller, { control: control, name: "weekendPrice", render: function (_a) {
                        var _b = _a.field, value = _b.value, onChange = _b.onChange;
                        value = value !== null && value !== void 0 ? value : NaN;
                        return (React.createElement(NumberFormat, { thousandSeparator: true, customInput: CustomInput, icon: faDollarSign, iconPosition: "before", label: "Precio fin de semana", helpText: "(viernes-sábado)", value: value, onValueChange: function (_a) {
                                var floatValue = _a.floatValue;
                                onChange(floatValue);
                            } }));
                    } }))),
        React.createElement(Title, { variant: "subtitle" }, "DESCUENTOS"),
        React.createElement(Row, null,
            renderStayDiscounts,
            React.createElement(Controller, { control: control, name: "lastMinuteRentalDisscount", render: function (_a) {
                    var _b = _a.field, value = _b.value, onChange = _b.onChange;
                    value = value !== null && value !== void 0 ? value : NaN;
                    return (React.createElement(NumberFormat, { thousandSeparator: true, customInput: CustomInput, icon: faPercent, label: "Renta de \u00FAltima hora", helpText: "(24 hrs. antes)", value: value, onValueChange: function (_a) {
                            var floatValue = _a.floatValue;
                            onChange(floatValue);
                        } }));
                } })),
        React.createElement(Title, { variant: "subtitle" }, "CARGOS"),
        React.createElement(Row, null,
            React.createElement(Column, null,
                React.createElement(Controller, { control: control, name: "cleaningCharge", render: function (_a) {
                        var _b = _a.field, value = _b.value, onChange = _b.onChange;
                        value = value !== null && value !== void 0 ? value : NaN;
                        return (React.createElement(NumberFormat, { thousandSeparator: true, customInput: CustomInput, label: "Cargo por limpieza", icon: faDollarSign, iconPosition: "before", value: value, onValueChange: function (_a) {
                                var floatValue = _a.floatValue;
                                onChange(floatValue);
                            } }));
                    } })),
            React.createElement(Column, null,
                React.createElement(Controller, { control: control, name: "extraGuestPrice", render: function (_a) {
                        var _b = _a.field, value = _b.value, onChange = _b.onChange;
                        value = value !== null && value !== void 0 ? value : NaN;
                        return (React.createElement(NumberFormat, { thousandSeparator: true, customInput: CustomInput, label: "Cargo por h\u00FAesped extra", icon: faDollarSign, iconPosition: "before", value: value, onValueChange: function (_a) {
                                var floatValue = _a.floatValue;
                                onChange(floatValue);
                            } }));
                    } })),
            React.createElement(TwoColumns, null,
                React.createElement(Controller, { control: control, name: "extraGuestApply", render: function (_a) {
                        var _b = _a.field, value = _b.value, onChange = _b.onChange;
                        value = value !== null && value !== void 0 ? value : NaN;
                        return (React.createElement(NumberFormat, { thousandSeparator: true, customInput: CustomInput, label: "A partir de cu\u00E1ntos hu\u00E9spedes aplica", suffix: " hu\u00E9spedes", decimalScale: 0, allowNegative: false, max: "50", value: value, onValueChange: function (_a) {
                                var floatValue = _a.floatValue;
                                onChange(floatValue);
                            } }));
                    } })))));
};
export { Component as Prices };
export default Component;
