var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect } from "react";
import { Carousel, BungalowInformation } from "components";
import { Content } from "components/Layout";
import { FormProvider, useForm } from "react-hook-form";
import { useStoreActions, useStoreState } from "state/hooks";
var BungalowDetails = function (_a) {
    var slug = _a.slug;
    var methods = useForm();
    var getBungalowBySlug = useStoreActions(function (actions) { return actions.bungalow; }).getBungalowBySlug;
    var bungalow = useStoreState(function (store) { return store.bungalow; }).bungalow;
    useEffect(function () {
        var params = { slug: slug };
        getBungalowBySlug(params);
    }, [slug]);
    var arrayPictures = bungalow.pictures && bungalow.pictures.map(function (picture) { return picture.url; });
    var renderCarousel = arrayPictures && (React.createElement(Carousel, { images: arrayPictures, imagesToShow: 3, infiniteLoop: true }));
    var renderBungalowDetails = bungalow && (React.createElement(BungalowInformation, { dataBungalow: bungalow }));
    var renderReservationDetails = bungalow.baseRule && (React.createElement(FormProvider, __assign({}, methods)));
    return (React.createElement(Content, null,
        renderCarousel,
        renderBungalowDetails,
        renderReservationDetails));
};
export default BungalowDetails;
export { BungalowDetails };
