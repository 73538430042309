var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var AmenitiesEditor = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  > div {\n    flex-direction: row;\n    max-width: 900px;\n    height: fit-content;\n    padding: 16px;\n    align-items: center;\n    justify-content: space-between;\n  }\n"], ["\n  > div {\n    flex-direction: row;\n    max-width: 900px;\n    height: fit-content;\n    padding: 16px;\n    align-items: center;\n    justify-content: space-between;\n  }\n"])));
export var CheckBox = styled.input(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-color: ", ";\n"], ["\n  background-color: ", ";\n"])), function (props) { var _a, _b; return (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.colors) === null || _b === void 0 ? void 0 : _b.blue00c5; });
export var InputName = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 65%;\n  margin: 0 1em;\n"], ["\n  width: 65%;\n  margin: 0 1em;\n"])));
export var SelectContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 35%;\n  height: 3.4em;\n  > div {\n    width: 90%;\n  }\n"], ["\n  width: 35%;\n  height: 3.4em;\n  > div {\n    width: 90%;\n  }\n"])));
export var DeleteContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 10%;\n  cursor: pointer;\n  font-family: Roboto-Bold;\n  font-size: 14px;\n  color: ", ";\n"], ["\n  width: 10%;\n  cursor: pointer;\n  font-family: Roboto-Bold;\n  font-size: 14px;\n  color: ", ";\n"])), function (props) { var _a; return (_a = props.theme.fonts) === null || _a === void 0 ? void 0 : _a.red; });
export default AmenitiesEditor;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
