var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { mobileDevice } from "style/constants";
import styled from "styled-components";
import { ContainerShadow } from "style/common";
export var Search = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  justify-content: center;\n  position: relative;\n  z-index: 1;\n"], ["\n  display: flex;\n  width: 100%;\n  justify-content: center;\n  position: relative;\n  z-index: 1;\n"])));
export var FormContainer = ContainerShadow(styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  max-width: 996px;\n  background-color: ", ";\n  border-bottom-left-radius: 10px;\n  border-bottom-right-radius: 10px;\n  padding: 0 10px 10px;\n  @media ", " {\n    width: 100%;\n    height: fit-content;\n    flex-wrap: wrap;\n  }\n"], ["\n  display: flex;\n  max-width: 996px;\n  background-color: ", ";\n  border-bottom-left-radius: 10px;\n  border-bottom-right-radius: 10px;\n  padding: 0 10px 10px;\n  @media ", " {\n    width: 100%;\n    height: fit-content;\n    flex-wrap: wrap;\n  }\n"])), function (props) { var _a, _b; return (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.colors) === null || _b === void 0 ? void 0 : _b.white; }, mobileDevice));
export var DateRangeContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  width: 480px;\n  align-content: center;\n  align-items: center;\n  @media ", " {\n    width: 100%;\n    height: 80px;\n    margin: 16px;\n  }\n"], ["\n  display: flex;\n  width: 480px;\n  align-content: center;\n  align-items: center;\n  @media ", " {\n    width: 100%;\n    height: 80px;\n    margin: 16px;\n  }\n"])), mobileDevice);
export var PeopleNumberContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  width: 240px;\n  align-content: center;\n  align-items: center;\n  margin-left: 5px;\n\n  @media ", " {\n    width: 100%;\n    height: 80px;\n    margin: 8px 16px;\n  }\n"], ["\n  display: flex;\n  width: 240px;\n  align-content: center;\n  align-items: center;\n  margin-left: 5px;\n\n  @media ", " {\n    width: 100%;\n    height: 80px;\n    margin: 8px 16px;\n  }\n"])), mobileDevice);
export var ButtonContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  width: 190px;\n  align-content: center;\n  align-items: center;\n  padding: 20px 5px 0 10px;\n\n  @media ", " {\n    justify-content: center;\n    justify-items: center;\n    width: 90%;\n    height: 50px;\n    margin: 8px 16px;\n  }\n"], ["\n  display: flex;\n  width: 190px;\n  align-content: center;\n  align-items: center;\n  padding: 20px 5px 0 10px;\n\n  @media ", " {\n    justify-content: center;\n    justify-items: center;\n    width: 90%;\n    height: 50px;\n    margin: 8px 16px;\n  }\n"])), mobileDevice);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
