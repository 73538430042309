import React from "react";
import { SolidButton, TransparentButton } from "components";
import { ButtonContainer, CancelButton, SaveButton } from "./styled";
import { DeleteButton } from "components/Button/variants";
var Component = function (props) {
    var handleCancel = props.handleCancel, handleSubmit = props.handleSubmit, handleDelete = props.handleDelete;
    return (React.createElement(ButtonContainer, null,
        handleCancel && (React.createElement(CancelButton, null,
            React.createElement(TransparentButton, { onClick: handleCancel }, "Regresar"))),
        handleDelete && (React.createElement(CancelButton, null,
            React.createElement(DeleteButton, { onClick: handleDelete }, "Eliminar"))),
        React.createElement(SaveButton, null,
            React.createElement(SolidButton, { onClick: handleSubmit }, "Guardar"))));
};
export default Component;
export { Component as ActionButtons };
