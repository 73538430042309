import { calendarModel } from "./Calendar";
import { errorsPayload, fileModel, paginationPayload } from "./commonPayloads";
var baseRule = "\n    id,\n    name,\n    description,\n    basePrice,\n    weekendPrice,\n    cleaningCharge,\n    extraGuestPrice,\n    extraGuestApply,\n    minimumStay,\n    capacity,\n    childrenAllowed,\n    lastMinuteRentalDisscount\n";
export var simpleBungalowModel = "\n  pricePerNight,\n  id,\n  name,\n  slug,\n  description,\n  features,\n  mainPictureId,\n  amenities {\n    id,\n    name,\n    englishName,\n    iconId\n  },\n  pictures {\n    " + fileModel + "\n  },\n  rules {\n    rule {\n      id,\n      name,\n      description,\n      isBaseRule,\n      basePrice\n    },\n    startDate,\n    endDate\n  }\n";
export var bungalowModel = "\n  " + simpleBungalowModel + "\n  baseRule {\n    " + baseRule + "\n    ruleStayDiscount{\n      nightsStay,\n      discount\n    }\n  },\n  calendars {\n    " + calendarModel + "\n  }\n";
var bungalowPayload = "\n  payload {\n    " + bungalowModel + "\n  }\n";
export var getBungalowsQuery = "\n  query(\n    $isAsc: Boolean\n  ) {\n    getBungalows(isAsc:$isAsc) {\n      success,\n      " + bungalowPayload + ",\n      " + errorsPayload + "\n    }\n  }\n";
export var getBungalowBySlugQuery = "\nquery(\n  $slug: String!\n) {\n  getBungalowBySlug(slug:$slug){\n    success,\n    payload{\n      " + bungalowModel + "\n    },\n      " + errorsPayload + "\n\n  }\n}\n";
export var getPaginatedBungalowsQuery = "\n  query(\n    $pageInfo: paginationInput\n  ) {\n    getPaginatedBungalows(\n      pageInfo: $pageInfo\n    ) {\n      success,\n      payload {\n        " + paginationPayload + "\n        bungalows {\n          " + bungalowModel + "\n        }\n      }\n      " + errorsPayload + "\n    }\n  }\n";
export var createBungalowMutation = "\n  mutation(\n    $bungalow: createBungalowInput,\n    $amenities: amenitiesInput,\n    $pictures: picturesInput,\n    $calendars: calendarsInput,\n    $baseRule: createUpdateBungalowRuleInput,\n    $rules: createUpdateBungalowRulesInput,\n    $ruleStayDiscount: [ruleStayDiscountInput]\n  ){\n    createBungalow(\n      bungalow: $bungalow,\n      amenities: $amenities,\n      pictures: $pictures,\n      calendars: $calendars,\n      baseRule: $baseRule,\n      rules: $rules,\n      ruleStayDiscount: $ruleStayDiscount\n    ){\n      success,\n      " + bungalowPayload + ",\n      " + errorsPayload + "\n    }\n  }\n";
export var deleteBungalowMutation = "\n  mutation($bungalow: deleteBungalowInput) {\n    deleteBungalow(\n      bungalow: $bungalow\n    ){\n      success,\n      payload{\n        id\n      },\n      errors{\n        message\n      }\n    }\n  }\n";
export var updateBungalowMutation = "\n  mutation(\n    $bungalow: updateBungalowInput,\n    $amenities: amenitiesInput,\n    $pictures: picturesInput,\n    $calendars: calendarsInput,\n    $baseRule: createUpdateBungalowRuleInput,\n    $rules: createUpdateBungalowRulesInput,\n    $ruleStayDiscount: [ruleStayDiscountInput]\n  ){\n    updateBungalow(\n      bungalow: $bungalow,\n      amenities: $amenities,\n      pictures: $pictures,\n      calendars: $calendars,\n      baseRule: $baseRule,\n      rules: $rules,\n      ruleStayDiscount: $ruleStayDiscount\n    ){\n      success,\n      " + bungalowPayload + ",\n      " + errorsPayload + "\n    }\n  }\n";
