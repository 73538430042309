var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
var AmenitySelectorContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  justify-content: center;\n\n  > div {\n    align-items: center;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    width: 100%;\n  }\n"], ["\n  align-items: center;\n  display: flex;\n  justify-content: center;\n\n  > div {\n    align-items: center;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    width: 100%;\n  }\n"])));
var SelectContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
var DeleteContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  cursor: pointer;\n  font-family: Roboto-Bold;\n  font-size: 12px;\n  text-align: center;\n  width: 15%;\n"], ["\n  color: ", ";\n  cursor: pointer;\n  font-family: Roboto-Bold;\n  font-size: 12px;\n  text-align: center;\n  width: 15%;\n"])), function (props) { var _a; return (_a = props.theme.fonts) === null || _a === void 0 ? void 0 : _a.red; });
export { AmenitySelectorContainer, SelectContainer, DeleteContainer };
var templateObject_1, templateObject_2, templateObject_3;
