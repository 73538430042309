import React from "react";
import { Modal, SolidButton, TransparentButton } from "components";
import Confirm from "./styled";
var Component = function (props) {
    var showModal = props.showModal, text = props.text, confirmButtonText = props.confirmButtonText, _a = props.cancelButtonText, cancelButtonText = _a === void 0 ? "Cancelar" : _a, onClose = props.onClose, onConfirm = props.onConfirm;
    return (React.createElement(Modal, { open: showModal, onClose: onClose, enableCloseWithEsc: true, enableBackdropClick: false },
        React.createElement(Confirm, null,
            React.createElement(Confirm.Text, null, text),
            React.createElement(Confirm.Buttons, null,
                React.createElement(Confirm.Cancel, null,
                    React.createElement(TransparentButton, { onClick: onClose }, cancelButtonText)),
                React.createElement(Confirm.Delete, null,
                    React.createElement(SolidButton, { onClick: onConfirm }, confirmButtonText))))));
};
export { Component as ConfirmModal };
export default Component;
