import React from "react";
import { Router } from "@reach/router";
import { Header, SearchComponent, Footer } from "components";
import { Home } from "./Home";
import { BungalowsList } from "./BungalowsList";
import { TerracePool } from "./TerracePool";
import { BeachClub } from "./BeachClub";
import { Activities } from "./Activities";
import { Ubication } from "./Ubication";
import { BungalowDetails } from "./BungalowDetails";
import { Availability } from "./Availability";
import { Reservation } from "./Reservation";
var Public = function (props) {
    var _a, _b;
    var showSearch = true;
    if ((_b = (_a = props === null || props === void 0 ? void 0 : props.location) === null || _a === void 0 ? void 0 : _a.pathname) === null || _b === void 0 ? void 0 : _b.includes("reservation")) {
        showSearch = false;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, null),
        React.createElement(SearchComponent, { showSearch: showSearch }),
        React.createElement(Router, null,
            React.createElement(Home, { default: true }),
            React.createElement(BungalowsList, { path: "bungalows" }),
            React.createElement(BungalowDetails, { path: "bungalows/:slug" }),
            React.createElement(TerracePool, { path: "terraza-y-alberca" }),
            React.createElement(BeachClub, { path: "club-de-playa" }),
            React.createElement(Activities, { path: "actividades" }),
            React.createElement(Ubication, { path: "ubicacion" }),
            React.createElement(Availability, { path: "disponibilidad" }),
            React.createElement(Reservation, { path: "reservation/:id" })),
        React.createElement(Footer, null)));
};
export { Public };
export default Public;
