import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import NumberFormat from "react-number-format";
import { CustomInput, Segment, Title } from "components";
import { Row, TwoColumns, Column } from "./styled";
var Component = function () {
    var _a = useFormContext(), register = _a.register, control = _a.control;
    return (React.createElement(Segment, null,
        React.createElement(Title, { variant: "subtitle" }, "LONGITUD DE ESTAD\u00CDA"),
        React.createElement(Row, null,
            React.createElement(TwoColumns, null,
                React.createElement(Controller, { control: control, name: "minimumStay", render: function (_a) {
                        var _b = _a.field, value = _b.value, onChange = _b.onChange;
                        value = value !== null && value !== void 0 ? value : NaN;
                        return (React.createElement(NumberFormat, { thousandSeparator: true, customInput: CustomInput, label: "Longitud m\u00EDnima de estad\u00EDa para todos los bungalows", suffix: " noche(s)", decimalScale: 0, allowNegative: false, value: value, onValueChange: function (_a) {
                                var floatValue = _a.floatValue;
                                onChange(floatValue);
                            } }));
                    } }))),
        React.createElement(Title, { variant: "subtitle" }, "OTROS"),
        React.createElement(Row, null,
            React.createElement(Column, null,
                React.createElement(Controller, { control: control, name: "capacity", render: function (_a) {
                        var _b = _a.field, value = _b.value, onChange = _b.onChange;
                        value = value !== null && value !== void 0 ? value : NaN;
                        return (React.createElement(NumberFormat, { thousandSeparator: true, customInput: CustomInput, label: "M\u00E1ximo de personas", suffix: " hu\u00E9spedes", decimalScale: 0, allowNegative: false, value: value, onValueChange: function (_a) {
                                var floatValue = _a.floatValue;
                                onChange(floatValue);
                            } }));
                    } })),
            React.createElement(Column, null,
                React.createElement(Controller, { control: control, name: "childrenAllowed", render: function (_a) {
                        var _b = _a.field, value = _b.value, onChange = _b.onChange;
                        value = value !== null && value !== void 0 ? value : NaN;
                        return (React.createElement(NumberFormat, { thousandSeparator: true, customInput: CustomInput, label: "M\u00E1ximo de infantes", suffix: " infantes", decimalScale: 0, allowNegative: false, value: value, onValueChange: function (_a) {
                                var floatValue = _a.floatValue;
                                onChange(floatValue);
                            } }));
                    } })))));
};
export { Component as Extra };
export default Component;
