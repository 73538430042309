var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import { mobileDevice } from "style/constants";
var Footer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: fit-content;\n  background: linear-gradient(0deg, #002a53 0%, #014388 100%);\n  color: white;\n  font-family: \"Roboto\";\n  > div {\n    display: flex;\n    padding: 99px 0;\n    flex-direction: row;\n    flex-wrap: wrap;\n    justify-content: space-evenly;\n\n    word-break: break-word;\n    @media ", " {\n      flex-direction: column;\n      padding: 50px 30px;\n      > div {\n        margin-bottom: 50px;\n        width: fit-content;\n      }\n    }\n  }\n"], ["\n  height: fit-content;\n  background: linear-gradient(0deg, #002a53 0%, #014388 100%);\n  color: white;\n  font-family: \"Roboto\";\n  > div {\n    display: flex;\n    padding: 99px 0;\n    flex-direction: row;\n    flex-wrap: wrap;\n    justify-content: space-evenly;\n\n    word-break: break-word;\n    @media ", " {\n      flex-direction: column;\n      padding: 50px 30px;\n      > div {\n        margin-bottom: 50px;\n        width: fit-content;\n      }\n    }\n  }\n"])), mobileDevice);
Footer.Info = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  max-width: 390px;\n  > hr {\n    width: 23px;\n    margin: 0;\n  }\n  > p {\n    font-size: 16px;\n  }\n"], ["\n  max-width: 390px;\n  > hr {\n    width: 23px;\n    margin: 0;\n  }\n  > p {\n    font-size: 16px;\n  }\n"])));
Footer.BulledInfo = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  justify-items: flex-start;\n  align-items: center;\n  margin-top: 20px;\n  > svg {\n    border-radius: 50%;\n    padding: 5px;\n    font-size: 14px;\n  }\n  > p {\n    margin: unset;\n    margin-left: 10px;\n  }\n"], ["\n  display: flex;\n  flex-direction: row;\n  justify-items: flex-start;\n  align-items: center;\n  margin-top: 20px;\n  > svg {\n    border-radius: 50%;\n    padding: 5px;\n    font-size: 14px;\n  }\n  > p {\n    margin: unset;\n    margin-left: 10px;\n  }\n"])));
Footer.Map = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  max-width: 220px;\n  > hr {\n    width: 23px;\n    margin: 0;\n  }\n"], ["\n  max-width: 220px;\n  > hr {\n    width: 23px;\n    margin: 0;\n  }\n"])));
Footer.MapItem = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin: 20px 0;\n  a {\n    color: white;\n    text-decoration: none;\n  }\n"], ["\n  margin: 20px 0;\n  a {\n    color: white;\n    text-decoration: none;\n  }\n"])));
Footer.Nets = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  max-width: 390px;\n  > hr {\n    width: 23px;\n    margin: 0;\n  }\n  img {\n    width: 100%;\n  }\n"], ["\n  max-width: 390px;\n  > hr {\n    width: 23px;\n    margin: 0;\n  }\n  img {\n    width: 100%;\n  }\n"])));
Footer.SocialIcons = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin: 20px 0;\n  > a > svg {\n    font-size: 24px;\n    margin-right: 15px;\n    color: white;\n    &:hover {\n      opacity: 0.8;\n    }\n  }\n"], ["\n  margin: 20px 0;\n  > a > svg {\n    font-size: 24px;\n    margin-right: 15px;\n    color: white;\n    &:hover {\n      opacity: 0.8;\n    }\n  }\n"])));
export default Footer;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
