var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
var UsersAdministration = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  box-sizing: border-box;\n  width: 100%;\n  display: flex;\n  border: solid 1px #ccc;\n  border-radius: 10px;\n  flex-direction: column;\n  overflow: hidden;\n  height: 60px;\n  padding: 0 20px 0 10px;\n  transition: height ease-in-out 0.3s;\n"], ["\n  box-sizing: border-box;\n  width: 100%;\n  display: flex;\n  border: solid 1px #ccc;\n  border-radius: 10px;\n  flex-direction: column;\n  overflow: hidden;\n  height: 60px;\n  padding: 0 20px 0 10px;\n  transition: height ease-in-out 0.3s;\n"])));
UsersAdministration.Info = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  padding: 5px 12px;\n  justify-content: space-between;\n"], ["\n  display: flex;\n  padding: 5px 12px;\n  justify-content: space-between;\n"])));
UsersAdministration.Name = styled.h1(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 18px;\n  font-family: Roboto;\n"], ["\n  color: ", ";\n  font-size: 18px;\n  font-family: Roboto;\n"])), function (props) { var _a, _b; return (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.colors) === null || _b === void 0 ? void 0 : _b.black000; });
UsersAdministration.Icons = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  padding-top: 12px;\n"], ["\n  display: flex;\n  padding-top: 12px;\n"])));
UsersAdministration.ActionIcon = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: ", ";\n  cursor: pointer;\n  font-size: 14px;\n  font-family: Roboto;\n  padding: 3px 50px;\n"], ["\n  color: ", ";\n  cursor: pointer;\n  font-size: 14px;\n  font-family: Roboto;\n  padding: 3px 50px;\n"])), function (props) { var _a, _b; return props.isActive ? (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.colors) === null || _b === void 0 ? void 0 : _b.rede24 : props.theme.colors.green219; });
UsersAdministration.PlusIcon = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  color: ", ";\n  cursor: pointer;\n  font-size: 24px;\n  display: flex;\n"], ["\n  color: ", ";\n  cursor: pointer;\n  font-size: 24px;\n  display: flex;\n"])), function (props) { var _a, _b; return (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.colors) === null || _b === void 0 ? void 0 : _b.grayadb; });
export default UsersAdministration;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
