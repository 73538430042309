var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import { CustomInput, Title, Segment, InputContainer } from "components";
var Component = function () {
    var _a, _b, _c;
    var _d = useFormContext(), register = _d.register, errors = _d.formState.errors, control = _d.control;
    return (React.createElement(Segment, null,
        React.createElement(Title, { variant: "segment" }, "Cuenta bancaria"),
        React.createElement(InputContainer, { variant: "fluid" },
            React.createElement(CustomInput, __assign({ label: "Nombre de cuenta" }, register("config.account.name"), { error: (_c = (_b = (_a = errors.config) === null || _a === void 0 ? void 0 : _a.account) === null || _b === void 0 ? void 0 : _b.name) === null || _c === void 0 ? void 0 : _c.message }))),
        React.createElement(InputContainer, null,
            React.createElement(Controller, { control: control, name: "config.account.number", render: function (_a) {
                    var _b, _c, _d;
                    var _e = _a.field, value = _e.value, onChange = _e.onChange;
                    return (React.createElement(NumberFormat, { label: "No. de cuenta", format: "#### #### #### ####", customInput: CustomInput, thousandSeparator: true, error: (_d = (_c = (_b = errors.config) === null || _b === void 0 ? void 0 : _b.account) === null || _c === void 0 ? void 0 : _c.number) === null || _d === void 0 ? void 0 : _d.message, value: value, onValueChange: function (_a) {
                            var floatValue = _a.floatValue;
                            onChange(floatValue);
                        } }));
                } }))));
};
export default Component;
export { Component as BankAccountForm };
