import React from "react";
import { WeekDay } from "./styled";
var daysES = ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"];
var daysEN = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
var Component = function (props) {
    var weekday = props.weekday, className = props.className, locale = props.locale;
    var days = locale === "en" ? daysEN : daysES;
    return React.createElement(WeekDay, { className: className }, days[weekday]);
};
export default Component;
