var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from "react";
import { faSort, faSortDown, faSortUp, } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTable, useSortBy, useFilters, usePagination, } from "react-table";
import TextFilter from "./components/TextFilter";
import { CustomCheckbox2 } from "./components/CustomCheckbox2";
import Pagination from "./components/Pagination";
import ServerSidePagination from "./components/ServerSidePagination";
import { Table, THead, THeader, TRow, ColumnHeader, HeaderTitle, TBody, TCell, SelectionColumn, } from "./styled";
var selectionHook = function (hooks) {
    hooks.allColumns.push(function (columns) { return __spreadArray([
        // Let's make a column for selection
        {
            id: "_selector",
            disableResizing: true,
            disableGroupBy: true,
            minWidth: 45,
            width: 45,
            maxWidth: 45,
            Aggregated: undefined,
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: function (_a) {
                var getToggleAllRowsSelectedProps = _a.getToggleAllRowsSelectedProps;
                return (React.createElement(SelectionColumn, null,
                    React.createElement(CustomCheckbox2, __assign({}, getToggleAllRowsSelectedProps()))));
            },
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: function (_a) {
                var row = _a.row;
                return (React.createElement(SelectionColumn, null,
                    React.createElement(CustomCheckbox2, __assign({}, row.getToggleRowSelectedProps()))));
            },
        }
    ], __read(columns), false); });
    hooks.useInstanceBeforeDimensions.push(function (_a) {
        var headerGroups = _a.headerGroups;
        // fix the parent group of the selection button to not be resizable
        var selectionGroupHeader = headerGroups[0].headers[0];
        selectionGroupHeader.canResize = false;
    });
};
var Component = function (props) {
    var columns = props.columns, pagination = props.pagination;
    var defaultColumn = {
        Filter: TextFilter,
    };
    var hooks = [
        useFilters,
        useSortBy,
        usePagination,
        // useRowSelect,
        // selectionHook,
    ];
    var instance = useTable.apply(void 0, __spreadArray([__assign(__assign({}, props), { defaultColumn: defaultColumn, columns: columns, initialState: { pageSize: 15, pageIndex: 0 }, manualPagination: true })], __read(hooks), false));
    var getTableProps = instance.getTableProps, headerGroups = instance.headerGroups, getTableBodyProps = instance.getTableBodyProps, rows = instance.rows, prepareRow = instance.prepareRow;
    var tableProps = getTableProps();
    var renderHeaders = function () {
        return headerGroups.map(function (headerGroup) {
            var _a = headerGroup.getHeaderGroupProps(), headerGroupKey = _a.key, headerGroupRole = _a.role, getHeaderGroupProps = __rest(_a, ["key", "role"]);
            return (React.createElement(TRow, __assign({ key: headerGroupKey }, getHeaderGroupProps), headerGroup.headers.map(function (column) {
                var _a = column.getHeaderProps(), headerKey = _a.key, headerRole = _a.role, getHeaderProps = __rest(_a, ["key", "role"]);
                var sortIcon = column.isSorted
                    ? column.isSortedDesc
                        ? faSortDown
                        : faSortUp
                    : faSort;
                return (React.createElement(THeader, __assign({ key: headerKey }, getHeaderProps),
                    React.createElement(ColumnHeader, null,
                        React.createElement(HeaderTitle, __assign({}, column.getSortByToggleProps()),
                            column.render("Header"),
                            column.canSort && React.createElement(FontAwesomeIcon, { icon: sortIcon }))),
                    React.createElement("div", null, column.canFilter ? column.render("Filter") : null)));
            })));
        });
    };
    var renderBody = function () {
        return rows.map(function (row) {
            prepareRow(row);
            return (React.createElement(TRow, __assign({}, row.getRowProps()), row.cells.map(function (cell) {
                return (React.createElement(TCell, __assign({}, cell.getCellProps()), cell.render("Cell")));
            })));
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Table, __assign({}, tableProps),
            React.createElement(THead, null, renderHeaders()),
            React.createElement(TBody, __assign({}, getTableBodyProps()), renderBody())),
        pagination ? (React.createElement(ServerSidePagination, { pagination: pagination })) : (React.createElement(Pagination, { instance: instance }))));
};
export { Component as Table };
export default Component;
