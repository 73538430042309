var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useRef, useEffect } from "react";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DropdownContainer, Header, HeaderContent, DropdownList, DropdownListContainer, ListItem, Icon, IconItem, Label, Item, Error, } from "./styled";
var Component = function (props) {
    var _a = props.options, options = _a === void 0 ? [] : _a, isImg = props.isImg, label = props.label, onChange = props.onChange, _b = props.selectedItem, selectedItem = _b === void 0 ? null : _b, _c = props.readonly, readonly = _c === void 0 ? false : _c, _d = props.error, error = _d === void 0 ? null : _d;
    var _e = __read(useState(false), 2), isOpen = _e[0], setIsOpen = _e[1];
    var _f = __read(useState(selectedItem), 2), optionSelected = _f[0], setOptionSelected = _f[1];
    var dropdownRef = useRef(null);
    useEffect(function () {
        setOptionSelected(selectedItem);
    }, [selectedItem]);
    useEffect(function () {
        var handleClickOutside = function (event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("click", handleClickOutside);
        return function () {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [dropdownRef]);
    var handleClickOption = function (event, option) {
        setOptionSelected(option);
        toggleDropdownListContainer(event);
        onChange && onChange(option);
    };
    var toggleDropdownListContainer = function (event) {
        if (readonly)
            return;
        event.stopPropagation();
        setIsOpen(!isOpen);
    };
    var evalOption = function (option) {
        if (typeof option === "string") {
            return isImg ? React.createElement(IconItem, { src: option }) : option;
        }
        else if (typeof option === "object") {
            var icon = isImg ? React.createElement(IconItem, { src: option === null || option === void 0 ? void 0 : option.image }) : "";
            return (React.createElement(Item, null,
                icon,
                React.createElement(Label, null, option === null || option === void 0 ? void 0 : option.text)));
        }
        else {
            return option;
        }
    };
    var renderOptions = options
        .filter(function (option) { return option != optionSelected; })
        .map(function (option, index) { return (React.createElement(ListItem, { key: "dropdown-option-" + index, onClick: function (event) { return handleClickOption(event, option); } }, evalOption(option))); });
    var existsSelected = typeof optionSelected === "object"
        ? isImg
            ? optionSelected === null || optionSelected === void 0 ? void 0 : optionSelected.image
            : optionSelected === null || optionSelected === void 0 ? void 0 : optionSelected.text
        : optionSelected;
    var showDefault = existsSelected
        ? evalOption(optionSelected)
        : "Selecciona una opcion";
    return (React.createElement(DropdownContainer, { ref: dropdownRef },
        label && React.createElement(Label, null, label),
        React.createElement(Header, { onClick: toggleDropdownListContainer },
            React.createElement(HeaderContent, null, showDefault),
            !readonly && (React.createElement(Icon, null,
                React.createElement(FontAwesomeIcon, { icon: faCaretDown })))),
        isOpen && (React.createElement(DropdownListContainer, null,
            React.createElement(DropdownList, null, renderOptions))),
        error && React.createElement(Error, null, error)));
};
export { Component as ComponentName };
export default Component;
