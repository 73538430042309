import React, { useContext } from "react";
import { formatMonthDay } from "utils/dates";
import { ResumeContainer, ResumeSearch, TotalOptions } from "./styled";
import Context from "app/Context";
var Component = function (props) {
    var isEnglish = useContext(Context).isEnglish;
    var _a = props.data, arrival = _a.arrival, departure = _a.departure, _b = _a.guests, adults = _b.adults, children = _b.children, infants = _b.infants, proposalCount = props.proposalCount;
    var adultsText = adults + " " + (isEnglish ? "adults(s)" : "adulto(s)") + " ";
    var childrenText = children !== 0
        ? ", " + children + " " + (isEnglish ? "child(ren)" : "niño(s)") + " "
        : "";
    var infantsText = infants !== 0
        ? ", " + infants + " " + (isEnglish ? "infants(s)" : "infante(s)")
        : "";
    var capacityText = "" + adultsText + childrenText + infantsText;
    var optionTextPlural = proposalCount === 1 ? "opción" : "opciones";
    var optionText = proposalCount === 0
        ? "No se encontraton opciones para su reservación"
        : proposalCount + " " + optionTextPlural;
    var searchText = "\n    " + formatMonthDay(arrival, isEnglish) + " -\n    " + formatMonthDay(departure, isEnglish) + ",\n    " + capacityText + "\n  ";
    return (React.createElement(ResumeContainer, null,
        React.createElement(ResumeSearch, null, searchText),
        React.createElement(TotalOptions, null, optionText)));
};
export { Component as AvailabilityResume };
export default Component;
