var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
var BungalowContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n"], ["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n"])));
var Section = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n"], ["\n  align-items: center;\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n"])));
var Title = styled.h3(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 0.9rem;\n  font-weight: 600;\n  letter-spacing: 1.2px;\n"], ["\n  color: ", ";\n  font-size: 0.9rem;\n  font-weight: 600;\n  letter-spacing: 1.2px;\n"])), function (props) { return props.theme.colors.gray495; });
var Total = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 0.8rem;\n  font-weight: 600;\n  letter-spacing: 1.2px;\n"], ["\n  color: ", ";\n  font-size: 0.8rem;\n  font-weight: 600;\n  letter-spacing: 1.2px;\n"])), function (props) { return props.theme.colors.gray; });
var Icon = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  align-self: start;\n  color: ", ";\n  border-radius: 100%;\n  box-shadow: 0px 3px 8px 0px #ced4da33;\n  cursor: pointer;\n  display: grid;\n  grid-column: 2/3;\n  grid-row: 1/2;\n  height: 12px;\n  justify-content: center;\n  justify-self: end;\n  padding: 16px;\n  width: 12px;\n  > svg {\n    width: 100%;\n    height: 100%;\n  }\n"], ["\n  align-self: start;\n  color: ", ";\n  border-radius: 100%;\n  box-shadow: 0px 3px 8px 0px #ced4da33;\n  cursor: pointer;\n  display: grid;\n  grid-column: 2/3;\n  grid-row: 1/2;\n  height: 12px;\n  justify-content: center;\n  justify-self: end;\n  padding: 16px;\n  width: 12px;\n  > svg {\n    width: 100%;\n    height: 100%;\n  }\n"])), function (props) { return props.theme.colors.gray495; });
var Details = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  color: ", ";\n  display: ", ";\n  flex-direction: column;\n  font-size: 0.8rem;\n  margin-bottom: 40px;\n  padding: 0 15px;\n  width: calc(100% - 30px);\n"], ["\n  color: ", ";\n  display: ", ";\n  flex-direction: column;\n  font-size: 0.8rem;\n  margin-bottom: 40px;\n  padding: 0 15px;\n  width: calc(100% - 30px);\n"])), function (props) { return props.theme.colors.gray495; }, function (props) { return (props.isOpen ? "flex" : "none"); });
var TransactionContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject([""], [""])));
var Transaction = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  margin: 10px 0;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  margin: 10px 0;\n"])));
var Text = styled.span(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  color: ", ";\n  flex: 0 1 70%;\n  font-size: 0.8rem;\n  letter-spacing: 1.2px;\n"], ["\n  color: ", ";\n  flex: 0 1 70%;\n  font-size: 0.8rem;\n  letter-spacing: 1.2px;\n"])), function (props) { return props.theme.colors.gray495; });
var Price = styled.span(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 0.9rem;\n"], ["\n  color: ", ";\n  font-size: 0.9rem;\n"])), function (props) {
    return props.isDiscount
        ? props.theme.colors.green3BC
        : props.theme.colors.gray495;
});
var RuleName = styled.h4(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  font-size: 0.9rem;\n  font-weight: bolder;\n  letter-spacing: 1.3px;\n"], ["\n  font-size: 0.9rem;\n  font-weight: bolder;\n  letter-spacing: 1.3px;\n"])));
var Line = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  border-bottom: 1px solid ", ";\n  margin: 10px 0;\n  width: 100%;\n"], ["\n  border-bottom: 1px solid ", ";\n  margin: 10px 0;\n  width: 100%;\n"])), function (props) { return props.theme.colors.grayced4; });
export { BungalowContainer, Section, Title, Total, Icon, Details, TransactionContainer, Transaction, Text, Price, RuleName, Line, };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;
