var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
//@ts-ignore
// (pdfMake as any).vfs = window.pdfMake.vfs;
//@ts-ignore
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { formateDateNoDay } from "./dates";
import { imageBungalows, imageOxxo } from "mock";
import { formatPrice, generateChargeText, processAllBungalows, } from "./bungalowFactory";
var columnWidths = Array(12).fill(34);
var columns11 = Array(11).fill({});
var columns7 = Array(7).fill({});
var strings = {
    spanish: {
        resume: "Recuerda que este es una cotización y esta sujeto a validación con disponibilidad y tarifa vigente. por lo que el precio podria variar.",
        amount: "Monto a pagar",
        reference: "Referencia",
        deadline: "Fecha de expiración",
        bankAccount: "Cuenta bancaria",
        reservationDetails: "Detalles de la reservación",
        clientName: "Reservación a nombre de",
        arrivalDate: "Fecha de llegada",
        departureDate: "Fecha de salida",
        guests: "Cantidad de húespedes",
        reservedBungalows: "Bungalows reservados",
        taxes: "Tasas e impuestos de ocupación",
        footerInstructions: [
            "Si tienes dudas adicionales acerca del pago, envía un correo a reservaciones@bungalowsalcaraz.com.",
            "Comunicate con nosotros al telefono +52-314-197-0482.",
        ],
        adults: "adulto(s)",
        children: "niño(s)",
        infants: "infante(s)",
    },
    english: {
        resume: "Please remember that this is an estimate and is subject to validation based on availability and current rates, so the price may vary.",
        amount: "Amount to pay",
        reference: "Payment reference",
        deadline: "Deadline",
        bankAccount: "Bank account",
        reservationDetails: "Reservation details",
        clientName: "Reservation for",
        arrivalDate: "Arrival date",
        departureDate: "Departure date",
        guests: "Guests",
        reservedBungalows: "Reserved bungalows",
        taxes: "Occupation taxes",
        footerInstructions: [
            "If you have any additional doubts regarding payment, please send an email to booking@bungalowsalcaraz.com.",
            "You can also reach us by phone at +52-314-197-0482.",
        ],
        adults: "adults",
        children: "children",
        infants: "infants",
    },
};
var generateLine = function (columns) { return [
    {
        text: "",
        colSpan: columns,
        border: [false, false, false, true],
    },
    {},
]; };
var generateHeader = function (strings) {
    var doc = [
        {
            columns: [
                {
                    width: 120,
                    image: "bungalows",
                    fit: [80, 80],
                },
                {
                    absolutePosition: { x: 125, y: 35 },
                    canvas: [
                        {
                            type: "line",
                            x1: 10,
                            y1: -4,
                            x2: 10,
                            y2: 50,
                            lineWidth: 1,
                            lineColor: "#CED4DA",
                            w: 10,
                        },
                    ],
                },
            ],
        },
        {
            style: "descriptionParagraph",
            fontSize: 8,
            text: strings.resume,
        },
    ];
    return doc;
};
var generateResumeTable = function (reservation, strings) {
    var total = reservation.total, paymentReference = reservation.paymentReference, deadline = reservation.deadline, accountNumber = reservation.accountNumber;
    var doc = [
        {
            style: "resumeTable",
            table: {
                widths: columnWidths,
                body: [
                    __spreadArray([
                        {
                            text: "",
                            colSpan: 12,
                            margin: [0, 0, 0, 10],
                            border: [true, true, true, false],
                        }
                    ], __read(columns11), false),
                    [{ text: "", colSpan: 12, border: [true, false, true, false] }],
                    [
                        { text: "", colSpan: 2, border: [true, false, false, false] },
                        {},
                        {
                            text: "\n" + strings.reference + ":\n\n",
                            colSpan: 2,
                            fontSize: 8,
                            border: [false, true, false, true],
                        },
                        {},
                        {
                            text: "\n" + paymentReference + "\n\n",
                            colSpan: 2,
                            fontSize: 8,
                            bold: true,
                            border: [false, true, false, true],
                        },
                        {},
                        {
                            text: "\n" + strings.deadline + ":\n\n",
                            colSpan: 2,
                            fontSize: 8,
                            border: [false, true, false, true],
                        },
                        {},
                        {
                            text: "\n" + formateDateNoDay(deadline) + "\n\n",
                            colSpan: 2,
                            fontSize: 8,
                            bold: true,
                            border: [false, true, false, true],
                        },
                        {},
                        { text: "", colSpan: 2, border: [false, false, true, false] },
                        {},
                    ],
                ],
            },
            layout: {
                hLineColor: function (i, node) {
                    return "#CED4DA";
                },
                vLineColor: function (i, node) {
                    return "#CED4DA";
                },
            },
        },
    ];
    return doc;
};
var generateReservationDetails = function (reservation, strings) {
    var customerCard = generateCustomerCard(reservation, strings);
    var bungalowsDetails = generateBungalowsDetails(reservation, strings);
    var doc = [
        {
            margin: [0, 14, 0, 0],
            table: {
                widths: columnWidths,
                body: [
                    __spreadArray(__spreadArray([
                        { text: "", colSpan: 2, border: [false, false, false, false] },
                        {},
                        {
                            text: strings.reservationDetails,
                            colSpan: 8,
                            fontSize: 12,
                            margin: [0, 10, 0, 0],
                            border: [false, false, false, false],
                        }
                    ], __read(columns7), false), [
                        { text: "", colSpan: 2, border: [false, false, false, false] },
                        {},
                    ], false),
                    __spreadArray(__spreadArray([
                        { text: "", colSpan: 2, border: [false, false, false, false] },
                        {},
                        customerCard
                    ], __read(columns7), false), [
                        { text: "", colSpan: 2, border: [false, false, false, false] },
                        {},
                    ], false),
                    __spreadArray(__spreadArray([
                        { text: "", colSpan: 2, border: [false, false, false, false] },
                        {},
                        bungalowsDetails
                    ], __read(columns7), false), [
                        { text: "", colSpan: 2, border: [false, false, false, false] },
                        {},
                    ], false),
                ],
            },
            layout: {
                fillColor: function (rowIndex, node, columnIndex) { return "#F8F9FA"; },
            },
        },
    ];
    return doc;
};
var generateCustomerCard = function (reservation, strings) {
    var clientName = reservation.clientName, clientPhone = reservation.clientPhone, arrivalDate = reservation.arrivalDate, departureDate = reservation.departureDate, totalGuests = reservation.totalGuests, totalChildren = reservation.totalChildren, totalInfants = reservation.totalInfants;
    var adultsText = totalGuests + " " + strings.adults;
    var childrenText = totalChildren !== 0 ? ", " + totalChildren + " " + strings.children : "";
    var infantsText = totalInfants !== 0 ? ", " + totalInfants + " " + strings.infants : "";
    var capacityText = "" + adultsText + childrenText + infantsText;
    return {
        colSpan: 8,
        border: [false, false, false, false],
        fillColor: "#fff",
        table: {
            widths: ["auto", "*"],
            body: [
                [
                    {
                        text: strings.clientName,
                        fontSize: 8,
                        color: "#ADB5BD",
                        colSpan: 2,
                        border: [false, false, false, false],
                    },
                    {},
                ],
                [
                    {
                        text: clientName,
                        fontSize: 11,
                        bold: true,
                        color: "#495057",
                        colSpan: 2,
                        border: [false, false, false, false],
                    },
                    {},
                ],
                [
                    {
                        text: clientPhone,
                        fontSize: 8,
                        bold: true,
                        color: "#495057",
                        colSpan: 2,
                        border: [false, false, false, false],
                    },
                    {},
                ],
                generateLine(2),
                [
                    {
                        margin: [0, 5, 0, 0],
                        text: strings.arrivalDate + ": ",
                        color: "#212529",
                        fontSize: 8,
                        border: [false, false, false, false],
                    },
                    {
                        margin: [0, 5, 0, 0],
                        text: formateDateNoDay(arrivalDate),
                        bold: true,
                        color: "#212529",
                        fontSize: 8,
                        alignment: "left",
                        border: [false, false, false, false],
                    },
                ],
                [
                    {
                        margin: [0, 5, 0, 0],
                        text: strings.departureDate + ": ",
                        color: "#212529",
                        fontSize: 8,
                        border: [false, false, false, false],
                    },
                    {
                        margin: [0, 5, 0, 0],
                        text: formateDateNoDay(departureDate),
                        bold: true,
                        color: "#212529",
                        fontSize: 8,
                        alignment: "left",
                        border: [false, false, false, false],
                    },
                ],
                [
                    {
                        margin: [0, 5, 0, 0],
                        text: strings.guests + ": ",
                        color: "#212529",
                        fontSize: 8,
                        border: [false, false, false, false],
                    },
                    {
                        margin: [0, 5, 0, 0],
                        text: capacityText,
                        bold: true,
                        color: "#212529",
                        fontSize: 8,
                        alignment: "left",
                        border: [false, false, false, false],
                    },
                ],
                generateLine(2),
            ],
        },
        layout: {
            hLineColor: function (i, node) {
                return "#dadada";
            },
            hLineWidth: function (i, node) { return 0.5; },
        },
    };
};
var generateBungalowsDetails = function (reservation, strings) {
    var _a = generateBungalows(reservation), total = _a.total, subtotal = _a.subtotal, taxes = _a.taxes, bungalowsDetails = _a.bungalowsDetails;
    var savedTaxes = reservation.taxes;
    return {
        colSpan: 8,
        border: [false, false, false, false],
        fillColor: "#fff",
        table: {
            widths: ["auto", "*"],
            body: __spreadArray(__spreadArray([
                [
                    {
                        text: strings.reservedBungalows,
                        fontSize: 8,
                        color: "#ADB5BD",
                        colSpan: 2,
                        border: [false, false, false, false],
                    },
                    {},
                ]
            ], __read(bungalowsDetails), false), [
                [
                    {
                        margin: [0, 15, 0, 0],
                        text: "Subtotal",
                        bold: true,
                        color: "#212529",
                        fontSize: 8,
                        border: [false, false, false, false],
                    },
                    {
                        margin: [0, 15, 0, 0],
                        text: formatPrice(subtotal, "es"),
                        bold: true,
                        color: "#212529",
                        fontSize: 8,
                        alignment: "right",
                        border: [false, false, false, false],
                    },
                ],
                [
                    {
                        margin: [0, 5, 0, 0],
                        text: strings.taxes,
                        bold: true,
                        color: "#212529",
                        fontSize: 8,
                        border: [false, false, false, false],
                    },
                    {
                        margin: [0, 5, 0, 0],
                        text: formatPrice(savedTaxes, "es"),
                        bold: true,
                        color: "#212529",
                        fontSize: 8,
                        alignment: "right",
                        border: [false, false, false, false],
                    },
                ],
                [
                    {
                        margin: [0, 10, 0, 10],
                        text: strings.amount,
                        bold: true,
                        color: "#212529",
                        fontSize: 10,
                        border: [false, false, false, false],
                    },
                    {
                        margin: [0, 10, 0, 10],
                        text: formatPrice(total, "es"),
                        bold: true,
                        color: "#212529",
                        fontSize: 14,
                        alignment: "right",
                        border: [false, false, false, false],
                    },
                ],
            ], false),
        },
        layout: {
            hLineColor: function (i, node) {
                return "#dadada";
            },
            hLineWidth: function (i, node) { return 0.5; },
        },
    };
};
var generateBungalows = function (reservation) {
    var e_1, _a, e_2, _b;
    var total = reservation.total, bungalows = reservation.bungalows;
    var taxes = 0;
    var subtotal = 0;
    var bungalowsDetails = [];
    var fakeProposal = [
        {
            bungalows: bungalows,
        },
    ];
    var newBungalows = processAllBungalows(fakeProposal)[0].bungalows;
    try {
        for (var newBungalows_1 = __values(newBungalows), newBungalows_1_1 = newBungalows_1.next(); !newBungalows_1_1.done; newBungalows_1_1 = newBungalows_1.next()) {
            var bungalow = newBungalows_1_1.value;
            var transactions = bungalow.transactions, charges = bungalow.charges, name_1 = bungalow.name, bungalowTotal = bungalow.total;
            var prevRuleName = "";
            bungalowsDetails.push([
                {
                    margin: [0, 10, 0, 0],
                    text: name_1,
                    fontSize: 10,
                    bold: true,
                    color: "#495057",
                    colSpan: 2,
                    border: [false, false, false, false],
                },
                {},
            ]);
            try {
                for (var transactions_1 = (e_2 = void 0, __values(transactions)), transactions_1_1 = transactions_1.next(); !transactions_1_1.done; transactions_1_1 = transactions_1.next()) {
                    var transaction = transactions_1_1.value;
                    var _c = transaction, ruleName = _c.ruleName, label = _c.label, value_1 = _c.value, percent = _c.percent, times = _c.times, total_1 = _c.total;
                    if (label === "GuestRequired")
                        continue;
                    var text = generateChargeText(label, value_1, percent, times, total_1, "es");
                    var isSameRule = prevRuleName !== ruleName;
                    var ruleNameToShow = (ruleName === null || ruleName === void 0 ? void 0 : ruleName.includes("Rule Base"))
                        ? "Regular"
                        : ruleName;
                    if (isSameRule || prevRuleName === "")
                        bungalowsDetails.push([
                            {
                                margin: [0, 10, 0, 0],
                                text: ruleNameToShow,
                                fontSize: 8,
                                bold: true,
                                color: "#495057",
                                colSpan: 2,
                                border: [false, false, false, false],
                            },
                            {},
                        ]);
                    bungalowsDetails.push([
                        {
                            margin: [0, 5, 0, 0],
                            text: text,
                            color: "#212529",
                            fontSize: 8,
                            border: [false, false, false, false],
                        },
                        {
                            margin: [0, 5, 0, 0],
                            text: formatPrice(total_1, "es"),
                            bold: true,
                            color: total_1 < 0 ? "#3BC794" : "#212529",
                            fontSize: 8,
                            alignment: "right",
                            border: [false, false, false, false],
                        },
                    ]);
                    prevRuleName = ruleName;
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (transactions_1_1 && !transactions_1_1.done && (_b = transactions_1.return)) _b.call(transactions_1);
                }
                finally { if (e_2) throw e_2.error; }
            }
            var value = (charges === null || charges === void 0 ? void 0 : charges.filter(function (charge) { return charge.label === "CleanningCharge"; })[0]).value;
            bungalowsDetails.push([
                {
                    margin: [0, 5, 0, 0],
                    text: "Tarifa de limpieza",
                    color: "#212529",
                    fontSize: 8,
                    border: [false, false, false, false],
                },
                {
                    margin: [0, 5, 0, 0],
                    text: formatPrice(value, "es"),
                    bold: true,
                    color: total < 0 ? "#3BC794" : "#212529",
                    fontSize: 8,
                    alignment: "right",
                    border: [false, false, false, false],
                },
            ]);
            bungalowsDetails.push([
                {
                    margin: [0, 5, 0, 0],
                    text: "Total",
                    color: "#212529",
                    fontSize: 10,
                    bold: true,
                    border: [false, false, false, false],
                },
                {
                    margin: [0, 5, 0, 0],
                    text: formatPrice(bungalowTotal, "es"),
                    bold: true,
                    color: "#212529",
                    fontSize: 10,
                    alignment: "right",
                    border: [false, false, false, false],
                },
            ]);
            bungalowsDetails.push(generateLine(2));
            subtotal += bungalowTotal;
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (newBungalows_1_1 && !newBungalows_1_1.done && (_a = newBungalows_1.return)) _a.call(newBungalows_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    taxes = subtotal * (15 / 100);
    return {
        total: total,
        subtotal: subtotal,
        taxes: taxes,
        bungalowsDetails: bungalowsDetails,
    };
};
var generateFooter = function (strings) {
    return [
        {
            style: "footer",
            margin: [0, 50, 0, 0],
            ul: strings.footerInstructions,
        },
    ];
};
export var generateReservationReference = function (reservation, lang) {
    var requiredStrings = lang === "spanish" ? strings.spanish : strings.english;
    var header = generateHeader(requiredStrings);
    var resumeTable = generateResumeTable(reservation, requiredStrings);
    var reservationDetails = generateReservationDetails(reservation, requiredStrings);
    var footer = generateFooter(requiredStrings);
    var docDefinition = {
        content: [header, resumeTable, reservationDetails, footer],
        styles: {
            resumeTable: {
                margin: [0, 20, 0, 0],
            },
            descriptionParagraph: {
                margin: [0, 20, 0, 0],
            },
            footer: {
                fontSize: 8,
                lineHeight: 1.4,
            },
        },
        defaultStyle: {
            alignment: "justify",
            color: "#212529",
        },
        images: {
            oxxo: imageOxxo,
            bungalows: imageBungalows,
        },
    };
    var pdfDocGenerator = pdfMake.createPdf(docDefinition);
    return pdfDocGenerator;
};
