var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useContext } from "react";
import { Features, Amenities } from "../../components";
import Information from "./styled";
import Context from "app/Context";
var BungalowInformation = function (props) {
    var isEnglish = useContext(Context).isEnglish;
    var dataBungalow = props.dataBungalow, rest = __rest(props, ["dataBungalow"]);
    var features = dataBungalow.features, name = dataBungalow.name, amenities = dataBungalow.amenities, description = dataBungalow.description;
    return (React.createElement(Information, null,
        React.createElement(Information.Description, null,
            React.createElement("h2", null, name),
            React.createElement("p", null, description)),
        features && (React.createElement(Information.Features, null,
            React.createElement("h2", null, isEnglish ? "Features" : "Caracteríticas"),
            React.createElement(Features, { features: features, isEditing: false }))),
        (amenities === null || amenities === void 0 ? void 0 : amenities.length) > 0 && (React.createElement(Information.Amenities, null,
            React.createElement("h2", null, isEnglish ? "Amenities" : "Amenidades"),
            amenities && (React.createElement(Amenities, { amenitieData: amenities, showDetails: true }))))));
};
export { BungalowInformation };
export default BungalowInformation;
