var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef } from "react";
import { CustomSelect } from "./styled";
var Component = forwardRef(function (props, ref) {
    var label = props.label, placeholder = props.placeholder, disabled = props.disabled, name = props.name, error = props.error, data = props.data, rest = __rest(props, ["label", "placeholder", "disabled", "name", "error", "data"]);
    var renderDefaultOption = (React.createElement("option", { key: name + "-default", value: "" }, placeholder));
    var renderOptions = data.map(function (_a, index) {
        var text = _a.text, value = _a.value;
        return (React.createElement("option", { key: name + "-item-" + index, value: value }, text));
    });
    return (React.createElement(CustomSelect, null,
        React.createElement(CustomSelect.Label, null, label),
        React.createElement(CustomSelect.Input, null,
            React.createElement("select", __assign({ ref: ref, name: name, placeholder: placeholder, disabled: disabled }, rest),
                renderDefaultOption,
                renderOptions)),
        error && React.createElement(CustomSelect.Error, null, error)));
});
export { Component as CustomSelect };
export default Component;
