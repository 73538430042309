var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
var PeopleNumber = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  height: 100%;\n  align-items: center;\n"], ["\n  display: flex;\n  width: 100%;\n  height: 100%;\n  align-items: center;\n"])));
PeopleNumber.InputContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  justify-content: flex-start;\n  padding-bottom: 5px;\n"], ["\n  display: flex;\n  width: 100%;\n  justify-content: flex-start;\n  padding-bottom: 5px;\n"])));
PeopleNumber.SelectContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: fit-content;\n  background-color: ", ";\n  border: ", ";\n  padding: 20px 20px;\n  margin: 0 auto;\n  border-radius: 5px;\n  z-index: 99999;\n"], ["\n  width: fit-content;\n  background-color: ", ";\n  border: ", ";\n  padding: 20px 20px;\n  margin: 0 auto;\n  border-radius: 5px;\n  z-index: 99999;\n"])), function (props) { var _a, _b; return (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.colors) === null || _b === void 0 ? void 0 : _b.white; }, function (props) { var _a, _b; return "solid 1px " + ((_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.colors) === null || _b === void 0 ? void 0 : _b.gray); });
PeopleNumber.ButtonContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  height: 40px;\n  right: 0;\n  > button {\n    width: 100%;\n    height: 100%;\n    background-color: ", ";\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n"], ["\n  display: flex;\n  width: 100%;\n  height: 40px;\n  right: 0;\n  > button {\n    width: 100%;\n    height: 100%;\n    background-color: ", ";\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n"])), function (props) { var _a, _b; return (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.colors) === null || _b === void 0 ? void 0 : _b.primary; });
export default PeopleNumber;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
