import Context from "app/Context";
import { ImageGallery, PageInfo } from "components";
import React, { useContext, useEffect } from "react";
import { Container } from "components/Layout";
import { useStoreActions, useStoreState } from "state/hooks";
var PAGE = "beachClub";
var BeachClub = function (_a) {
    var children = _a.children;
    var language = useContext(Context).language;
    var isLanguageEnglish = language === "spanish";
    var contentByPage = useStoreState(function (store) { return store.content; }).contentByPage;
    var getContentByPage = useStoreActions(function (actions) { return actions.content; }).getContentByPage;
    useEffect(function () {
        getContentByPage(PAGE);
    }, []);
    var images = contentByPage
        .filter(function (content) { return content.type === "experience"; })
        .map(function (image) {
        var _a;
        var description = image.description, englishDescription = image.englishDescription;
        return {
            image: (_a = image.picture[0]) === null || _a === void 0 ? void 0 : _a.url,
            description: isLanguageEnglish ? description : englishDescription,
        };
    });
    var tales = contentByPage
        .filter(function (content) { return content.type === "general"; })
        .map(function (tale, index) {
        var _a;
        var description = tale.description, englishDescription = tale.englishDescription, title = tale.title, englishTitle = tale.englishTitle;
        var content = {
            title: isLanguageEnglish ? title : englishTitle,
            description: isLanguageEnglish ? description : englishDescription,
            image: (_a = tale === null || tale === void 0 ? void 0 : tale.picture[0]) === null || _a === void 0 ? void 0 : _a.url,
        };
        return React.createElement(PageInfo, { data: content, key: "page-beach-club-" + index });
    });
    return (React.createElement(Container, null,
        tales,
        React.createElement(ImageGallery, { images: images })));
};
export { BeachClub };
export default BeachClub;
