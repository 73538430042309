var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import logo from "assets/logo-white.png";
var PanelHeader = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-items: center;\n  background-color: ", ";\n  display: flex;\n  height: 154px;\n  justify-content: center;\n  position: relative;\n  width: 100%;\n"], ["\n  align-items: center;\n  background-color: ", ";\n  display: flex;\n  height: 154px;\n  justify-content: center;\n  position: relative;\n  width: 100%;\n"])), function (props) { var _a, _b; return (_b = (_a = props === null || props === void 0 ? void 0 : props.theme) === null || _a === void 0 ? void 0 : _a.colors) === null || _b === void 0 ? void 0 : _b.blue004e; });
PanelHeader.Logo = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-image: url(", ");\n  background-size: cover;\n  height: 98px;\n  width: 221px;\n"], ["\n  background-image: url(", ");\n  background-size: cover;\n  height: 98px;\n  width: 221px;\n"])), logo);
PanelHeader.Extra = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background-color: ", ";\n  height: 37px;\n  left: 0;\n  position: absolute;\n  top: 100%;\n  width: 100%;\n"], ["\n  background-color: ", ";\n  height: 37px;\n  left: 0;\n  position: absolute;\n  top: 100%;\n  width: 100%;\n"])), function (props) { var _a, _b; return (_b = (_a = props === null || props === void 0 ? void 0 : props.theme) === null || _a === void 0 ? void 0 : _a.colors) === null || _b === void 0 ? void 0 : _b.blue004e; });
export default PanelHeader;
var templateObject_1, templateObject_2, templateObject_3;
