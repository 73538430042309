var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
var CustomSelect = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n"], ["\n  width: 100%;\n  height: 100%;\n"])));
CustomSelect.Label = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-family: \"Roboto-Medium\";\n  font-size: 14px;\n  margin-bottom: 5px;\n"], ["\n  font-family: \"Roboto-Medium\";\n  font-size: 14px;\n  margin-bottom: 5px;\n"])));
CustomSelect.Input = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-family: \"Roboto\";\n  padding: 16px;\n  border: ", ";\n  border-radius: 6px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  cursor: pointer;\n  > select {\n    -webkit-appearance: none;\n    -moz-appearance: textfield;\n    width: 100%;\n    margin-right: 5px;\n    font-size: 14px;\n    background-color: transparent;\n    color: ", ";\n    border: none;\n    cursor: pointer;\n    &:focus {\n      outline: none;\n    }\n  }\n"], ["\n  font-family: \"Roboto\";\n  padding: 16px;\n  border: ", ";\n  border-radius: 6px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  cursor: pointer;\n  > select {\n    -webkit-appearance: none;\n    -moz-appearance: textfield;\n    width: 100%;\n    margin-right: 5px;\n    font-size: 14px;\n    background-color: transparent;\n    color: ", ";\n    border: none;\n    cursor: pointer;\n    &:focus {\n      outline: none;\n    }\n  }\n"])), function (props) { var _a, _b; return "solid 1px " + ((_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.colors) === null || _b === void 0 ? void 0 : _b.gray); }, function (props) { return props.theme.colors.black212; });
CustomSelect.Error = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n  font-family: \"Roboto-Medium\";\n  font-size: 12px;\n  font-weight: 500;\n"], ["\n  color: ", ";\n  font-family: \"Roboto-Medium\";\n  font-size: 12px;\n  font-weight: 500;\n"])), function (params) { var _a; return (_a = params === null || params === void 0 ? void 0 : params.theme) === null || _a === void 0 ? void 0 : _a.colors.redff6; });
export { CustomSelect };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
