var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
var Row = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  justify-content: space-between;\n"], ["\n  display: flex;\n  width: 100%;\n  justify-content: space-between;\n"])));
var InputDescription = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 55%;\n  height: 200px;\n"], ["\n  width: 55%;\n  height: 200px;\n"])));
var ImageEdit = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  height: auto;\n  width: 40%;\n"], ["\n  height: auto;\n  width: 40%;\n"])));
export { Row, InputDescription, ImageEdit };
var templateObject_1, templateObject_2, templateObject_3;
