var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { AmenitySelector, Segment, SolidButton, Title } from "components";
import { AmenitiesContainer, ButtonContainer } from "./styled";
import { useStoreActions, useStoreState } from "state/hooks";
var Component = function () {
    var watch = useFormContext().watch;
    var _a = useFieldArray({
        name: "amenities",
        keyName: "key",
    }), appendAmenities = _a.append, updateAmenities = _a.update, removeAmenities = _a.remove;
    var _b = useFieldArray({
        name: "amenitiesToRemove",
        keyName: "key",
    }), appendAmenitiesToRemove = _b.append, removeAmenitiesToRemove = _b.remove;
    var amenities = useStoreState(function (state) { return state; }).amenity.amenities;
    var getAmenities = useStoreActions(function (actions) { return actions; }).amenity.getAmenities;
    var savedAmenities = watch("amenities");
    var amenitiesToRemove = watch("amenitiesToRemove");
    useEffect(function () {
        getAmenities();
    }, []);
    var onNew = function () {
        var existsEmptyAmenity = (savedAmenities === null || savedAmenities === void 0 ? void 0 : savedAmenities.length) > 0 &&
            (savedAmenities === null || savedAmenities === void 0 ? void 0 : savedAmenities.find(function (amenity) { return amenity.id === undefined; }));
        if (existsEmptyAmenity)
            return;
        appendAmenities({ isNew: true });
    };
    var onChange = function (index, amenity) {
        var isNew = true;
        if (amenitiesToRemove) {
            var indexAmenitiesToRemove = amenitiesToRemove === null || amenitiesToRemove === void 0 ? void 0 : amenitiesToRemove.findIndex(function (amenityToRemove) { return amenityToRemove.id === amenity.id; });
            if (indexAmenitiesToRemove >= 0) {
                removeAmenitiesToRemove(indexAmenitiesToRemove);
                isNew = false;
            }
        }
        updateAmenities(index, __assign(__assign({}, amenity), { isNew: isNew }));
    };
    var onRemove = function (index) {
        var _a = savedAmenities[index], id = _a.id, name = _a.name, isNew = _a.isNew;
        if (!isNew)
            appendAmenitiesToRemove({ id: id, name: name });
        removeAmenities(index);
    };
    var selectableAmenities = amenities === null || amenities === void 0 ? void 0 : amenities.filter(function (amenity) {
        return !(savedAmenities === null || savedAmenities === void 0 ? void 0 : savedAmenities.some(function (savedAmenity) { return amenity.id === savedAmenity.id; }));
    });
    var renderAmenities = savedAmenities &&
        savedAmenities.map(function (amenity, index) {
            var _a;
            var id = amenity.id, isNew = amenity.isNew;
            var readonly = (_a = !isNew) !== null && _a !== void 0 ? _a : true;
            return (React.createElement(AmenitySelector, { index: index, onChange: onChange, onRemove: onRemove, amenities: selectableAmenities, amenitySelected: amenity, key: "amenitySelector-" + id, readonly: readonly }));
        });
    return (React.createElement(Segment, null,
        React.createElement(Title, { variant: "segment" }, "Amenidades"),
        React.createElement(AmenitiesContainer, null, renderAmenities),
        React.createElement(ButtonContainer, null,
            React.createElement(SolidButton, { children: "+ Agregar amenidad", onClick: onNew }))));
};
export { Component as AmenitiesSelectorForm };
export default Component;
