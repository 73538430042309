import React from "react";
import { formatDay } from "utils/dates";
import { Day } from "./styled";
import "./style.css";
var Component = function (date) {
    var day = formatDay(date);
    return (React.createElement(Day, null,
        React.createElement(Day.Value, null, day)));
};
export default Component;
