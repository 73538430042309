import React from "react";
import { navigate } from "@reach/router";
import { useStoreState } from "state/hooks";
import { Profile } from "components";
import { MainColumn } from "components/Layout";
var View = function () {
    var handleEditInfo = function () {
        navigate("/admin/perfil/editar");
    };
    var handleChangePassword = function () {
        navigate("/admin/perfil/cambiar-contrasena");
    };
    var _a = useStoreState(function (store) { return store.auth; }).data, name = _a.name, lastName = _a.lastName, email = _a.email;
    return (React.createElement(MainColumn, null,
        React.createElement(Profile, { name: name + " " + lastName, email: email, handleEditInfo: handleEditInfo, handleChangePassword: handleChangePassword })));
};
export { View as Profile };
export default View;
