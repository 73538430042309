var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
var Row = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: repeat(4, 1fr);\n  grid-gap: 10px;\n  grid-auto-rows: minmax(100px, auto);\n"], ["\n  display: grid;\n  grid-template-columns: repeat(4, 1fr);\n  grid-gap: 10px;\n  grid-auto-rows: minmax(100px, auto);\n"])));
var Column = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  grid-column: span 1;\n"], ["\n  grid-column: span 1;\n"])));
var TwoColumns = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  grid-column: span 2;\n"], ["\n  grid-column: span 2;\n"])));
export { Row, Column, TwoColumns };
var templateObject_1, templateObject_2, templateObject_3;
