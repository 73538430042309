var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { useFormContext } from "react-hook-form";
import { Row, SmallColumn, LargeColumn } from "./styled";
import { CustomInput, Segment, Title } from "components";
var Component = function () {
    var _a = useFormContext(), register = _a.register, watch = _a.watch;
    var ruleName = watch("name");
    return (React.createElement(Segment, null,
        React.createElement(Title, { variant: "segment" }, ruleName),
        React.createElement(Title, { variant: "subtitle" }, "GENERALES"),
        React.createElement(Row, null,
            React.createElement(SmallColumn, null,
                React.createElement(CustomInput, __assign({ label: "Nombre" }, register("name")))),
            React.createElement(LargeColumn, null,
                React.createElement(CustomInput, __assign({ label: "Descripción" }, register("description")))))));
};
export { Component as General };
export default Component;
