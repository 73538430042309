import React from "react";
import { Segment, SelectImages, Title } from "components";
var Component = function (props) {
    var _a = props.fieldName, fieldName = _a === void 0 ? "images" : _a;
    return (React.createElement(Segment, null,
        React.createElement(Title, { variant: "segment" }, "Im\u00E1genes"),
        React.createElement(SelectImages, { fieldName: fieldName, isSelectable: true })));
};
export { Component as GalleryForm };
export default Component;
