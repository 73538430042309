import Context from "app/Context";
import { ImageGallery, PageInfo } from "components";
import React, { useContext, useEffect } from "react";
import { Container } from "components/Layout";
import { useStoreActions, useStoreState } from "state/hooks";
var PAGE = "terrance";
var TerracePool = function (_a) {
    var children = _a.children;
    var language = useContext(Context).language;
    var isLanguageEnglish = language === "spanish";
    var contentByPage = useStoreState(function (store) { return store.content; }).contentByPage;
    var getContentByPage = useStoreActions(function (actions) { return actions.content; }).getContentByPage;
    useEffect(function () {
        getContentByPage(PAGE);
    }, []);
    var images = contentByPage
        .filter(function (content) { return content.type === "imageGallery"; })
        .map(function (image) {
        var description = image.description, englishDescription = image.englishDescription;
        return {
            image: image.picture[0].url,
            description: isLanguageEnglish ? description : englishDescription,
        };
    });
    var tales = contentByPage
        .filter(function (content) { return content.type === "general"; })
        .map(function (tale, index) {
        var _a;
        var description = tale.description, englishDescription = tale.englishDescription, title = tale.title, englishTitle = tale.englishTitle;
        var content = {
            title: isLanguageEnglish ? title : englishTitle,
            description: isLanguageEnglish ? description : englishDescription,
            image: (_a = tale.picture[0]) === null || _a === void 0 ? void 0 : _a.url,
        };
        return React.createElement(PageInfo, { data: content, key: "page-terracepool-" + index });
    });
    return (React.createElement(Container, null,
        tales,
        React.createElement(ImageGallery, { images: images })));
};
export { TerracePool };
export default TerracePool;
