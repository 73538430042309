import { ExplorerItem } from "components";
import React from "react";
import { Services } from "./styled";
var Component = function (props) {
    var data = props.data;
    var renderExplorerItem = data.map(function (item, index) { return (React.createElement(ExplorerItem, { dataItem: item, key: "explorerItem-" + index })); });
    return React.createElement(Services, null, renderExplorerItem);
};
export default Component;
export { Component as Services };
