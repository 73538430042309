var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from "react";
import { faExclamationTriangle, faTimesCircle, faCheckCircle, } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Alert from "./styled";
var Component = function (props) {
    var message = props.message, type = props.type, cleanError = props.cleanError;
    var _a = __read(useState(true), 2), isVisible = _a[0], setVisible = _a[1];
    useEffect(function () {
        setVisible(!(message === ""));
    }, [message]);
    var icon = type === "success"
        ? faCheckCircle
        : type === "danger"
            ? faTimesCircle
            : faExclamationTriangle;
    var renderAlert = isVisible && (React.createElement(Alert, { type: type },
        React.createElement(Alert.Icon, null,
            React.createElement(FontAwesomeIcon, { icon: icon })),
        React.createElement(Alert.Message, null, message),
        React.createElement(Alert.Close, { onClick: cleanError },
            React.createElement(FontAwesomeIcon, { icon: faTimesCircle }))));
    return React.createElement(React.Fragment, null, renderAlert);
};
export { Component as Alert };
export default Component;
