var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from "react";
import { useStoreActions, useStoreState } from "state/hooks";
import { navigate } from "@reach/router";
import { Alert, Empty, Title, UsersAdministration, Segment, ConfirmModal, TextButton, } from "components";
import { BucketList, MainColumn } from "components/Layout";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
var Users = function (_a) {
    var children = _a.children;
    var _b = __read(useState(false), 2), showModal = _b[0], setShowModal = _b[1];
    var _c = useStoreActions(function (actions) { return actions; }).user, getUsers = _c.getUsers, setUser = _c.setUser, changeUserStatus = _c.changeUserStatus, cleanMessage = _c.cleanMessage;
    var _d = useStoreState(function (store) { return store; }).user, users = _d.users, selectedUser = _d.user, message = _d.message;
    useEffect(function () {
        getUsers();
    }, []);
    var onSelect = function (user) {
        setUser(user);
        navigate("/admin/usuarios/editar");
    };
    var onChangeStatus = function (id) {
        var user = users.find(function (user) { return user.id === id; });
        setUser(user);
        setShowModal(true);
    };
    var onConfirmChangeStatus = function () {
        setShowModal(false);
        changeUserStatus({
            user: {
                id: selectedUser === null || selectedUser === void 0 ? void 0 : selectedUser.id,
                isActive: !(selectedUser === null || selectedUser === void 0 ? void 0 : selectedUser.isActive),
            },
        });
    };
    var onCloseConfirmationModal = function () {
        setUser(null);
        setShowModal(false);
    };
    var renderModal = showModal && (React.createElement(ConfirmModal, { showModal: showModal, confirmButtonText: (selectedUser === null || selectedUser === void 0 ? void 0 : selectedUser.isActive) ? "Eliminar" : "Restaurar", text: "\u00BFEst\u00E1 seguro que desea " + ((selectedUser === null || selectedUser === void 0 ? void 0 : selectedUser.isActive) ? "eliminar" : "restaurar") + " al usuario " + (selectedUser === null || selectedUser === void 0 ? void 0 : selectedUser.name) + " " + (selectedUser === null || selectedUser === void 0 ? void 0 : selectedUser.lastName) + "?", onClose: onCloseConfirmationModal, onConfirm: onConfirmChangeStatus }));
    var renderUsers = users &&
        (users === null || users === void 0 ? void 0 : users.map(function (user, index) {
            return (React.createElement(UsersAdministration, { key: "user-" + index, onChangeStatus: onChangeStatus, onSelect: onSelect, data: user }));
        }));
    var renderEmpty = users.length === 0 && (React.createElement(Empty, { text: "No hay usuarios por mostrar" }));
    var renderMessage = message && (React.createElement(Alert, { message: message.text, type: message.success ? "success" : "danger", cleanError: cleanMessage }));
    var renderLink = (React.createElement(TextButton, { onClick: function () { return navigate("/admin/usuarios/editar"); } },
        React.createElement(FontAwesomeIcon, { icon: faPlus }),
        "Agregar usuario"));
    return (React.createElement(MainColumn, null,
        React.createElement(Segment, null,
            renderModal,
            React.createElement(Title, { variant: "segment" }, "Usuarios"),
            renderMessage,
            renderEmpty,
            React.createElement(BucketList, null, renderUsers),
            renderLink)));
};
export { Users };
export default Users;
