var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { useFormContext } from "react-hook-form";
import { Segment, Title, InputContainer, CustomInput } from "components";
var Component = function () {
    var _a, _b;
    var _c = useFormContext(), register = _c.register, errors = _c.formState.errors;
    return (React.createElement(Segment, null,
        React.createElement(Title, { variant: "segment" }, "Cambiar Contrase\u00F1a"),
        React.createElement(InputContainer, null,
            React.createElement(CustomInput, __assign({ type: "password", label: "Nueva contraseña", placeholder: "Nueva contraseña" }, register("password"), { error: (_a = errors.password) === null || _a === void 0 ? void 0 : _a.message }))),
        React.createElement(InputContainer, null,
            React.createElement(CustomInput, __assign({ type: "password", label: "Confirmar nueva contraseña", placeholder: "Confirmar contraseña" }, register("confirmPassword"), { error: (_b = errors.confirmPassword) === null || _b === void 0 ? void 0 : _b.message })))));
};
export { Component as ChangePassword };
export default Component;
