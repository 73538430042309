var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from "react";
import { BungalowItem, HeaderImage, Loader, SortMenu } from "components";
import { Container, Content } from "components/Layout";
import { useStoreActions, useStoreState } from "state/hooks";
import { isEmpty } from "lodash";
import { optionsToSort } from "utils/bungalowFactory";
var PAGE = "bungalows";
var BungalowsList = function (_a) {
    var children = _a.children;
    var _b = __read(useState(optionsToSort[0]), 2), optionSelected = _b[0], setOptionSelected = _b[1];
    var _c = useStoreState(function (state) { return state; }), _d = _c.bungalow, bungalows = _d.bungalows, isLoading = _d.isLoading, contentByPage = _c.content.contentByPage;
    var _e = useStoreActions(function (actions) { return actions; }), getBungalows = _e.bungalow.getBungalows, getContentByPage = _e.content.getContentByPage;
    useEffect(function () {
        getContentByPage(PAGE);
        if (isEmpty(bungalows)) {
            loadBungalows();
        }
    }, []);
    useEffect(function () {
        loadBungalows();
    }, [optionSelected]);
    var loadBungalows = function () {
        getBungalows({ isAsc: (optionSelected === null || optionSelected === void 0 ? void 0 : optionSelected.id) === "ASC" });
    };
    var renderLoading = isLoading && React.createElement(Loader, null);
    var renderHeaderImage = contentByPage
        .filter(function (content) { return content.type === "banner"; })
        .map(function (image) {
        return React.createElement(HeaderImage, { image: image.picture[0].url });
    });
    var renderBungalows = bungalows &&
        bungalows.map(function (bungalow) {
            return (React.createElement(BungalowItem, { key: "bungalow-" + bungalow.id, dataBungalow: bungalow }));
        });
    return (React.createElement(Content, null,
        renderHeaderImage,
        React.createElement(Container, null,
            React.createElement(SortMenu, { optionSelected: optionSelected, setOptionSelected: setOptionSelected, options: optionsToSort }),
            renderLoading,
            renderBungalows)));
};
export { BungalowsList };
export default BungalowsList;
