import React, { useContext } from "react";
import { Link } from "@reach/router";
import { faMapMarkedAlt, faEnvelope, faPhone, } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp, } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container } from "components/Screen";
import footerImage from "assets/footer-image.png";
import Footer from "./styled";
import { links } from "data";
import Context from "app/Context";
var Component = function (props) {
    var isEnglish = useContext(Context).isEnglish;
    var renderMapItems = function () {
        return links.map(function (_a, index) {
            var name = _a.name, path = _a.path, englishName = _a.englishName;
            return (React.createElement(Footer.MapItem, { key: "map-item-" + index },
                React.createElement(Link, { to: path }, isEnglish ? englishName : name)));
        });
    };
    return (React.createElement(Footer, null,
        React.createElement(Container, null,
            React.createElement(Footer.Info, null,
                React.createElement("h3", null, isEnglish ? "ABOUT US" : "ACERCA DE NOSOTROS"),
                React.createElement("hr", null),
                React.createElement("p", null, isEnglish
                    ? "We are in Santiago just 5 min walk away from the beach, with views of the best sunsets in Manzanillo."
                    : "Nos encontramos en Santiago, a 5 min. de la playa caminando, con vistas a los mejores atardeceres de Manzanillo."),
                React.createElement(Footer.BulledInfo, null,
                    React.createElement(FontAwesomeIcon, { icon: faMapMarkedAlt }),
                    React.createElement("p", null, "Calle dos, No. 3, Colonia: Colinas de Santiago, C.P. 28863.")),
                React.createElement(Footer.BulledInfo, null,
                    React.createElement(FontAwesomeIcon, { icon: faEnvelope }),
                    React.createElement("p", null, "booking@bungalowsalcaraz.com")),
                React.createElement(Footer.BulledInfo, null,
                    React.createElement(FontAwesomeIcon, { icon: faPhone }),
                    React.createElement("p", null, "01 (314) 33-309-53")),
                React.createElement(Footer.BulledInfo, null,
                    React.createElement(FontAwesomeIcon, { icon: faWhatsapp }),
                    React.createElement("p", null, "314-197-0482"))),
            React.createElement(Footer.Map, null,
                React.createElement("h3", null, isEnglish ? "SITEMAP" : "MAPA DE SITIO"),
                React.createElement("hr", null),
                renderMapItems()),
            React.createElement(Footer.Nets, null,
                React.createElement("img", { src: footerImage })))));
};
export { Component as Footer };
export default Component;
