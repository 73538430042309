var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import logo from "assets/logo.png";
import { mobileDevice } from "style/constants";
import { ContainerShadowCss } from "style/common";
var Header = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  background-color: ", ";\n  ", "\n  height: 88px;\n  z-index: 1;\n  position: relative;\n  > div {\n    display: grid;\n    grid-template:\n      \"leftContent rightContent\" 88px\n      \"bottomContent bottomContent\" 0\n      /20% 80%;\n    justify-items: center;\n    align-items: center;\n  }\n  @media ", " {\n    height: auto;\n    width: 100%;\n    > div {\n      grid-template-columns: 50% 50%;\n      grid-template-rows: 88px auto;\n    }\n  }\n"], ["\n  width: 100%;\n  background-color: ", ";\n  ", "\n  height: 88px;\n  z-index: 1;\n  position: relative;\n  > div {\n    display: grid;\n    grid-template:\n      \"leftContent rightContent\" 88px\n      \"bottomContent bottomContent\" 0\n      /20% 80%;\n    justify-items: center;\n    align-items: center;\n  }\n  @media ", " {\n    height: auto;\n    width: 100%;\n    > div {\n      grid-template-columns: 50% 50%;\n      grid-template-rows: 88px auto;\n    }\n  }\n"])), function (props) {
    return (props === null || props === void 0 ? void 0 : props.transparent) ? "transparent" : "white";
}, function (props) { return (props.transparent ? "" : ContainerShadowCss); }, mobileDevice);
Header.Logo = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  grid-area: leftContent;\n  height: 60px;\n  width: 135px;\n  background-image: url(", ");\n  justify-self: flex-start;\n"], ["\n  grid-area: leftContent;\n  height: 60px;\n  width: 135px;\n  background-image: url(", ");\n  justify-self: flex-start;\n"])), logo);
Header.MenuTrigger = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  grid-area: rightContent;\n  cursor: pointer;\n  place-self: right;\n  justify-self: flex-end;\n  display: none;\n  @media ", " {\n    margin-right: 30px;\n    display: inline;\n  }\n"], ["\n  grid-area: rightContent;\n  cursor: pointer;\n  place-self: right;\n  justify-self: flex-end;\n  display: none;\n  @media ", " {\n    margin-right: 30px;\n    display: inline;\n  }\n"])), mobileDevice);
Header.Menu = styled.ul(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  grid-area: rightContent;\n  display: inline-flex;\n  padding: unset;\n  height: fit-content;\n  overflow: hidden;\n  justify-self: flex-end;\n  align-items: center;\n  transition: height 0.2s ease-in-out;\n  @media ", " {\n    grid-area: bottomContent;\n    background-color: #f9f9f9;\n    flex-direction: column;\n    width: 100%;\n    height: 0;\n    margin: 0;\n    &.open {\n      height: 360px;\n    }\n  }\n"], ["\n  grid-area: rightContent;\n  display: inline-flex;\n  padding: unset;\n  height: fit-content;\n  overflow: hidden;\n  justify-self: flex-end;\n  align-items: center;\n  transition: height 0.2s ease-in-out;\n  @media ", " {\n    grid-area: bottomContent;\n    background-color: #f9f9f9;\n    flex-direction: column;\n    width: 100%;\n    height: 0;\n    margin: 0;\n    &.open {\n      height: 360px;\n    }\n  }\n"])), mobileDevice);
Header.MenuItem = styled.li(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: inline-flex;\n  margin: 0 15px;\n  > a {\n    text-decoration: none;\n    color: #606061;\n    border-bottom: solid 3px transparent;\n    font-family: \"Roboto\";\n    &:hover {\n      color: #000;\n      border-bottom: solid 3px #000;\n    }\n  }\n\n  @media ", " {\n    display: block;\n    margin: unset;\n    > a {\n      display: block;\n      text-align: center;\n      padding: 15px 0;\n      &:hover {\n        background-color: #f3f3f3;\n      }\n    }\n  }\n"], ["\n  display: inline-flex;\n  margin: 0 15px;\n  > a {\n    text-decoration: none;\n    color: #606061;\n    border-bottom: solid 3px transparent;\n    font-family: \"Roboto\";\n    &:hover {\n      color: #000;\n      border-bottom: solid 3px #000;\n    }\n  }\n\n  @media ", " {\n    display: block;\n    margin: unset;\n    > a {\n      display: block;\n      text-align: center;\n      padding: 15px 0;\n      &:hover {\n        background-color: #f3f3f3;\n      }\n    }\n  }\n"])), mobileDevice);
export default Header;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
