var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import Component from "../";
import Styled from "../styled";
var InitialsInMenu = styled(Component)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", " {\n    background-color: ", ";\n    color: ", ";\n    padding: 15px;\n    font-size: 1.5em;\n  }\n"], ["\n  ", " {\n    background-color: ", ";\n    color: ", ";\n    padding: 15px;\n    font-size: 1.5em;\n  }\n"])), Styled.InitialName, function (props) { var _a; return (_a = props === null || props === void 0 ? void 0 : props.theme) === null || _a === void 0 ? void 0 : _a.colors.blue3A6E; }, function (props) { var _a; return (_a = props === null || props === void 0 ? void 0 : props.theme) === null || _a === void 0 ? void 0 : _a.colors.whitefff; });
export default InitialsInMenu;
var templateObject_1;
