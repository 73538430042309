import React from "react";
import InitialName from "./styled";
var Component = function (props) {
    var className = props.className, name = props.name, _a = props.isInMenu, isInMenu = _a === void 0 ? false : _a;
    var initials = name
        .split(" ")
        .map(function (n) { return n[0]; })
        .join("")
        .toUpperCase();
    return (React.createElement(InitialName, { className: className, isInMenu: isInMenu }, initials));
};
export { Component as InitialsName };
export default Component;
