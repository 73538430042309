var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Label, HelpText, InputContainer, Error, Icon, Input, } from "./styled";
var Component = forwardRef(function (props, ref) {
    var label = props.label, helpText = props.helpText, onClick = props.onClick, placeholder = props.placeholder, _a = props.disabled, disabled = _a === void 0 ? false : _a, _b = props.readOnly, readOnly = _b === void 0 ? false : _b, _c = props.type, type = _c === void 0 ? "text" : _c, icon = props.icon, _d = props.iconPosition, iconPosition = _d === void 0 ? "after" : _d, error = props.error, name = props.name, value = props.value, _e = props.showLabel, showLabel = _e === void 0 ? true : _e, rest = __rest(props, ["label", "helpText", "onClick", "placeholder", "disabled", "readOnly", "type", "icon", "iconPosition", "error", "name", "value", "showLabel"]);
    var renderIcon = icon && (React.createElement(Icon, null,
        React.createElement(FontAwesomeIcon, { icon: icon })));
    var renderLabel = showLabel && (React.createElement(Label, null,
        label,
        React.createElement(HelpText, null, helpText)));
    return (React.createElement(Container, null,
        renderLabel,
        React.createElement(InputContainer, { onClick: onClick },
            iconPosition === "before" && renderIcon,
            React.createElement(Input, __assign({ ref: ref, value: value, name: name, placeholder: placeholder, disabled: disabled, readOnly: readOnly, type: type }, rest)),
            iconPosition === "after" && renderIcon),
        error && React.createElement(Error, null, error)));
});
export { Component as CustomInput };
export default Component;
