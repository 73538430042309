var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useEffect } from "react";
import { faAngleLeft, faAngleDoubleLeft, faAngleRight, faAngleDoubleRight, } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Pagination, Button, PageLabel } from "./styled";
var Component = function (props) {
    var _a = props.pagination, nextPage = _a.nextPage, previousPage = _a.previousPage, totalPages = _a.totalPages, onChangePage = _a.onChangePage, currentPage = _a.currentPage;
    var onNextPage = function () { return onChangePage(nextPage); };
    var onPreviousPage = function () { return onChangePage(previousPage); };
    var gotoPage = function (page) { return onChangePage(page); };
    var _b = __read(useState(false), 2), canPreviousPage = _b[0], setCanPreviousPage = _b[1];
    var _c = __read(useState(false), 2), canNextPage = _c[0], setCanNextPage = _c[1];
    useEffect(function () {
        setCanPreviousPage(previousPage !== null);
        setCanNextPage(nextPage !== null);
    }, [totalPages, currentPage]);
    return (React.createElement(Pagination, null,
        React.createElement(Button, { onClick: function () { return gotoPage(1); }, disabled: !canPreviousPage },
            React.createElement(FontAwesomeIcon, { icon: faAngleDoubleLeft })),
        React.createElement(Button, { onClick: onPreviousPage, disabled: !canPreviousPage },
            React.createElement(FontAwesomeIcon, { icon: faAngleLeft })),
        React.createElement(PageLabel, null,
            "Pagina ",
            currentPage,
            " de ",
            totalPages),
        React.createElement(Button, { onClick: onNextPage, disabled: !canNextPage },
            React.createElement(FontAwesomeIcon, { icon: faAngleRight })),
        React.createElement(Button, { onClick: function () { return gotoPage(totalPages); }, disabled: !canNextPage },
            React.createElement(FontAwesomeIcon, { icon: faAngleDoubleRight }))));
};
export { Component as ServerSidePagination };
export default Component;
