export var theme = {
    backgroundColor: "#000",
    colors: {
        primary: "#00C5FF",
        whitefff: "#FFFFFF",
        redff6: "#ff6566",
        rede2: "#E24A4A",
        redLigth: "#FFCCCC",
        white: "#FFFFFF",
        black: "black",
        grayced4: "#ced4da",
        grayced4Transparent: "#33 ced4da",
        grayf8f: "#f8f9fa",
        grayeae: "#eaeaea",
        gray: "#adb5bd",
        greenDEF: "#DEFFEB",
        green219: "#219653",
        blue004e: "#004e98",
        blue3A6E: "#3A6EA5",
        blue1960: "#1960A2",
        blue00c5: "#00c5ff",
        black212: "#212529",
        gray495: "#495057",
        blueeaf: "#EAFBFF",
        black000: "#000000",
        grayadb: "#adb5bd",
        rede24: "#E24A4A",
        redF16D: "#F16D6D",
        redFFD: "#FFDEDE",
        red9E0: "#9E0505",
        redLight: "#FFCCCC",
        yellowFFF: "#FFFCDE",
        yellowAE7: "#AE7E04",
        black000c2: "#000000c2",
        grayd2d2: "#d2d2d2",
        green3BC: "#3BC794",
    },
    fonts: {
        primary: "#ffffff",
        secondary: "#212529",
        blue004: "#004E98",
        secondaryText: "#495057",
        red: "#E24A4A",
    },
    fontFamily: {
        primary: '"Roboto"',
        medium: "Roboto-Medium",
        bold: "Roboto-Bold",
        secondaryBold: '"Sora-Bold"',
    },
};
