var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useStoreActions, useStoreState } from "state/hooks";
import { ActionButtons, AmenitiesSelectorForm, Banners, Calendars, ConfirmModal, FeaturesForm, GeneralEditor, ListItem, Loader, RuleSelection, Title, } from "components";
import { LeftColumn, MainColumn, RightColumnNoOverflow, TwoColumnsLayout, } from "components/Layout";
import GalleryForm from "components/Forms/common/GalleryForm";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { cleanMockCompleteBungalow, disscount } from "mock";
import { format } from "date-fns/esm";
import { partition } from "lodash";
import useNotification from "hooks/useNotification";
var schema = yup.object().shape({
    name: yup.string().required("Debe ingresar un nombre para el bungalow"),
    description: yup.string().required("Ingrese una descripción"),
    baseRule: yup.object().shape({
        basePrice: yup
            .number()
            .transform(function (value) { return (isNaN(value) ? undefined : value); })
            .required("Indique el precio por noche"),
        weekendPrice: yup
            .number()
            .transform(function (value) { return (isNaN(value) ? undefined : value); })
            .required("Indique el precio para fines de semana"),
        cleaningCharge: yup
            .number()
            .transform(function (value) { return (isNaN(value) ? undefined : value); })
            .required("Indique el precio de la limpieza"),
        minimumStay: yup
            .number()
            .transform(function (value) { return (isNaN(value) ? undefined : value); })
            .required("Indique el mínimo de noches para reservar"),
        capacity: yup
            .number()
            .transform(function (value) { return (isNaN(value) ? undefined : value); })
            .required("Indique el máximo de personas"),
        childrenAllowed: yup
            .number()
            .transform(function (value) { return (isNaN(value) ? undefined : value); })
            .required("Indique l máximo de infantes"),
    }),
});
var PAGE = "bungalows";
var Bungalows = function () {
    var _a = __read(useState(true), 2), showMainImageSection = _a[0], setShowMainImageSection = _a[1];
    var _b = __read(useState(0), 2), selectedBungalow = _b[0], setSelectedBungalow = _b[1];
    var _c = __read(useState(false), 2), showForm = _c[0], setShowForm = _c[1];
    var _d = __read(useState(false), 2), showModal = _d[0], setShowModal = _d[1];
    var addNotification = useNotification().addNotification;
    var _e = useStoreState(function (state) { return state; }), _f = _e.bungalow, bungalows = _f.bungalows, isLoading = _f.isLoading, message = _f.message, contentByPage = _e.content.contentByPage;
    var _g = useStoreActions(function (actions) { return actions; }), _h = _g.bungalow, getBungalows = _h.getBungalows, createBungalow = _h.createBungalow, deleteBungalow = _h.deleteBungalow, cleanMessage = _h.cleanMessage, updateBungalow = _h.updateBungalow, _j = _g.content, getContentByPage = _j.getContentByPage, createContentItems = _j.createContentItems, updateContentItems = _j.updateContentItems;
    var methodsBanner = useForm({
        defaultValues: {
            item: [],
        },
    });
    var handleSubmitBanner = methodsBanner.handleSubmit, resetBanner = methodsBanner.reset;
    var methods = useForm({
        resolver: yupResolver(schema),
    });
    var handleSubmit = methods.handleSubmit, reset = methods.reset;
    useEffect(function () {
        getBungalows();
        getContentByPage(PAGE);
        cleanMessage();
    }, []);
    useEffect(function () {
        var banner = contentByPage === null || contentByPage === void 0 ? void 0 : contentByPage.filter(function (contentItem) { return contentItem.type === "banner"; });
        resetBanner({
            item: banner,
        });
    }, [contentByPage]);
    useEffect(function () {
        var mainImage = selectedBungalow === 0;
        setShowMainImageSection(mainImage);
        setShowForm(!mainImage);
        if (!mainImage) {
            var ruleStayDiscount = getStayDiscount();
            var bungalowToSet = __assign(__assign({}, bungalows[selectedBungalow - 1]), { ruleStayDiscount: ruleStayDiscount });
            reset(bungalowToSet);
        }
    }, [selectedBungalow, bungalows]);
    var getStayDiscount = function () {
        var _a;
        var baseRule = ((_a = bungalows[selectedBungalow - 1]) !== null && _a !== void 0 ? _a : {}).baseRule;
        var ruleStayDiscount = (baseRule !== null && baseRule !== void 0 ? baseRule : {}).ruleStayDiscount;
        return disscount.map(function (_a) {
            var _b;
            var nightsStay = _a.nightsStay;
            return ((_b = ruleStayDiscount === null || ruleStayDiscount === void 0 ? void 0 : ruleStayDiscount.filter(function (night) { return night.nightsStay === nightsStay; })[0]) !== null && _b !== void 0 ? _b : {
                nightsStay: nightsStay,
                discount: 0,
            });
        });
    };
    var items = __spreadArray([
        {
            title: "Imagen principal",
        }
    ], __read(bungalows === null || bungalows === void 0 ? void 0 : bungalows.map(function (_a) {
        var name = _a.name;
        return {
            title: name,
        };
    })), false);
    var onAddItem = function () {
        setSelectedBungalow(-1);
        setShowForm(true);
        setShowMainImageSection(false);
        var ruleStayDiscount = getStayDiscount();
        var bungalowToSet = __assign(__assign({}, cleanMockCompleteBungalow), { ruleStayDiscount: ruleStayDiscount });
        reset(bungalowToSet);
    };
    var onSubmitBanner = function (data) {
        var banner = data.item.map(function (item) {
            return __assign(__assign({}, item), { type: "banner", page: PAGE });
        });
        var _a = __read(partition(__spreadArray([], __read(banner), false), "id"), 2), contentItemsWithId = _a[0], contentItemsWithOutId = _a[1];
        if (contentItemsWithId.length) {
            updateContentItems(banner);
        }
        if (contentItemsWithOutId.length) {
            createContentItems(contentItemsWithOutId);
        }
    };
    var onSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var id, calendars, amenities, amenitiesToRemove, pictures, baseRule, rules, name, description, features, mainPictureId, ruleStayDiscount, amenitiesToAdd, calendarsToRemove, calendarsToAdd, rulesToAdd, rulesToRemove, picturesToAdd, picturesToRemove, newBungalow, res, messageNotification, res, messageNotification;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    id = data.id, calendars = data.calendars, amenities = data.amenities, amenitiesToRemove = data.amenitiesToRemove, pictures = data.pictures, baseRule = data.baseRule, rules = data.rules, name = data.name, description = data.description, features = data.features, mainPictureId = data.mainPictureId, ruleStayDiscount = data.ruleStayDiscount;
                    amenitiesToAdd = amenities === null || amenities === void 0 ? void 0 : amenities.filter(function (amenity) { return amenity.isNew && amenity.id !== undefined; }).map(function (amenity) {
                        var id = amenity.id, name = amenity.name, isNew = amenity.isNew;
                        if (isNew)
                            return {
                                id: id,
                                name: name,
                            };
                    });
                    calendarsToRemove = calendars === null || calendars === void 0 ? void 0 : calendars.filter(function (calendar) { return calendar.toDelete; }).map(function (calendar) {
                        return calendar.id;
                    });
                    calendarsToAdd = calendars === null || calendars === void 0 ? void 0 : calendars.filter(function (calendar) { return calendar.isNew && calendar.name !== undefined; }).map(function (calendar) {
                        var name = calendar.name, url = calendar.url;
                        return {
                            name: name,
                            url: url,
                        };
                    });
                    rulesToAdd = rules === null || rules === void 0 ? void 0 : rules.filter(function (rule) { return rule.isNew; }).map(function (bungalowRule) {
                        var id = bungalowRule.rule.id, startDate = bungalowRule.startDate, endDate = bungalowRule.endDate;
                        return {
                            ruleId: id,
                            startDate: startDate ? format(startDate, "yyyy-MM-dd") : startDate,
                            endDate: endDate ? format(endDate, "yyyy-MM-dd") : endDate,
                        };
                    });
                    rulesToRemove = rules === null || rules === void 0 ? void 0 : rules.filter(function (rule) { return rule.toDelete; }).map(function (bungalowRule) {
                        var id = bungalowRule.rule.id, startDate = bungalowRule.startDate, endDate = bungalowRule.endDate;
                        return {
                            ruleId: id,
                            startDate: startDate
                                ? format(new Date(startDate + " 00:00"), "yyyy-MM-dd")
                                : startDate,
                            endDate: endDate
                                ? format(new Date(endDate + " 00:00"), "yyyy-MM-dd")
                                : endDate,
                        };
                    });
                    picturesToAdd = pictures
                        ? pictures
                            .filter(function (image) { return image.picture != undefined; })
                            .map(function (image) {
                            if (image.picture !== undefined)
                                return image.picture[0];
                        })
                        : undefined;
                    picturesToRemove = pictures &&
                        pictures
                            .filter(function (image) { return image.toDelete; })
                            .map(function (image) {
                            return image.id;
                        });
                    delete baseRule.ruleStayDiscount;
                    newBungalow = {
                        amenities: {
                            amenitiesToAdd: amenitiesToAdd,
                            amenitiesToRemove: amenitiesToRemove,
                        },
                        baseRule: baseRule,
                        bungalow: {
                            id: id,
                            name: name,
                            description: description,
                            features: features,
                            mainPictureId: mainPictureId === null || mainPictureId === void 0 ? void 0 : mainPictureId.toString(),
                        },
                        calendars: {
                            calendarsToAdd: calendarsToAdd,
                            calendarsToRemove: calendarsToRemove,
                        },
                        pictures: { picturesToAdd: picturesToAdd, picturesToRemove: picturesToRemove },
                        rules: { rulesToAdd: rulesToAdd, rulesToRemove: rulesToRemove },
                        ruleStayDiscount: ruleStayDiscount,
                    };
                    if (!id) return [3 /*break*/, 2];
                    delete newBungalow.baseRule.name;
                    delete newBungalow.baseRule.description;
                    return [4 /*yield*/, updateBungalow(newBungalow)];
                case 1:
                    res = _a.sent();
                    messageNotification = res
                        ? "Bungalow actualizado"
                        : "Bungalow no actualizado";
                    addNotification(messageNotification, res ? "success" : "danger");
                    return [3 /*break*/, 4];
                case 2:
                    delete newBungalow.bungalow.id;
                    return [4 /*yield*/, createBungalow(newBungalow)];
                case 3:
                    res = _a.sent();
                    messageNotification = res
                        ? "Bungalow creado"
                        : "Bungalow no creado";
                    addNotification(messageNotification, res ? "success" : "danger");
                    _a.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleDelete = function () { return __awaiter(void 0, void 0, void 0, function () {
        var id, res, messageNotification;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setShowModal(false);
                    id = bungalows[selectedBungalow - 1].id;
                    return [4 /*yield*/, deleteBungalow({
                            bungalow: {
                                id: id,
                            },
                        })];
                case 1:
                    res = _a.sent();
                    messageNotification = res
                        ? "Se eliminó el bungalow"
                        : "No se pudo eliminar el bungalow";
                    addNotification(messageNotification, res ? "success" : "danger");
                    setSelectedBungalow(0);
                    return [2 /*return*/];
            }
        });
    }); };
    var defineHandleDelete = selectedBungalow !== -1 ? function () { return setShowModal(true); } : undefined;
    var renderMainImageSection = showMainImageSection && (React.createElement(FormProvider, __assign({}, methodsBanner),
        React.createElement(Banners, { title: "Imagen principal", singleImage: true }),
        React.createElement(ActionButtons, { handleSubmit: handleSubmitBanner(onSubmitBanner) })));
    var renderModal = showModal && (React.createElement(ConfirmModal, { showModal: showModal, confirmButtonText: "Eliminar", text: "\u00BFEst\u00E1 seguro que desea eliminar el bungalow " + bungalows[selectedBungalow - 1].name, onClose: function () { return setShowModal(false); }, onConfirm: handleDelete }));
    var renderLoading = isLoading && React.createElement(Loader, null);
    var renderForm = showForm && (React.createElement(React.Fragment, null,
        React.createElement(GeneralEditor, null),
        React.createElement(GalleryForm, { fieldName: "pictures" }),
        React.createElement(FeaturesForm, null),
        React.createElement(AmenitiesSelectorForm, null),
        React.createElement(Calendars, null),
        React.createElement(RuleSelection, null),
        React.createElement(ActionButtons, { handleSubmit: handleSubmit(onSubmit), handleDelete: defineHandleDelete })));
    var renderContent = (React.createElement(FormProvider, __assign({}, methods),
        React.createElement(TwoColumnsLayout, null,
            React.createElement(LeftColumn, null,
                React.createElement(ListItem, { optionSelected: selectedBungalow, onAddItem: onAddItem, onClickItem: setSelectedBungalow, textForNewItem: "Agregar bungalow", items: items })),
            React.createElement(RightColumnNoOverflow, null,
                renderMainImageSection,
                renderForm))));
    return (React.createElement(MainColumn, null,
        renderModal,
        React.createElement(Title, null, "Bungalows"),
        renderLoading,
        renderContent));
};
export { Bungalows };
export default Bungalows;
