var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var GeneralEditor = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  height: fit-content;\n  flex-wrap: wrap;\n  padding: 1em;\n  box-sizing: border-box;\n"], ["\n  display: flex;\n  width: 100%;\n  height: fit-content;\n  flex-wrap: wrap;\n  padding: 1em;\n  box-sizing: border-box;\n"])));
export var Section = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  flex-wrap: wrap;\n  justify-content: flex-start;\n  align-items: center;\n  margin-bottom: 1em;\n  gap: 15px;\n"], ["\n  display: flex;\n  width: 100%;\n  flex-wrap: wrap;\n  justify-content: flex-start;\n  align-items: center;\n  margin-bottom: 1em;\n  gap: 15px;\n"])));
export var InputName = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n"], ["\n  width: 100%;\n  height: 100%;\n"])));
export var InputDesciption = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n"], ["\n  width: 100%;\n  height: 100%;\n"])));
export var InputContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 32%;\n"], ["\n  width: 32%;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
