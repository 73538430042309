import _ from "lodash";
import axios from "axios";
import { extractFiles } from "extract-files";

import store from "state/store";

const url = "https://www.bungalowsalcaraz.com/graphql";
const method = "POST";

export const requesterWithMultipart = (query, variables) =>
  requester(query, variables, true);

export const requesterWithoutHeaders = (query, variables) =>
  requester(query, variables, false, false);

export const requester = (
  query,
  variables = {},
  multipart = false,
  withHeaders = true
) => {
  const {
    auth: { bearer },
  } = store.getState();
  const {
    auth: { setBearer },
  } = store.getActions();

  const form = new FormData();
  if (multipart) {
    const { clone, files } = extractFiles({ query, variables });
    const operationJSON = JSON.stringify(clone);
    if (files.size) {
      form.append("operations", operationJSON);
      const map = {};
      let i = 0;
      files.forEach((paths) => {
        map[++i] = paths;
      });
      form.append("map", JSON.stringify(map));
      i = 0;
      files.forEach((paths, file) => {
        form.append(`${++i}`, file, file.name);
      });
    }
  }

  const headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    ...(withHeaders ? { bearer } : {}),
  };

  return axios({
    url,
    method,
    headers,
    data: multipart
      ? form
      : {
          query,
          variables,
        },
  })
    .then((response) => {
      const errors = response.data.errors;
      const _query = Object.keys(response.data.data)[0];
      const queryObj = response.data.data[_query];

      if (!_.isEmpty(errors)) {
        throw errors;
      }
      if (_.has(response.headers, ["bearer"])) {
        setBearer(response.headers["bearer"]);
      }
      return queryObj;
    })
    .catch((error) => {
      return { errors: [error] };
    });
};
