var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { ActionButtons, Title, ListItem, TitleImage, ConfirmModal, EditInformationImage, } from "components";
import { TwoColumnsLayout, LeftColumn, MainColumn, RightColumn, } from "components/Layout";
import { useStoreActions, useStoreState } from "state/hooks";
import { partition } from "lodash";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
var PAGE = "activities";
var Activities = function () {
    var _a = __read(useState([]), 2), items = _a[0], setItems = _a[1];
    var _b = __read(useState([]), 2), activities = _b[0], setActivities = _b[1];
    var _c = __read(useState(true), 2), showGeneralSection = _c[0], setShowGeneralSection = _c[1];
    var _d = __read(useState(false), 2), showForm = _d[0], setShowForm = _d[1];
    var _e = __read(useState(0), 2), itemSelected = _e[0], setItemSelected = _e[1];
    var _f = __read(useState(false), 2), showModal = _f[0], setShowModal = _f[1];
    var schema = yup.object().shape({
        itemGallery: yup.object().shape({
            title: yup
                .string()
                .required("Ingresa en nombre de la actividad")
                .test("Unique", "Actividad registrada", function (value) {
                return !activities.some(function (_a) {
                    var title = _a.title;
                    return (title === null || title === void 0 ? void 0 : title.toLowerCase()) === value.toLowerCase();
                });
            }),
        }),
    });
    var _g = useStoreActions(function (actions) { return actions.content; }), getContentByPage = _g.getContentByPage, createContentItems = _g.createContentItems, updateContentItems = _g.updateContentItems, deleteContentItems = _g.deleteContentItems;
    var contentByPage = useStoreState(function (store) { return store.content; }).contentByPage;
    useEffect(function () {
        getContentByPage(PAGE);
    }, []);
    var methods = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            itemGallery: {},
            generals: {},
        },
    });
    var handleSubmit = methods.handleSubmit, reset = methods.reset;
    useEffect(function () {
        var generals = (contentByPage === null || contentByPage === void 0 ? void 0 : contentByPage.filter(function (contentItem) { return contentItem.type === "general"; })[0]) || {};
        loadData();
        reset({
            itemGallery: activities[itemSelected !== 0 ? itemSelected - 1 : itemSelected],
            generals: generals,
        });
    }, [itemSelected, contentByPage]);
    var loadData = function () {
        var activities = contentByPage === null || contentByPage === void 0 ? void 0 : contentByPage.filter(function (contentItem) { return contentItem.type === "activity"; });
        setActivities(activities);
        var showGeneral = itemSelected === 0;
        setShowGeneralSection(showGeneral);
        setShowForm(!showGeneral);
        setItems(__spreadArray([
            { title: "Generales" }
        ], __read(contentByPage === null || contentByPage === void 0 ? void 0 : contentByPage.filter(function (contentItem) { return contentItem.type === "activity"; }).map(function (_a) {
            var title = _a.title;
            return {
                title: title,
            };
        })), false));
    };
    var onAddItem = function () {
        setShowGeneralSection(false);
        setShowForm(true);
        reset({
            itemGallery: {
                id: "",
                title: "",
                englishTitle: "",
                picture: [],
            },
            generals: (contentByPage === null || contentByPage === void 0 ? void 0 : contentByPage.filter(function (contentItem) { return contentItem.type === "general"; })[0]) || {},
        });
        setItemSelected(-1);
    };
    var onSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var newActivity, generals, _a, contentItemsWithId, contentItemsWithOutId;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    newActivity = __assign(__assign({}, data.itemGallery), { type: "activity", page: PAGE });
                    generals = __assign(__assign({}, data.generals), { type: "general", page: PAGE });
                    _a = __read(partition([generals, newActivity], "id"), 2), contentItemsWithId = _a[0], contentItemsWithOutId = _a[1];
                    if (!(contentItemsWithId.length > 0)) return [3 /*break*/, 2];
                    return [4 /*yield*/, updateContentItems(contentItemsWithId)];
                case 1:
                    _b.sent();
                    _b.label = 2;
                case 2:
                    if (!(contentItemsWithOutId.length > 0)) return [3 /*break*/, 4];
                    return [4 /*yield*/, createContentItems(contentItemsWithOutId)];
                case 3:
                    _b.sent();
                    _b.label = 4;
                case 4:
                    getContentByPage(PAGE);
                    setItemSelected(0);
                    return [2 /*return*/];
            }
        });
    }); };
    var onConfirmChangeModal = function () {
        contentByPage;
        var _itemSelect = [activities[itemSelected - 1].id];
        deleteContentItems(_itemSelect);
        setShowModal(false);
        setItemSelected(0);
    };
    var onCloseModal = function () { return setShowModal(false); };
    var renderModal = showModal && (React.createElement(ConfirmModal, { showModal: showModal, confirmButtonText: "Eliminar", text: "\u00BFEst\u00E1 seguro que desea  eliminar la actividad " + items[itemSelected].title + "?", onClose: onCloseModal, onConfirm: onConfirmChangeModal }));
    var renderGeneralSection = showGeneralSection && (React.createElement(React.Fragment, null,
        React.createElement(EditInformationImage, { title: "General" }),
        React.createElement(ActionButtons, { handleSubmit: handleSubmit(onSubmit) })));
    var renderGallerySection = showForm && (React.createElement(React.Fragment, null, items.length > 0 && (React.createElement(RightColumn, null,
        React.createElement(TitleImage, null),
        React.createElement(ActionButtons, { handleSubmit: handleSubmit(onSubmit), handleDelete: function () { return setShowModal(true); } })))));
    return (React.createElement(MainColumn, null,
        renderModal,
        React.createElement(Title, null, "Actividades"),
        React.createElement(FormProvider, __assign({}, methods),
            React.createElement(TwoColumnsLayout, null,
                React.createElement(LeftColumn, null,
                    React.createElement(ListItem, { optionSelected: itemSelected, onAddItem: onAddItem, onClickItem: setItemSelected, items: items, textForNewItem: "Agregar actividad" })),
                renderGeneralSection,
                renderGallerySection))));
};
export { Activities };
export default Activities;
