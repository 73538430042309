var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef } from "react";
import { Label } from "components";
import { TextareaContainer, Textarea, Error } from "./styled";
var Component = forwardRef(function (props, ref) {
    var label = props.label, onClick = props.onClick, placeholder = props.placeholder, _a = props.disabled, disabled = _a === void 0 ? false : _a, _b = props.readOnly, readOnly = _b === void 0 ? false : _b, _c = props.type, type = _c === void 0 ? "text" : _c, error = props.error, name = props.name, value = props.value, rest = __rest(props, ["label", "onClick", "placeholder", "disabled", "readOnly", "type", "error", "name", "value"]);
    return (React.createElement(TextareaContainer, null,
        React.createElement(Label, null, label),
        React.createElement(Textarea, __assign({ ref: ref, value: value, name: name, placeholder: placeholder, disabled: disabled, readOnly: readOnly }, rest)),
        error && React.createElement(Error, null, error)));
});
export { Component as Textarea };
export default Component;
