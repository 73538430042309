var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
var IncrementDecrement = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: inline-flex;\n  width: 100%;\n  height: fit-content;\n  justify-content: flex-end;\n"], ["\n  display: inline-flex;\n  width: 100%;\n  height: fit-content;\n  justify-content: flex-end;\n"])));
IncrementDecrement.Icon = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 32px;\n  height: 32px;\n  border: ", ";\n  border-radius: 100%;\n  cursor: ", ";\n\n  > svg {\n    margin: 8px 9px;\n    color: ", ";\n  }\n"], ["\n  width: 32px;\n  height: 32px;\n  border: ", ";\n  border-radius: 100%;\n  cursor: ", ";\n\n  > svg {\n    margin: 8px 9px;\n    color: ", ";\n  }\n"])), function (props) {
    return props.isDisable ? "1px solid #CED4DA" : "1px solid #ADB5BD";
}, function (props) { return (props.isDisable ? "initial" : "pointer"); }, function (props) { return (props.isDisable ? " #CED4DA" : ""); });
IncrementDecrement.Value = styled.p(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-family: \"Roboto\";\n  font-size: 16px;\n  margin: 8px 16px;\n  justify-content: center;\n"], ["\n  font-family: \"Roboto\";\n  font-size: 16px;\n  margin: 8px 16px;\n  justify-content: center;\n"])));
export default IncrementDecrement;
var templateObject_1, templateObject_2, templateObject_3;
