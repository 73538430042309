import React, { useEffect } from "react";
import { useStoreActions, useStoreState } from "state/hooks";
import { navigate } from "@reach/router";
import { Login, PanelHeader } from "components";
import SignIn from "./styled";
var Signin = function (_a) {
    var children = _a.children;
    var _b = useStoreActions(function (actions) { return actions; }).auth, signin = _b.signin, cleanError = _b.cleanError;
    var signIn = function (data) {
        signin(data);
    };
    var _c = useStoreState(function (store) { return store; }).auth, isAuthenticated = _c.isAuthenticated, isLoading = _c.isLoading, error = _c.error;
    useEffect(function () {
        if (isAuthenticated)
            navigate("/admin");
    }, [isAuthenticated]);
    return (React.createElement(React.Fragment, null,
        React.createElement(PanelHeader, null),
        React.createElement(SignIn, null,
            React.createElement(Login, { signIn: signIn, isLoading: isLoading, error: error, cleanError: cleanError }))));
};
export { Signin };
export default Signin;
