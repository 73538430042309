import React, { useContext, useEffect } from "react";
import { Map, PageInfo } from "components";
import { Content } from "components/Layout";
import Context from "app/Context";
import { useStoreActions, useStoreState } from "state/hooks";
var PAGE = "colocation";
var Ubication = function (_a) {
    var children = _a.children;
    var language = useContext(Context).language;
    var isLanguageEnglish = language === "english";
    var _b = useStoreState(function (store) { return store; }), contentByPage = _b.content.contentByPage, _c = _b.config, config = _c.config, isLoading = _c.isLoading;
    var _d = useStoreActions(function (actions) { return actions; }), getContentByPage = _d.content.getContentByPage, getConfig = _d.config.getConfig;
    useEffect(function () {
        getContentByPage(PAGE);
        getConfig({ config: "contact" });
    }, []);
    var position = config && JSON.parse(config.value);
    var pageInfoToRender = contentByPage
        .filter(function (content) { return content.type === "general"; })
        .map(function (content) {
        var title = content.title, description = content.description, englishDescription = content.englishDescription, englishTitle = content.englishTitle;
        var data = {
            title: isLanguageEnglish ? englishTitle : title,
            description: isLanguageEnglish ? englishDescription : description,
        };
        return React.createElement(PageInfo, { data: data });
    });
    return (React.createElement(Content, null,
        React.createElement(Map, { positionSaved: position }),
        pageInfoToRender));
};
export { Ubication };
export default Ubication;
