var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from "react";
import NumberFormat from "react-number-format";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { CustomInput, Segment, Title } from "components";
import { GeneralEditor, InputName, InputDesciption, Section, InputContainer, } from "./styled";
import { faDollarSign, faPercent } from "@fortawesome/free-solid-svg-icons";
import { Column, Row } from "components/Forms/Rules/Prices/styled";
var Component = function () {
    var _a, _b;
    var _c = useFormContext(), register = _c.register, control = _c.control, errors = _c.formState.errors;
    var fields = useFieldArray({
        control: control,
        name: "ruleStayDiscount",
    }).fields;
    var renderStayDiscounts = fields.map(function (ruleStayDiscountItem, index) {
        var nightsStay = ruleStayDiscountItem.nightsStay;
        return (React.createElement(Column, { key: "nightsToStay-" + index },
            React.createElement(Controller, { control: control, name: "ruleStayDiscount." + index + ".discount", render: function (_a) {
                    var _b = _a.field, value = _b.value, onChange = _b.onChange;
                    return (React.createElement(NumberFormat, { thousandSeparator: true, customInput: CustomInput, icon: faPercent, label: "Por " + nightsStay + " noches", value: value, onValueChange: function (_a) {
                            var floatValue = _a.floatValue;
                            onChange(floatValue);
                        } }));
                } })));
    });
    return (React.createElement(Segment, null,
        React.createElement(GeneralEditor, null,
            React.createElement(Title, { variant: "segment" }, "Generales"),
            React.createElement(Section, null,
                React.createElement(InputName, null,
                    React.createElement(CustomInput, __assign({ label: "Nombre" }, register("name"), { error: (_a = errors.name) === null || _a === void 0 ? void 0 : _a.message })))),
            React.createElement(Section, null,
                React.createElement(InputDesciption, null,
                    React.createElement(CustomInput, __assign({ label: "Descripción" }, register("description"), { error: (_b = errors.description) === null || _b === void 0 ? void 0 : _b.message })))),
            React.createElement(Title, { variant: "subtitle" }, "PRECIOS"),
            React.createElement(Section, null,
                React.createElement(InputContainer, null,
                    React.createElement(Controller, { control: control, name: "baseRule.basePrice", render: function (_a) {
                            var _b, _c;
                            var _d = _a.field, value = _d.value, onChange = _d.onChange;
                            return (React.createElement(NumberFormat, { thousandSeparator: true, customInput: CustomInput, icon: faDollarSign, iconPosition: "before", label: "Precio por noche", helpText: "(domingo-jueves)", value: value, onValueChange: function (_a) {
                                    var floatValue = _a.floatValue;
                                    onChange(floatValue);
                                }, error: (_c = (_b = errors.baseRule) === null || _b === void 0 ? void 0 : _b.basePrice) === null || _c === void 0 ? void 0 : _c.message }));
                        } })),
                React.createElement(InputContainer, null,
                    React.createElement(Controller, { control: control, name: "baseRule.weekendPrice", render: function (_a) {
                            var _b, _c;
                            var _d = _a.field, value = _d.value, onChange = _d.onChange;
                            return (React.createElement(NumberFormat, { thousandSeparator: true, customInput: CustomInput, icon: faDollarSign, iconPosition: "before", label: "Precio fin de semana", helpText: "(VIE-SAB)", value: value, onValueChange: function (_a) {
                                    var floatValue = _a.floatValue;
                                    onChange(floatValue);
                                }, error: (_c = (_b = errors.baseRule) === null || _b === void 0 ? void 0 : _b.weekendPrice) === null || _c === void 0 ? void 0 : _c.message }));
                        } })),
                React.createElement(InputContainer, null,
                    React.createElement(Controller, { control: control, name: "baseRule.minimumStay", render: function (_a) {
                            var _b, _c;
                            var _d = _a.field, value = _d.value, onChange = _d.onChange;
                            return (React.createElement(NumberFormat, { thousandSeparator: true, customInput: CustomInput, label: "M\u00EDnimo de noches para reservar", suffix: " noche(s)", decimalScale: 0, allowNegative: false, value: value, onValueChange: function (_a) {
                                    var floatValue = _a.floatValue;
                                    onChange(floatValue);
                                }, error: (_c = (_b = errors.baseRule) === null || _b === void 0 ? void 0 : _b.minimumStay) === null || _c === void 0 ? void 0 : _c.message }));
                        } }))),
            React.createElement(Section, null,
                React.createElement(InputContainer, null,
                    React.createElement(Controller, { control: control, name: "baseRule.capacity", render: function (_a) {
                            var _b, _c;
                            var _d = _a.field, value = _d.value, onChange = _d.onChange;
                            return (React.createElement(NumberFormat, { thousandSeparator: true, customInput: CustomInput, label: "M\u00E1ximo de personas", suffix: " hu\u00E9spedes", decimalScale: 0, allowNegative: false, value: value, onValueChange: function (_a) {
                                    var floatValue = _a.floatValue;
                                    onChange(floatValue);
                                }, error: (_c = (_b = errors.baseRule) === null || _b === void 0 ? void 0 : _b.capacity) === null || _c === void 0 ? void 0 : _c.message }));
                        } })),
                React.createElement(InputContainer, null,
                    React.createElement(Controller, { control: control, name: "baseRule.childrenAllowed", render: function (_a) {
                            var _b, _c;
                            var _d = _a.field, value = _d.value, onChange = _d.onChange;
                            return (React.createElement(NumberFormat, { thousandSeparator: true, customInput: CustomInput, label: "M\u00E1ximo de infantes", suffix: " infantes", decimalScale: 0, allowNegative: false, value: value, onValueChange: function (_a) {
                                    var floatValue = _a.floatValue;
                                    onChange(floatValue);
                                }, error: (_c = (_b = errors.baseRule) === null || _b === void 0 ? void 0 : _b.childrenAllowed) === null || _c === void 0 ? void 0 : _c.message }));
                        } })),
                React.createElement(InputContainer, null,
                    React.createElement(Controller, { control: control, name: "baseRule.extraGuestPrice", render: function (_a) {
                            var _b = _a.field, value = _b.value, onChange = _b.onChange;
                            return (React.createElement(NumberFormat, { thousandSeparator: true, customInput: CustomInput, label: "Cargo por h\u00FAesped extra", icon: faDollarSign, iconPosition: "before", value: value, onValueChange: function (_a) {
                                    var floatValue = _a.floatValue;
                                    onChange(floatValue);
                                } }));
                        } }))),
            React.createElement(Section, null,
                React.createElement(InputContainer, null,
                    React.createElement(Controller, { control: control, name: "baseRule.extraGuestApply", render: function (_a) {
                            var _b = _a.field, value = _b.value, onChange = _b.onChange;
                            return (React.createElement(NumberFormat, { thousandSeparator: true, customInput: CustomInput, label: "A partir de cu\u00E1ntos hu\u00E9spedes aplica", suffix: " hu\u00E9spedes", decimalScale: 0, allowNegative: false, max: "50", value: value, onValueChange: function (_a) {
                                    var floatValue = _a.floatValue;
                                    onChange(floatValue);
                                } }));
                        } })),
                React.createElement(InputContainer, null,
                    React.createElement(Controller, { control: control, name: "baseRule.cleaningCharge", render: function (_a) {
                            var _b, _c;
                            var _d = _a.field, value = _d.value, onChange = _d.onChange;
                            return (React.createElement(NumberFormat, { thousandSeparator: true, customInput: CustomInput, icon: faDollarSign, iconPosition: "before", label: "Precio por limpieza", value: value, onValueChange: function (_a) {
                                    var floatValue = _a.floatValue;
                                    onChange(floatValue);
                                }, error: (_c = (_b = errors.baseRule) === null || _b === void 0 ? void 0 : _b.cleaningCharge) === null || _c === void 0 ? void 0 : _c.message }));
                        } }))),
            React.createElement(Title, { variant: "subtitle" }, "DESCUENTOS"),
            React.createElement(Row, null,
                renderStayDiscounts,
                React.createElement(Controller, { control: control, name: "baseRule.lastMinuteRentalDisscount", render: function (_a) {
                        var _b = _a.field, value = _b.value, onChange = _b.onChange;
                        value = value !== null && value !== void 0 ? value : NaN;
                        return (React.createElement(NumberFormat, { thousandSeparator: true, customInput: CustomInput, icon: faPercent, label: "Renta de \u00FAltima hora", helpText: "(24 hrs. antes)", value: value, onValueChange: function (_a) {
                                var floatValue = _a.floatValue;
                                onChange(floatValue);
                            } }));
                    } })))));
};
export default Component;
export { Component as GeneralEditor };
