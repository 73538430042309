import React from "react";
import { Redirect } from "@reach/router";
import { useStoreState } from "state/hooks";
export default (function (props) {
    var Component = props.component;
    var isAuthenticated = useStoreState(function (store) { return store.auth; }).isAuthenticated;
    if (!isAuthenticated) {
        return React.createElement(Redirect, { to: "/admin/iniciar-sesion", noThrow: true });
    }
    return React.createElement(Component, null);
});
