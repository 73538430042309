import React from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Feature from "./styled";
export var Features = function (props) {
    var features = props.features, isEditing = props.isEditing, onDelete = props.onDelete;
    var featuresToRender = features &&
        features.split(",").map(function (feature, index) {
            return (React.createElement(Feature, { key: "feature-" + index },
                React.createElement(Feature.Value, null, feature),
                isEditing && (React.createElement(Feature.ClearButton, { onClick: function () { return onDelete && onDelete(feature); } },
                    React.createElement(FontAwesomeIcon, { icon: faTimes })))));
        });
    return React.createElement(Feature.Container, null, featuresToRender);
};
export default Features;
