var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var DropdownContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  cursor: pointer;\n  position: relative;\n  height: fit-content;\n  max-height: ", ";\n  transition: max-height 1s ease-out;\n"], ["\n  cursor: pointer;\n  position: relative;\n  height: fit-content;\n  max-height: ", ";\n  transition: max-height 1s ease-out;\n"])), function (props) { return props.isOpen ? '500px' : '60px'; });
export var Header = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: grid;\n  padding: 16px;\n  font-size: 20px;\n  font-family: \"Roboto\";\n  grid-template:\n    \"headerContent icon\" 100%/\n    95% 5%;\n  align-items: center;\n"], ["\n  display: grid;\n  padding: 16px;\n  font-size: 20px;\n  font-family: \"Roboto\";\n  grid-template:\n    \"headerContent icon\" 100%/\n    95% 5%;\n  align-items: center;\n"])));
export var HeaderContent = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  gap: 10px;\n  grid-area: headerContent;\n  font-size: 20px;\n"], ["\n  display: flex;\n  gap: 10px;\n  grid-area: headerContent;\n  font-size: 20px;\n"])));
export var Icon = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  align-self: center;\n  font-size: 20px;\n  transform: rotate(", ");\n  transition: transform .25s ease ;\n"], ["\n  align-self: center;\n  font-size: 20px;\n  transform: rotate(", ");\n  transition: transform .25s ease ;\n"])), function (props) { return props.isOpen ? '180deg' : 0; });
export var DropdownListContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  height: auto;\n  top: 70%;\n  width: 100%;\n  z-index: 2;\n"], ["\n  height: auto;\n  top: 70%;\n  width: 100%;\n  z-index: 2;\n"])));
export var DropdownList = styled.ul(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  height: 100%;\n  padding-left: 0;\n  position: relative;\n  overflow-y: auto;\n  margin: 5px 0 0 ;\n"], ["\n  height: 100%;\n  padding-left: 0;\n  position: relative;\n  overflow-y: auto;\n  margin: 5px 0 0 ;\n"])));
export var ListItem = styled.li(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  height: 1.5rem;\n  list-style: none;\n  margin: unset;\n  padding: 0 1em;\n  font-size: 14px;\n  font-family: \"Roboto\";\n  &:hover {\n    background-color: ", ";\n  }\n"], ["\n  height: 1.5rem;\n  list-style: none;\n  margin: unset;\n  padding: 0 1em;\n  font-size: 14px;\n  font-family: \"Roboto\";\n  &:hover {\n    background-color: ", ";\n  }\n"])), function (props) { return props.theme.colors.grayf8f; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
