var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useContext, useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Features, Segment, Title } from "components";
import { useStoreActions, useStoreState } from "state/hooks";
import { FeatureContainer, SuggestionContainer, Input, Suggestions, Suggestion, } from "./styled";
import Context from "app/Context";
var Component = function () {
    var _a = __read(useState(""), 2), newFeature = _a[0], setNewFeature = _a[1];
    var _b = __read(useState([]), 2), suggestions = _b[0], setSuggestions = _b[1];
    var suggestionInput = useRef(null);
    var language = useContext(Context).language;
    var _c = useFormContext(), watch = _c.watch, setValue = _c.setValue;
    var features = watch("features");
    var getSuggestedFeatures = useStoreActions(function (actions) { return actions.feature; }).getSuggestedFeatures;
    var feature = useStoreState(function (store) { return store.feature; }).feature;
    var renderSuggestionFeatures = function () {
        var suggestionsToShow = feature === null || feature === void 0 ? void 0 : feature.filter(function (suggestion) {
            return !(features === null || features === void 0 ? void 0 : features.includes(suggestion));
        });
        suggestionsToShow && setSuggestions(suggestionsToShow);
    };
    useEffect(function () {
        if ((newFeature === null || newFeature === void 0 ? void 0 : newFeature.length) >= 2) {
            var params = {
                text: newFeature,
                language: language,
            };
            getSuggestedFeatures(params);
            renderSuggestionFeatures();
        }
    }, [newFeature]);
    var renderSuggestions = suggestions &&
        (suggestions === null || suggestions === void 0 ? void 0 : suggestions.map(function (suggestion, index) {
            return (React.createElement(Suggestion, { key: "tag-" + index, onClick: function () { return onClickSuggestionTag(suggestion); } }, suggestion));
        }));
    var onChangeSuggestionInput = function (event) {
        var value = event.target.value;
        setNewFeature(value);
    };
    var onPressSuggestionInput = function (event) {
        if (event.key === "Enter") {
            if (!newFeature)
                return;
            var newFeatures = newFeature
                .split(",")
                .filter(function (item) { return item.trim() !== "" && !(features === null || features === void 0 ? void 0 : features.includes(item)); });
            var previousFeatures = features === null || features === void 0 ? void 0 : features.split(",");
            var featuresToAdd = __spreadArray(__spreadArray([], __read((previousFeatures !== null && previousFeatures !== void 0 ? previousFeatures : [])), false), __read(newFeatures), false).filter(Boolean)
                .join(",");
            setValue("features", "" + featuresToAdd);
            cleanSuggestion();
        }
    };
    var onClickSuggestionTag = function (suggestion) {
        setValue("features", features ? features + ", " + suggestion : "" + suggestion);
        cleanSuggestion();
    };
    var onDelete = function (feature) {
        var featuresArray = features === null || features === void 0 ? void 0 : features.split(",");
        var newFeatures = featuresArray
            .filter(function (oldFeature) { return oldFeature !== feature; })
            .join(",");
        setValue("features", newFeatures);
    };
    var cleanSuggestion = function () {
        var _a;
        setNewFeature("");
        setSuggestions([]);
        (_a = suggestionInput === null || suggestionInput === void 0 ? void 0 : suggestionInput.current) === null || _a === void 0 ? void 0 : _a.focus();
    };
    return (React.createElement(Segment, null,
        React.createElement(Title, { variant: "segment" }, "Caracter\u00EDsticas"),
        React.createElement(FeatureContainer, null,
            React.createElement(SuggestionContainer, null,
                React.createElement(Input, { placeholder: "+ Agregar caracter\u00EDstica", value: newFeature, ref: suggestionInput, onChange: onChangeSuggestionInput, onKeyPress: onPressSuggestionInput }),
                React.createElement(Suggestions, null, renderSuggestions)),
            React.createElement(Features, { features: features, onDelete: onDelete, isEditing: true }))));
};
export { Component as FeaturesForm };
export default Component;
