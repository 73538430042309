var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { isEmpty } from "lodash";
import { statusColor, statusText } from "data";
import Table from "components/Table";
import { formateDate } from "utils/dates";
import React, { useContext, useEffect, useState } from "react";
import { formatPrice } from "utils/bungalowFactory";
import { useStoreActions, useStoreState } from "state/hooks";
import { MainColumn, Badge, GroupText } from "components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ConfirmModal, Segment, TextButton, Title } from "components";
import { faBan, faDollarSign, faInfoCircle, } from "@fortawesome/free-solid-svg-icons";
import Context from "app/Context";
var Reservations = function (_a) {
    var _b;
    var children = _a.children;
    var isEnglish = useContext(Context).isEnglish;
    var _c = __read(useState(1), 2), page = _c[0], setPage = _c[1];
    var _d = __read(useState(false), 2), showModal = _d[0], setShowModal = _d[1];
    var _e = __read(useState(null), 2), reservation = _e[0], setReservation = _e[1];
    var _f = __read(useState(null), 2), action = _f[0], setAction = _f[1];
    var reservations = useStoreState(function (state) { return state.reservation.paginatedReservations; });
    var _g = useStoreActions(function (actions) { return actions; }).reservation, getPaginatedReservations = _g.getPaginatedReservations, updateReservationStatus = _g.updateReservationStatus;
    useEffect(function () {
        if (isEmpty(reservations === null || reservations === void 0 ? void 0 : reservations.reservations))
            loadReservations(1);
    }, []);
    var loadReservations = function (pageToLoad) { return __awaiter(void 0, void 0, void 0, function () {
        var params;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    params = {
                        pageInfo: {
                            page: Number(pageToLoad),
                        },
                        data: {
                            clientName: "",
                            reference: "",
                            statusId: null,
                        },
                    };
                    return [4 /*yield*/, getPaginatedReservations(params)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var changeStatus = function (reservation, action) {
        setShowModal(true);
        setReservation(reservation);
        setAction(action);
    };
    var onCloseConfirmationModal = function () {
        setShowModal(false);
        setAction(null);
    };
    var onConfirmChangeStatus = function () { return __awaiter(void 0, void 0, void 0, function () {
        var success;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, updateReservationStatus({
                        data: {
                            id: reservation === null || reservation === void 0 ? void 0 : reservation.id,
                            statusId: action === "cancelled" ? 3 : 2,
                        },
                    })];
                case 1:
                    success = _a.sent();
                    if (!success) return [3 /*break*/, 3];
                    setShowModal(false);
                    setAction(null);
                    setReservation(null);
                    return [4 /*yield*/, loadReservations(page)];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var renderModal = showModal && (React.createElement(ConfirmModal, { showModal: showModal, confirmButtonText: "Si, " + (action === "cancelled" ? "CANCELAR" : "CONFIRMAR"), text: "\u00BFEst\u00E1 seguro que desea " + (action === "cancelled" ? "CANCELAR" : "CONFIRMAR") + " la reservaci\u00F3n con referencia " + (reservation === null || reservation === void 0 ? void 0 : reservation.paymentReference), onClose: onCloseConfirmationModal, onConfirm: onConfirmChangeStatus }));
    var columns = [
        {
            Header: "Referencias",
            accessor: "paymentReference",
        },
        {
            Header: "Estatus",
            Cell: function (props) {
                var _a = props.row.original.ReservationStatus, id = _a.id, status = _a.status;
                var value = statusText.find(function (object) { return object.en === status; });
                var text = value === null || value === void 0 ? void 0 : value.es;
                return React.createElement(Badge, { color: statusColor[id] }, text);
            },
        },
        {
            Header: "Contacto",
            accessor: "clientPhone",
            Cell: function (props) {
                var _a = props.row.original, clientName = _a.clientName, clientPhone = _a.clientPhone;
                return (React.createElement(React.Fragment, null,
                    React.createElement(GroupText, null,
                        React.createElement("span", null, "Cliente:"),
                        React.createElement("span", null, clientName)),
                    React.createElement(GroupText, null,
                        React.createElement("span", null, "Tel\u00E9fono:"),
                        React.createElement("span", null, clientPhone))));
            },
        },
        {
            Header: "Fechas",
            Cell: function (props) {
                var _a = props.row.original, deadline = _a.deadline, arrivalDate = _a.arrivalDate, departureDate = _a.departureDate;
                return (React.createElement(React.Fragment, null,
                    React.createElement(GroupText, null,
                        React.createElement("span", null, "L\u00EDmite pago:"),
                        React.createElement("span", null, formateDate(new Date(deadline), isEnglish))),
                    React.createElement(GroupText, null,
                        React.createElement("span", null, "F. llegada:"),
                        React.createElement("span", null, formateDate(new Date(arrivalDate), isEnglish))),
                    React.createElement(GroupText, null,
                        React.createElement("span", null, "F. partida:"),
                        React.createElement("span", null, formateDate(new Date(departureDate), isEnglish)))));
            },
        },
        {
            Header: "Monto a pagar",
            disableFilters: true,
            disableSortBy: true,
            Cell: function (props) {
                var original = props.row.original;
                return React.createElement("span", null, formatPrice(original.total, "es"));
            },
        },
        {
            Header: "Acciones",
            Cell: function (props) {
                var original = props.row.original;
                var id = original.id, _a = original.ReservationStatus, statusId = _a.id, status = _a.status;
                return (React.createElement(GroupText, null,
                    React.createElement(TextButton, { onClick: function () {
                            window.open("/reservation/" + id, "_blank");
                        } },
                        React.createElement(FontAwesomeIcon, { icon: faInfoCircle }),
                        "Detalle"),
                    statusId !== 3 && (React.createElement(TextButton, { mode: "danger", onClick: function () { return changeStatus(original, "cancelled"); } },
                        React.createElement(FontAwesomeIcon, { icon: faBan }),
                        "Cancelar")),
                    statusId !== 2 && (React.createElement(TextButton, { mode: "success", onClick: function () { return changeStatus(original, "paid"); } },
                        React.createElement(FontAwesomeIcon, { icon: faDollarSign }),
                        "Pagado"))));
            },
        },
    ];
    var renderTable = !isEmpty(reservations === null || reservations === void 0 ? void 0 : reservations.reservations) && (React.createElement(Table, { columns: columns, data: (_b = reservations === null || reservations === void 0 ? void 0 : reservations.reservations) !== null && _b !== void 0 ? _b : [], pagination: __assign(__assign({}, reservations.pagination), { onChangePage: function (pageNumber) {
                if (pageNumber) {
                    setPage(pageNumber);
                    loadReservations(pageNumber);
                }
            } }) }));
    return (React.createElement(MainColumn, null,
        renderModal,
        React.createElement(Title, null, "Reservaciones"),
        React.createElement(Segment, null, renderTable)));
};
export { Reservations };
export default Reservations;
