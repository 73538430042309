import React from "react";
import { BungalowItem, ReservationDetails } from "components";
import { BungalowsContainer, MainContainer, ProposalContainer, DetailsContainer, Title, } from "./styled";
var Component = function (props) {
    var data = props.data, option = props.option, search = props.search, onSubmitReservation = props.onSubmitReservation;
    var bungalows = data.bungalows;
    var renderBungalows = bungalows &&
        bungalows.map(function (bungalow) {
            return (React.createElement(BungalowItem, { key: "bungalow-proposal-" + option + "-" + bungalow.id, showPrice: false, dataBungalow: bungalow }));
        });
    return (React.createElement(MainContainer, null,
        React.createElement(ProposalContainer, null,
            React.createElement(BungalowsContainer, null,
                React.createElement(Title, null,
                    "Opci\u00F3n ",
                    option),
                renderBungalows),
            React.createElement(DetailsContainer, null,
                React.createElement(ReservationDetails, { search: search, data: data, buttonName: "Reservar estancia", option: option, onSubmitReservation: onSubmitReservation })))));
};
export { Component as Proposal };
export default Component;
