var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
var ResumeContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-bottom: 0.5px solid ", ";\n  display: flex;\n  flex-direction: column;\n  padding: 20px 0;\n"], ["\n  border-bottom: 0.5px solid ", ";\n  display: flex;\n  flex-direction: column;\n  padding: 20px 0;\n"])), function (props) { return props.theme.colors.grayced4; });
var ResumeSearch = styled.label(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 18px;\n  font-weight: 400;\n"], ["\n  color: ", ";\n  font-size: 18px;\n  font-weight: 400;\n"])), function (props) { return props.theme.colors.black212; });
var TotalOptions = styled.label(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 18px;\n  font-weight: 500;\n"], ["\n  color: ", ";\n  font-size: 18px;\n  font-weight: 500;\n"])), function (props) { return props.theme.colors.gray495; });
export { ResumeContainer, ResumeSearch, TotalOptions };
var templateObject_1, templateObject_2, templateObject_3;
