import { theme } from "../style/theme";
export var links = [
    {
        name: "Inicio",
        englishName: "Home",
        path: "/",
    },
    {
        name: "Bungalows",
        englishName: "Bungalows",
        path: "/bungalows",
    },
    {
        name: "Terraza y alberca",
        englishName: "Terrace & pool",
        path: "/terraza-y-alberca",
    },
    {
        name: "Club de playa",
        englishName: "Beach club",
        path: "/club-de-playa",
    },
    {
        name: "Actividades",
        englishName: "Activities",
        path: "/actividades",
    },
    {
        name: "Ubicación",
        englishName: "Location",
        path: "/ubicacion",
    },
];
export var statusColor = [
    theme.colors.gray,
    theme.colors.gray,
    theme.colors.primary,
    theme.colors.red9E0,
];
export var statusText = [
    {
        en: "CANCELLED",
        es: "Cancelada",
    },
    {
        en: "HOLDED",
        es: "Pendiente",
    },
    {
        en: "PAID",
        es: "Pagada",
    },
];
