import React from "react";
import { Segment, SelectImage, SelectImages, Title } from "components";
import { Image, SingleImageContainer } from "./styled";
var Component = function (props) {
    var _a = props.title, title = _a === void 0 ? "Banners" : _a, _b = props.singleImage, singleImage = _b === void 0 ? false : _b;
    var renderImageSelector = singleImage ? (React.createElement(SingleImageContainer, null,
        React.createElement(SelectImage, { fieldName: "item[0].picture" }))) : (React.createElement(SelectImages, { size: "default", fieldName: "items" }));
    return (React.createElement(Segment, null,
        React.createElement(Title, { variant: "normal" }, title),
        React.createElement(Image, null, renderImageSelector)));
};
export default Component;
export { Component as Banners };
