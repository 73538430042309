var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import { ContainerShadow } from "style/common";
var Login = ContainerShadow(styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-items: center;\n  background-color: ", ";\n  border-radius: 10px;\n  display: flex;\n  flex-direction: column;\n  padding: 15px 32px 25px;\n  width: 350px;\n  z-index: 2;\n"], ["\n  align-items: center;\n  background-color: ", ";\n  border-radius: 10px;\n  display: flex;\n  flex-direction: column;\n  padding: 15px 32px 25px;\n  width: 350px;\n  z-index: 2;\n"])), function (props) { var _a, _b; return (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.colors) === null || _b === void 0 ? void 0 : _b.whitefff; }));
Login.Form = styled.form(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
Login.Title = styled.h1(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-bottom: 10px;\n  font-family: Roboto;\n  font-size: 24px;\n  font-style: normal;\n  text-align: center;\n  font-weight: bold;\n"], ["\n  margin-bottom: 10px;\n  font-family: Roboto;\n  font-size: 24px;\n  font-style: normal;\n  text-align: center;\n  font-weight: bold;\n"])));
Login.InputContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-top: 31px;\n  width: 100%;\n"], ["\n  margin-top: 31px;\n  width: 100%;\n"])));
Login.ButtonContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-top: 31px;\n  text-align: right;\n  width: 100%;\n"], ["\n  margin-top: 31px;\n  text-align: right;\n  width: 100%;\n"])));
Login.Loading = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  justify-content: center;\n  margin: 10px 0;\n  width: 100%;\n"], ["\n  align-items: center;\n  display: flex;\n  justify-content: center;\n  margin: 10px 0;\n  width: 100%;\n"])));
export default Login;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
