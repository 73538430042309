import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleRight, faTrash, faRetweet, } from "@fortawesome/free-solid-svg-icons";
import UsersAdministration from "./styled";
var Component = function (props) {
    var data = props.data, onChangeStatus = props.onChangeStatus, onSelect = props.onSelect;
    var id = data.id, name = data.name, lastName = data.lastName, isActive = data.isActive;
    var action = isActive ? "Eliminar" : "Restaurar";
    var icon = isActive ? faTrash : faRetweet;
    return (React.createElement(UsersAdministration, null,
        React.createElement(UsersAdministration.Info, null,
            React.createElement(UsersAdministration.Name, null,
                name,
                " ",
                lastName),
            React.createElement(UsersAdministration.Icons, null,
                React.createElement(UsersAdministration.ActionIcon, { isActive: isActive, onClick: function () { return onChangeStatus(id); } },
                    React.createElement(FontAwesomeIcon, { icon: icon }),
                    " ",
                    action),
                React.createElement(UsersAdministration.PlusIcon, { onClick: function () { return onSelect(data); } },
                    React.createElement(FontAwesomeIcon, { icon: faChevronCircleRight }))))));
};
export default Component;
export { Component as UsersAdministration };
