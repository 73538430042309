import React, { useContext } from "react";
import { useStoreActions } from "state/hooks";
import { faHome, faBuilding, faRuler, faSwimmingPool, faUmbrellaBeach, faIdCard, faFish, faCalendar, faCreditCard, faUsers, faSignOutAlt, faTv, } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InitialsInMenu, BorderedButton } from "components";
import { AdminMenu, Logo, TopContainer, BottomContainer, UserNameContainer, UserInitials, UserName, LanguageButtonContainer, LinkItem, } from "./styled";
import Context from "app/Context";
var pageLinks = [
    {
        path: "/inicio",
        name: "Inicio",
        icon: faHome,
    },
    {
        path: "/bungalows",
        name: "Bungalows",
        icon: faBuilding,
    },
    {
        path: "/reglas",
        name: "Reglas",
        icon: faRuler,
    },
    {
        path: "/terraza-y-alberca",
        name: "Terraza y alberca",
        icon: faSwimmingPool,
    },
    {
        path: "/club-de-playa",
        name: "Club de Playa",
        icon: faUmbrellaBeach,
    },
    {
        path: "/actividades",
        name: "Actividades",
        icon: faFish,
    },
    {
        path: "/contacto",
        name: "Contacto",
        icon: faIdCard,
    },
    {
        path: "/amenidades",
        name: "Amenidades",
        icon: faTv,
    },
];
var configLinks = [
    {
        path: "/reservaciones",
        name: "Reservaciones",
        icon: faCalendar,
    },
    {
        path: "/configuracion",
        name: "Configuración",
        icon: faCreditCard,
    },
    {
        path: "/usuarios",
        name: "Usuarios",
        icon: faUsers,
    },
    {
        path: "/cerrar-sesion",
        name: "Cerrar sesion",
        icon: faSignOutAlt,
        isSignOut: true,
    },
];
var Component = function (props) {
    var name = props.name, parentPath = props.parentPath;
    var closeSession = useStoreActions(function (actions) { return actions; }).auth.closeSession;
    var signOut = function () {
        closeSession();
    };
    var _a = useContext(Context), language = _a.language, setLanguage = _a.setLanguage;
    var handleClick = function () {
        if (language === "english") {
            setLanguage("spanish");
        }
        else {
            setLanguage("english");
        }
    };
    var title = language === "english" ? "English" : "Español";
    var renderPageLinks = pageLinks.map(function (_a, index) {
        var path = _a.path, name = _a.name, icon = _a.icon;
        return (React.createElement(LinkItem, { key: "page-link-" + index, to: "" + parentPath + path },
            React.createElement(FontAwesomeIcon, { icon: icon }),
            " ",
            name));
    });
    var renderConfigLinks = configLinks.map(function (_a, index) {
        var path = _a.path, name = _a.name, icon = _a.icon, isSignOut = _a.isSignOut;
        return (React.createElement(LinkItem, { key: "config-link-" + index, to: "" + parentPath + path, "$isActive": false, "$isSignOut": isSignOut, onClick: isSignOut ? signOut : "" },
            React.createElement(FontAwesomeIcon, { icon: icon }),
            " ",
            name));
    });
    return (React.createElement(AdminMenu, null,
        React.createElement(TopContainer, null,
            React.createElement(Logo, null),
            React.createElement(UserNameContainer, { to: "/admin/perfil", "$isActive": false },
                React.createElement(UserInitials, null,
                    React.createElement(InitialsInMenu, { name: name, isInMenu: true })),
                React.createElement(UserName, null, name)),
            React.createElement(LanguageButtonContainer, null,
                React.createElement(BorderedButton, { children: title, onClick: handleClick })),
            renderPageLinks),
        React.createElement(BottomContainer, null, renderConfigLinks)));
};
export { Component as AdminMenu };
export default Component;
