import React from "react";
import { faAngleLeft, faAngleDoubleLeft, faAngleRight, faAngleDoubleRight, } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Pagination, Button, PageLabel } from "./styled";
var Component = function (props) {
    var _a = props.instance, pageIndex = _a.state.pageIndex, gotoPage = _a.gotoPage, nextPage = _a.nextPage, previousPage = _a.previousPage, canPreviousPage = _a.canPreviousPage, pageOptions = _a.pageOptions, canNextPage = _a.canNextPage, pageCount = _a.pageCount;
    return (React.createElement(Pagination, null,
        React.createElement(Button, { onClick: function () { return gotoPage(0); }, disabled: !canPreviousPage },
            React.createElement(FontAwesomeIcon, { icon: faAngleDoubleLeft })),
        React.createElement(Button, { onClick: previousPage, disabled: !canPreviousPage },
            React.createElement(FontAwesomeIcon, { icon: faAngleLeft })),
        React.createElement(PageLabel, null,
            "Pagina ",
            pageIndex + 1,
            " de ",
            pageOptions.length),
        React.createElement(Button, { onClick: nextPage, disabled: !canNextPage },
            React.createElement(FontAwesomeIcon, { icon: faAngleRight })),
        React.createElement(Button, { onClick: function () { return gotoPage(pageCount - 1); }, disabled: !canNextPage },
            React.createElement(FontAwesomeIcon, { icon: faAngleDoubleRight }))));
};
export { Component as Pagination };
export default Component;
