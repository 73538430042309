var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { action, thunk } from "easy-peasy";
import { requester, requesterWithMultipart } from "libs/requester";
import { getContentByPageQuery, createContentQuery, updateContentQuery, deleteContentQuery, } from "graphql/Content";
var defaultState = {
    CONTENT_BY_PAGE: [],
    CONTENT_ITEMS: [],
    IS_LOADING: false,
};
var contentModel = {
    contentByPage: defaultState.CONTENT_BY_PAGE,
    contentItems: defaultState.CONTENT_ITEMS,
    isLoading: defaultState.IS_LOADING,
    setLoading: action(function (state, loading) {
        state.isLoading = loading;
    }),
    setContentByPage: action(function (state, params) {
        state.contentByPage = params.contentItems;
        state.contentItems = __spreadArray(__spreadArray([], __read(state.contentItems), false), __read(params.contentItems), false);
    }),
    getContentByPage: thunk(function (actions, page) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    actions.setLoading(true);
                    return [4 /*yield*/, requester(getContentByPageQuery, { page: page })];
                case 1:
                    response = _a.sent();
                    actions.setLoading(false);
                    if (response === null || response === void 0 ? void 0 : response.success) {
                        actions.setContentByPage({
                            page: page,
                            contentItems: response.payload || [],
                        });
                    }
                    return [2 /*return*/];
            }
        });
    }); }),
    removeContentItems: action(function (state, contentItemsDeleted) {
        state.contentItems = state.contentItems.filter(function (contentItem) { return !contentItemsDeleted.includes(contentItem.id); });
        state.contentByPage = state.contentByPage.filter(function (contentItem) { return !contentItemsDeleted.includes(contentItem.id); });
    }),
    createContentItems: thunk(function (actions, contentItems) { return __awaiter(void 0, void 0, void 0, function () {
        var PAGE, isMultipart, _requester;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    actions.setLoading(true);
                    PAGE = contentItems[0].page;
                    isMultipart = false;
                    contentItems.forEach(function (contentItem) {
                        if (contentItem.picture && contentItem.picture[0] instanceof File) {
                            isMultipart = true;
                        }
                        else {
                            delete contentItem.picture;
                        }
                    });
                    _requester = isMultipart ? requesterWithMultipart : requester;
                    return [4 /*yield*/, _requester(createContentQuery, {
                            contentItems: contentItems,
                        })];
                case 1:
                    _a.sent();
                    actions.setLoading(false);
                    PAGE && actions.getContentByPage(PAGE);
                    return [2 /*return*/];
            }
        });
    }); }),
    updateContentItems: thunk(function (actions, contentItems) { return __awaiter(void 0, void 0, void 0, function () {
        var isMultipart, PAGE, _requester;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    isMultipart = false;
                    PAGE = contentItems[0].page;
                    contentItems.forEach(function (contentItem) {
                        delete contentItem.page;
                        delete contentItem.type;
                        delete contentItem.toDelete;
                        if (contentItem.picture && contentItem.picture[0] instanceof File) {
                            isMultipart = true;
                        }
                        else {
                            delete contentItem.picture;
                        }
                    });
                    _requester = isMultipart ? requesterWithMultipart : requester;
                    actions.setLoading(true);
                    return [4 /*yield*/, _requester(updateContentQuery, {
                            contentItems: contentItems,
                        })];
                case 1:
                    _a.sent();
                    actions.setLoading(false);
                    PAGE && actions.getContentByPage(PAGE);
                    return [2 /*return*/];
            }
        });
    }); }),
    deleteContentItems: thunk(function (actions, contentItemIds) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    actions.setLoading(true);
                    return [4 /*yield*/, requester(deleteContentQuery, {
                            contentItemIds: contentItemIds,
                        })];
                case 1:
                    response = _a.sent();
                    actions.setLoading(false);
                    if (response === null || response === void 0 ? void 0 : response.success) {
                        actions.removeContentItems(contentItemIds);
                    }
                    return [2 /*return*/];
            }
        });
    }); }),
};
export default contentModel;
