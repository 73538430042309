var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useContext, useEffect, useState } from "react";
import { useLayer } from "react-laag";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import { formateDate } from "utils/dates";
import { CustomInput } from "components";
import Navbar from "./components/Navbar";
import Caption from "./components/Caption";
import WeekDay from "./components/WeekDay";
import Day from "./components/Day";
import "./components/style.css";
import DateRange from "./styled";
import { useFormContext } from "react-hook-form";
import Context from "app/Context";
var Component = function (props) {
    var _a, _b;
    var isEnglish = useContext(Context).isEnglish;
    var _c = props.fromText, fromText = _c === void 0 ? "" + (isEnglish ? "Check in" : "Fecha de llegada") : _c, _d = props.toText, toText = _d === void 0 ? "" + (isEnglish ? "Check out" : "Fecha de salida") : _d, _e = props.fromValue, fromValue = _e === void 0 ? undefined : _e, _f = props.toValue, toValue = _f === void 0 ? undefined : _f, _g = props.isReadonly, isReadonly = _g === void 0 ? false : _g;
    var _h = __read(useState(false), 2), isOpen = _h[0], setIsOpen = _h[1];
    var _j = __read(useState({
        from: fromValue,
        to: toValue,
    }), 2), range = _j[0], setRange = _j[1];
    var errors = useFormContext().formState.errors;
    useEffect(function () {
        setRange({
            from: fromValue,
            to: toValue,
        });
    }, [fromValue, toValue]);
    var _k = useFormContext(), register = _k.register, setValue = _k.setValue;
    var close = function () {
        setIsOpen(false);
    };
    var _l = useLayer({
        isOpen: isOpen,
        onOutsideClick: close,
        overflowContainer: true,
        auto: true,
        placement: "bottom-center",
        possiblePlacements: ["top-center", "bottom-center"],
        triggerOffset: 20,
        containerOffset: 16,
        arrowOffset: 16,
    }), triggerProps = _l.triggerProps, layerProps = _l.layerProps, renderLayer = _l.renderLayer;
    var toggleCalendar = function () {
        if (isReadonly)
            return;
        setIsOpen(!isOpen);
    };
    var from = range.from, to = range.to;
    useEffect(function () {
        setValue("startDate", from);
        setValue("endDate", to);
    }, [from, to]);
    var handleClick = function (date) {
        var newRange = DateUtils.addDayToRange(date, range);
        setRange(newRange);
    };
    var modifiers = { start: from, end: to };
    var startDateSelected = from ? formateDate(from, isEnglish) : "";
    var endDateSelected = to ? formateDate(to, isEnglish) : "";
    var today = new Date();
    return (React.createElement(DateRange, null,
        React.createElement(DateRange.InputsContainer, __assign({}, triggerProps),
            React.createElement(CustomInput, __assign({ label: fromText, placeholder: isEnglish ? "Select date" : "Selecciona fecha", value: startDateSelected, readOnly: true, onClick: toggleCalendar, icon: faCalendarAlt }, register("startDate"), { error: (_a = errors.startDate) === null || _a === void 0 ? void 0 : _a.message })),
            React.createElement(CustomInput, __assign({ label: toText, placeholder: isEnglish ? "Select date" : "Selecciona fecha", value: endDateSelected, readOnly: true, onClick: toggleCalendar, icon: faCalendarAlt }, register("endDate"), { error: (_b = errors.endDate) === null || _b === void 0 ? void 0 : _b.message }))),
        isOpen &&
            renderLayer(React.createElement(DateRange.CalendarContainer, __assign({}, layerProps),
                React.createElement(DayPicker, { locale: isEnglish ? "en" : "es", className: "Selectable", onDayClick: function (day, _a) {
                        var disabled = _a.disabled;
                        if (disabled)
                            return;
                        handleClick(day);
                    }, captionElement: Caption, navbarElement: Navbar, weekdayElement: WeekDay, renderDay: Day, selectedDays: [from, { from: from, to: to }], modifiers: modifiers, disabledDays: { before: today } })))));
};
export { Component as DateRange };
export default Component;
