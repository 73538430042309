import React from "react";
import Profile from "./styled";
import { InitialsInProfile } from "components";
var Component = function (props) {
    var name = props.name, email = props.email, handleEditInfo = props.handleEditInfo, handleChangePassword = props.handleChangePassword;
    return (React.createElement(Profile, null,
        React.createElement(InitialsInProfile, { name: name }),
        React.createElement(Profile.DataContainer, null,
            React.createElement(Profile.Data, null,
                React.createElement(Profile.Name, null, name),
                React.createElement(Profile.Edit, { onClick: handleEditInfo }, "Editar datos")),
            React.createElement(Profile.Data, null,
                React.createElement(Profile.Email, null, email)),
            React.createElement(Profile.Data, null,
                React.createElement(Profile.Edit, { onClick: handleChangePassword }, "Cambiar contrase\u00F1a")))));
};
export { Component as Profile };
export default Component;
