import auth from "state/models/Auth";
import user from "state/models/User";
import config from "state/models/Config";
import rule from "state/models/Rule";
import amenity from "state/models/Amenity";
import bungalow from "state/models/Bungalow";
import feature from "state/models/Features";
import availability from "state/models/Availability";
import content from "state/models/Content";
import reservation from "state/models/Reservation";
var storeModel = {
    auth: auth,
    user: user,
    config: config,
    rule: rule,
    amenity: amenity,
    bungalow: bungalow,
    feature: feature,
    availability: availability,
    content: content,
    reservation: reservation,
};
export default storeModel;
