import React, { useEffect } from "react";
import useNotification from "hooks/useNotification";
import { faExclamationTriangle, faTimesCircle, faCheckCircle, faTimes, } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer, Toast, Button, ToastTitle, Content, Icon, } from "./styled";
var Component = function () {
    var _a = useNotification(), notification = _a.notification, removeNotification = _a.removeNotification;
    var handleSubmit = function () {
        removeNotification();
    };
    useEffect(function () {
        var interval = setInterval(function () {
            removeNotification();
        }, 4000);
        return function () {
            clearInterval(interval);
        };
    }, []);
    var icon = (notification === null || notification === void 0 ? void 0 : notification.type) == "success"
        ? faCheckCircle
        : (notification === null || notification === void 0 ? void 0 : notification.type) == "danger"
            ? faTimesCircle
            : faExclamationTriangle;
    return (React.createElement(React.Fragment, null, notification && (React.createElement(ToastContainer, null,
        React.createElement(Toast, { type: notification.type },
            React.createElement(Content, null,
                React.createElement(Icon, null,
                    React.createElement(FontAwesomeIcon, { icon: icon })),
                React.createElement(ToastTitle, null, notification.message)),
            React.createElement(Button, { onClick: handleSubmit },
                React.createElement(FontAwesomeIcon, { icon: faTimes })))))));
};
export default Component;
export { Component as Toast };
