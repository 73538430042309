var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
var MainContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-bottom: 1px solid ", ";\n  display: flex;\n  flex-direction: column;\n  margin-top: 45px;\n  padding-bottom: 26px;\n  width: 100%;\n"], ["\n  border-bottom: 1px solid ", ";\n  display: flex;\n  flex-direction: column;\n  margin-top: 45px;\n  padding-bottom: 26px;\n  width: 100%;\n"])), function (props) { return props.theme.colors.grayced4; });
var Title = styled.h3(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 24px;\n  font-weight: 500;\n  margin-bottom: 20px;\n"], ["\n  color: ", ";\n  font-size: 24px;\n  font-weight: 500;\n  margin-bottom: 20px;\n"])), function (props) { return props.theme.colors.black212; });
var ProposalContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: grid;\n  grid-template:\n    \"LeftColumn RightColumn\" 100%\n    / 65% 35%;\n"], ["\n  display: grid;\n  grid-template:\n    \"LeftColumn RightColumn\" 100%\n    / 65% 35%;\n"])));
var BungalowsContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  grid-area: LeftColumn;\n  position: sticky;\n  align-self: start;\n  top: 0;\n"], ["\n  grid-area: LeftColumn;\n  position: sticky;\n  align-self: start;\n  top: 0;\n"])));
var DetailsContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  grid-area: RightColumn;\n"], ["\n  grid-area: RightColumn;\n"])));
export { MainContainer, Title, ProposalContainer, BungalowsContainer, DetailsContainer, };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
