var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useContext } from "react";
import { useFormContext } from "react-hook-form";
import { CustomInput, Title, Segment, InputContainer } from "components";
import Context from "app/Context";
var Component = function () {
    var _a = useContext(Context), isEnglish = _a.isEnglish, isSpanish = _a.isSpanish;
    var register = useFormContext().register;
    return (React.createElement(Segment, null,
        React.createElement(Title, { variant: "segment" }, "Generales"),
        React.createElement(InputContainer, { variant: "fluid" },
            isEnglish && (React.createElement(CustomInput, __assign({ label: "Title" }, register("generals.englishTitle")))),
            isSpanish && (React.createElement(CustomInput, __assign({ label: "Título" }, register("generals.title"))))),
        React.createElement(InputContainer, { variant: "fluid" },
            isEnglish && (React.createElement(CustomInput, __assign({ label: "Description" }, register("generals.englishDescription")))),
            isSpanish && (React.createElement(CustomInput, __assign({ label: "Descripción" }, register("generals.description")))))));
};
export default Component;
export { Component as TitleDescription };
