var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
var AmenitiesForm = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  > div {\n    box-sizing: border-box;\n    min-height: 255px;\n    width: 100%;\n    max-width: 896px;\n  }\n"], ["\n  > div {\n    box-sizing: border-box;\n    min-height: 255px;\n    width: 100%;\n    max-width: 896px;\n  }\n"])));
AmenitiesForm.Title = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-family: Roboto;\n  font-size: 24px;\n  font-weight: 700;\n  padding-bottom: 25px;\n"], ["\n  font-family: Roboto;\n  font-size: 24px;\n  font-weight: 700;\n  padding-bottom: 25px;\n"])));
AmenitiesForm.Box = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n  > div {\n    margin: 30px 0;\n  }\n"], ["\n  width: 100%;\n  > div {\n    margin: 30px 0;\n  }\n"])));
AmenitiesForm.AddItem = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding: 0 15px;\n  font-family: Roboto;\n  color: ", ";\n  cursor: pointer;\n"], ["\n  padding: 0 15px;\n  font-family: Roboto;\n  color: ", ";\n  cursor: pointer;\n"])), function (props) { var _a, _b; return (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.colors) === null || _b === void 0 ? void 0 : _b.primary; });
export default AmenitiesForm;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
