var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { useFormContext } from "react-hook-form";
import { CustomInput, Segment, Title, InputContainer } from "components";
var Component = function () {
    var _a, _b, _c;
    var _d = useFormContext(), register = _d.register, errors = _d.formState.errors;
    return (React.createElement(Segment, null,
        React.createElement(Title, { variant: "segment" }, "Cambiar informaci\u00F3n"),
        React.createElement(InputContainer, null,
            React.createElement(CustomInput, __assign({ label: "Nombre", placeholder: "Ingrese nombre" }, register("name"), { error: (_a = errors.name) === null || _a === void 0 ? void 0 : _a.message }))),
        React.createElement(InputContainer, null,
            React.createElement(CustomInput, __assign({ label: "Apellido", placeholder: "Ingrese apellido" }, register("lastName"), { error: (_b = errors.lastName) === null || _b === void 0 ? void 0 : _b.message }))),
        React.createElement(InputContainer, null,
            React.createElement(CustomInput, __assign({ label: "Correo", placeholder: "ingrese correo" }, register("email"), { error: (_c = errors.email) === null || _c === void 0 ? void 0 : _c.message })))));
};
export { Component as ChangeInfo };
export default Component;
