var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import icons from "assets/icons";
import { useFormContext } from "react-hook-form";
import { CustomInput, Dropdown, SubSegment } from "components";
import { AmenitiesEditor, InputName, SelectContainer } from "./styled";
var Component = function () {
    var _a, _b;
    var _c = useFormContext(), register = _c.register, setValue = _c.setValue, watch = _c.watch, errors = _c.formState.errors;
    var currentIconId = watch("iconId");
    var formtattedIcons = icons.map(function (icon, index) {
        return {
            id: index.toString(),
            text: "",
            image: icon,
        };
    });
    var selectedOption = currentIconId !== -1
        ? formtattedIcons.filter(function (option) { return Number(option.id) === currentIconId; })[0]
        : null;
    var onIconChange = function (value) {
        var id = value.id;
        setValue("iconId", Number(id));
    };
    return (React.createElement(AmenitiesEditor, null,
        React.createElement(SubSegment, null,
            React.createElement(InputName, null,
                React.createElement(CustomInput, __assign({}, register("name"), { error: (_a = errors.name) === null || _a === void 0 ? void 0 : _a.message, showLabel: false }))),
            React.createElement(SelectContainer, null,
                React.createElement(Dropdown, { options: formtattedIcons, isImg: true, onChange: onIconChange, selectedItem: selectedOption, error: (_b = errors.iconId) === null || _b === void 0 ? void 0 : _b.message })))));
};
export default Component;
export { Component as AmenitiesEditor };
