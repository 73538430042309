var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useEffect } from "react";
import { useGoogleMaps } from "react-hook-google-maps";
import isEmpty from "lodash/isEmpty";
import { MyLocationStyle } from "./styled";
var googleMapsAPIKey = "AIzaSyC2dmvCFNPuKky2j6CRNbRhU-X6yZcoRzE";
var Component = function (props) {
    var className = props.className, _a = props.draggable, draggable = _a === void 0 ? false : _a, positionSaved = props.positionSaved, onPositionChange = props.onPositionChange;
    var initialPosition = {
        lat: (positionSaved === null || positionSaved === void 0 ? void 0 : positionSaved.lat) || 19.060140572364535,
        lng: (positionSaved === null || positionSaved === void 0 ? void 0 : positionSaved.lng) || -104.25727030030822,
    };
    var _b = useGoogleMaps(googleMapsAPIKey, {
        center: initialPosition,
        zoom: 17,
    }), ref = _b.ref, map = _b.map, google = _b.google;
    var _c = __read(useState({}), 2), position = _c[0], setPosition = _c[1];
    var _d = __read(useState({}), 2), myPosition = _d[0], setMyPosition = _d[1];
    useEffect(function () {
        if (isEmpty(position)) {
            return;
        }
        onPositionChange && onPositionChange(position);
    }, [position]);
    useEffect(function () {
        if (google !== undefined && map !== undefined) {
            var marker_1 = new google.maps.Marker({
                position: initialPosition,
                draggable: draggable,
                map: map,
            });
            map.setCenter(initialPosition);
            if (draggable) {
                var locationButton = document.createElement("button");
                locationButton.type = "button";
                locationButton.textContent = "Usar mi ubicacion";
                locationButton.style.cssText = "" + MyLocationStyle;
                map.controls[google.maps.ControlPosition.TOP_CENTER].push(locationButton);
                locationButton.addEventListener("click", function () {
                    if (isEmpty(myPosition) && navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition(function (position) {
                            var pos = {
                                lat: position.coords.latitude,
                                lng: position.coords.longitude,
                            };
                            map.setCenter(pos);
                            marker_1.setPosition(pos);
                            setPosition(pos);
                            setMyPosition(pos);
                        }, function () { });
                    }
                    else if (!isEmpty(myPosition)) {
                        map.setCenter(myPosition);
                        marker_1.setPosition(myPosition);
                        setPosition(myPosition);
                        setMyPosition(myPosition);
                    }
                    else {
                        // Browser doesn't support Geolocation
                        // handleLocationError(false, infoWindow, map.getCenter()!);
                    }
                });
                map.addListener("click", function (event) {
                    marker_1.setPosition(event.latLng);
                    setPosition(event.latLng.toJSON());
                });
            }
            marker_1.addListener("dragend", function () {
                setPosition(marker_1.getPosition().toJSON());
            });
        }
    }, [google, map]);
    return (React.createElement("div", { ref: ref, className: className, style: { width: "100%", height: 500 } }));
};
export { Component as Map };
export default Component;
