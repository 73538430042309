var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n"], ["\n  width: 100%;\n  height: 100%;\n"])));
var Label = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-family: \"Roboto-Medium\";\n  font-size: 14px;\n  margin-bottom: 5px;\n"], ["\n  font-family: \"Roboto-Medium\";\n  font-size: 14px;\n  margin-bottom: 5px;\n"])));
var HelpText = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-family: \"Roboto-Medium\";\n  font-size: 14px;\n  margin-left: 5px;\n"], ["\n  font-family: \"Roboto-Medium\";\n  font-size: 14px;\n  margin-left: 5px;\n"])));
var InputContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-family: \"Roboto\";\n  padding: 16px;\n  background-color: ", ";\n  border: ", ";\n  border-radius: 6px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  cursor: pointer;\n"], ["\n  font-family: \"Roboto\";\n  padding: 16px;\n  background-color: ", ";\n  border: ", ";\n  border-radius: 6px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  cursor: pointer;\n"])), function (props) { var _a, _b; return (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.colors) === null || _b === void 0 ? void 0 : _b.whitefff; }, function (props) { var _a, _b; return "solid 1px " + ((_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.colors) === null || _b === void 0 ? void 0 : _b.gray); });
var Input = styled.input(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  -webkit-appearance: none;\n  -moz-appearance: textfield;\n  width: 100%;\n  margin-right: 5px;\n  font-size: 14px;\n  background-color: transparent;\n  color: #212529;\n  border: none;\n  cursor: pointer;\n  &:focus {\n    outline: none;\n  }\n"], ["\n  -webkit-appearance: none;\n  -moz-appearance: textfield;\n  width: 100%;\n  margin-right: 5px;\n  font-size: 14px;\n  background-color: transparent;\n  color: #212529;\n  border: none;\n  cursor: pointer;\n  &:focus {\n    outline: none;\n  }\n"])));
var Error = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  color: ", ";\n  font-family: \"Roboto-Medium\";\n  font-size: 12px;\n  font-weight: 500;\n"], ["\n  color: ", ";\n  font-family: \"Roboto-Medium\";\n  font-size: 12px;\n  font-weight: 500;\n"])), function (params) { var _a; return (_a = params === null || params === void 0 ? void 0 : params.theme) === null || _a === void 0 ? void 0 : _a.colors.redff6; });
var Icon = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  color: #495057;\n"], ["\n  color: #495057;\n"])));
export { Container, Label, HelpText, InputContainer, Error, Icon, Input };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
