var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useContext, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useLayer } from "react-laag";
import { SolidButton, PersonCounter, CustomInput } from "components";
import { faUserAlt } from "@fortawesome/free-solid-svg-icons";
import PeopleNumber from "./styled";
import Context from "app/Context";
var Component = function (props) {
    var _a;
    var _b = props.defaultAdults, defaultAdults = _b === void 0 ? 0 : _b, _c = props.defaultChildren, defaultChildren = _c === void 0 ? 0 : _c, _d = props.defaultInfants, defaultInfants = _d === void 0 ? 0 : _d, _e = props.isReadonly, isReadonly = _e === void 0 ? false : _e;
    var _f = useFormContext(), setValue = _f.setValue, errors = _f.formState.errors;
    useEffect(function () {
        setPeopleNumber({
            adults: defaultAdults,
            children: defaultChildren,
            infants: defaultInfants,
        });
    }, [defaultAdults, defaultChildren, defaultInfants]);
    var isEnglish = useContext(Context).isEnglish;
    var _g = __read(useState(false), 2), isOpen = _g[0], setIsOpen = _g[1];
    var toggleSelect = function () {
        if (isReadonly)
            return;
        setIsOpen(!isOpen);
    };
    var _h = __read(useState({
        adults: defaultAdults,
        children: defaultChildren,
        infants: defaultInfants,
    }), 2), peopleNumber = _h[0], setPeopleNumber = _h[1];
    var _j = useLayer({
        isOpen: isOpen,
        onOutsideClick: toggleSelect,
        overflowContainer: true,
        auto: false,
        placement: "bottom-center",
        triggerOffset: 20,
        containerOffset: 16,
        arrowOffset: 16,
    }), triggerProps = _j.triggerProps, layerProps = _j.layerProps, renderLayer = _j.renderLayer;
    var adults = peopleNumber.adults, children = peopleNumber.children, infants = peopleNumber.infants;
    setValue("capacity", peopleNumber);
    var changeValueAdults = function (value) {
        setPeopleNumber(__assign(__assign({}, peopleNumber), { adults: value }));
    };
    var changeValueChildren = function (value) {
        setPeopleNumber(__assign(__assign({}, peopleNumber), { children: value }));
    };
    var changeValueInfants = function (value) {
        setPeopleNumber(__assign(__assign({}, peopleNumber), { infants: value }));
    };
    var stringValueInput = function () {
        var value = [];
        if (adults > 0) {
            var singular = adults === 1;
            value.push(" " + adults + " " + (singular
                ? "" + (isEnglish ? "adult" : "adulto")
                : "" + (isEnglish ? "adults" : "adultos")));
        }
        if (children > 0) {
            var singular = children === 1;
            value.push(" " + children + " " + (singular
                ? "" + (isEnglish ? "child" : "niño")
                : "" + (isEnglish ? "children" : "niños")));
        }
        if (infants > 0) {
            var singular = infants === 1;
            value.push(" " + infants + " " + (singular
                ? "" + (isEnglish ? "infant" : "infante")
                : "" + (isEnglish ? "infants" : "infantes")));
        }
        return value.toString();
    };
    var ResetPeopleNumber = function () {
        setPeopleNumber({
            adults: 0,
            children: 0,
            infants: 0,
        });
    };
    return (React.createElement(PeopleNumber, null,
        React.createElement(PeopleNumber.InputContainer, __assign({}, triggerProps),
            React.createElement(CustomInput, { label: isEnglish ? "Guests" : "Número de personas", placeholder: isEnglish
                    ? "Select the number of guests"
                    : "Selecciona el número de personas", value: stringValueInput(), readOnly: true, onClick: toggleSelect, icon: faUserAlt, error: (_a = errors.capacity) === null || _a === void 0 ? void 0 : _a.adults.message })),
        isOpen &&
            renderLayer(React.createElement(PeopleNumber.SelectContainer, __assign({}, layerProps),
                React.createElement(PersonCounter, { valueSelect: peopleNumber.adults, setValue: changeValueAdults, data: {
                        limit: 0,
                        title: "" + (isEnglish ? "Adults" : "Adultos"),
                        description: "" + (isEnglish ? "Ages 13 or above" : "Mayores de 13 años"),
                    } }),
                React.createElement(PersonCounter, { valueSelect: peopleNumber.children, setValue: changeValueChildren, data: {
                        limit: 0,
                        title: "" + (isEnglish ? "Children" : "Niños"),
                        description: "" + (isEnglish ? "Ages 2 - 12" : "De 2 - 12 años"),
                    } }),
                React.createElement(PersonCounter, { valueSelect: peopleNumber.infants, setValue: changeValueInfants, data: {
                        limit: 0,
                        title: "" + (isEnglish ? "Infants" : "Infantes"),
                        description: "" + (isEnglish ? "Under 2" : "Menores a 2 años"),
                    } }),
                React.createElement(PeopleNumber.ButtonContainer, null,
                    React.createElement(SolidButton, { children: isEnglish ? "Reset" : "Resetear", onClick: ResetPeopleNumber }))))));
};
export default Component;
export { Component as PeopleNumber };
