var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
var Amenitie = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-items: center;\n  border-radius: 4px;\n  cursor: pointer;\n  display: flex;\n  height: 32px;\n  justify-content: center;\n  margin: 0 8px 0 0;\n"], ["\n  align-items: center;\n  border-radius: 4px;\n  cursor: pointer;\n  display: flex;\n  height: 32px;\n  justify-content: center;\n  margin: 0 8px 0 0;\n"])));
Amenitie.Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n"], ["\n  display: flex;\n"])));
Amenitie.Icon = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 32px;\n  height: 32px;\n  background-color: ", ";\n  color: ", ";\n  font-family: \"Roboto\";\n  > svg {\n    margin: 7px;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 32px;\n  height: 32px;\n  background-color: ", ";\n  color: ", ";\n  font-family: \"Roboto\";\n  > svg {\n    margin: 7px;\n  }\n"])), function (props) { var _a, _b; return (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.colors) === null || _b === void 0 ? void 0 : _b.blueeaf; }, function (props) { var _a, _b; return (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.colors) === null || _b === void 0 ? void 0 : _b.blue00c5; });
Amenitie.IconItem = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 1.5rem;\n  height: 1.5rem;\n"], ["\n  width: 1.5rem;\n  height: 1.5rem;\n"])));
Amenitie.Name = styled.p(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-size: 16px;\n  font-family: \"Roboto\";\n  margin-left: 21px;\n"], ["\n  font-size: 16px;\n  font-family: \"Roboto\";\n  margin-left: 21px;\n"])));
export default Amenitie;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
