var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var Modal = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  top: 0;\n  left: 0;\n  background-color: ", ";\n  width: 100vw;\n  height: 100vh;\n  z-index: 100;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n"], ["\n  position: fixed;\n  top: 0;\n  left: 0;\n  background-color: ", ";\n  width: 100vw;\n  height: 100vh;\n  z-index: 100;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n"])), function (props) { return props.theme.colors.black000c2; });
Modal.Wrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-color: ", ";\n  border-radius: 10px;\n  display: flex;\n  flex-direction: column;\n  padding: 10px;\n  width: 80%;\n"], ["\n  background-color: ", ";\n  border-radius: 10px;\n  display: flex;\n  flex-direction: column;\n  padding: 10px;\n  width: 80%;\n"])), function (props) { return props.theme.colors.whitefff; });
Modal.Controls = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  cursor: pointer;\n  font-size: 20px;\n  align-self: flex-end;\n  height: 30px;\n"], ["\n  color: ", ";\n  cursor: pointer;\n  font-size: 20px;\n  align-self: flex-end;\n  height: 30px;\n"])), function (props) { return props.theme.colors.black212; });
Modal.Content = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-bottom: 60px;\n"], ["\n  margin-bottom: 60px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
