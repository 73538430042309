var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useContext } from "react";
import { useFormContext } from "react-hook-form";
import { SelectImage, CustomInput, Segment, Label } from "components";
import { Title, Row, InputTitle, InputDescription, ImageEdit } from "./styled";
import Context from "app/Context";
var Component = function (props) {
    var _a = useContext(Context), isEnglish = _a.isEnglish, isSpanish = _a.isSpanish;
    var title = props.title;
    var register = useFormContext().register;
    return (React.createElement(Segment, null,
        React.createElement(Title, null, title),
        React.createElement(Row, null,
            React.createElement(InputTitle, null,
                isSpanish && (React.createElement(CustomInput, __assign({ label: "Título" }, register("generals.title")))),
                isEnglish && (React.createElement(CustomInput, __assign({ label: "Title" }, register("generals.englishTitle"))))),
            React.createElement(ImageEdit, null,
                React.createElement(Label, null, "Imagen"),
                React.createElement(SelectImage, { fieldName: "generals.picture" }))),
        React.createElement(InputDescription, null,
            isSpanish && (React.createElement(CustomInput, __assign({ label: "Descripción" }, register("generals.description")))),
            isEnglish && (React.createElement(CustomInput, __assign({ label: "Description" }, register("generals.englishDescription")))))));
};
export default Component;
export { Component as EditInformationImage };
