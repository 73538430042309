import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import { Section } from "./styles";
import { CustomInput, Title, Segment } from "components";
var Component = function () {
    var _a = useFormContext(), register = _a.register, errors = _a.formState.errors, control = _a.control;
    return (React.createElement(Segment, null,
        React.createElement(Title, { variant: "segment" }, "Impuestos"),
        React.createElement(Section, null,
            React.createElement(Controller, { control: control, name: "config.taxes.iva", render: function (_a) {
                    var _b, _c, _d;
                    var _e = _a.field, value = _e.value, onChange = _e.onChange;
                    return (React.createElement(NumberFormat, { label: "IVA", suffix: "%", customInput: CustomInput, error: (_d = (_c = (_b = errors.config) === null || _b === void 0 ? void 0 : _b.taxes) === null || _c === void 0 ? void 0 : _c.iva) === null || _d === void 0 ? void 0 : _d.message, value: value, onValueChange: function (_a) {
                            var floatValue = _a.floatValue;
                            onChange(floatValue);
                        } }));
                } }),
            React.createElement(Controller, { control: control, name: "config.taxes.ish", render: function (_a) {
                    var _b, _c, _d;
                    var _e = _a.field, value = _e.value, onChange = _e.onChange;
                    return (React.createElement(NumberFormat, { label: "ISH (Impuesto sobre hospedaje)", suffix: "%", customInput: CustomInput, error: (_d = (_c = (_b = errors.config) === null || _b === void 0 ? void 0 : _b.taxes) === null || _c === void 0 ? void 0 : _c.ish) === null || _d === void 0 ? void 0 : _d.message, value: value, onValueChange: function (_a) {
                            var floatValue = _a.floatValue;
                            onChange(floatValue);
                        } }));
                } }))));
};
export default Component;
export { Component as TaxesForm };
