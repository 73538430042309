var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { Image } from "components";
import { MyExplorerItem, ImageContainer, Title, Description, Link, } from "./styled";
var ExplorerItem = function (props) {
    var dataItem = props.dataItem, rest = __rest(props, ["dataItem"]);
    var title = dataItem.title, image = dataItem.image, description = dataItem.description, url = dataItem.url;
    return (React.createElement(MyExplorerItem, null,
        React.createElement(ImageContainer, { href: url, target: '_blank' },
            React.createElement(Image, { src: image })),
        React.createElement(Title, { href: url, target: '_blank' }, title),
        React.createElement(Description, null, description),
        url && React.createElement(Link, { href: url, target: '_blank' }, "Leer m\u00E1s")));
};
export { ExplorerItem };
export default ExplorerItem;
