var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var textCollection = {
    ExtraGuest: {
        es: "Precio por húesped extra",
        en: "Price because extra guest",
    },
    GuestRequired: {
        es: "Húespedes",
        en: "Guests",
    },
    ExtraGuestRequired: {
        es: "Total de {ExtraGuestRequired} húesped(es) extra(s) por {extraGuestRequiredNights} noche(s)",
        en: "Total {ExtraGuestRequired} extra guests x {extraGuestRequiredNights} night(s)",
    },
    StayNightsDiscount: {
        es: "Descuento por estancia larga {StayNightsDiscount}",
        en: "Long stay discount {StayNightsDiscount}",
    },
    BasePrice: {
        es: "${BasePrice} x {NightsToStay} noches",
        en: "${BasePrice} x {NightsToStay} nights",
    },
    LastMinuteDiscount: {
        es: "Descuento por reservación de última hora {LastMinuteDiscount}",
        en: "Last minute reservation discount {LastMinuteDiscount}",
    },
    WeekendPrice: {
        es: "${WeekendPrice} x {WeekendNightsToStay} noches en fin de semana",
        en: "${WeekendPrice} x {WeekendNightsToStay} nights at weekend",
    },
};
export var generateProcessedTransactionsForBungalow = function (transactions) {
    var e_1, _a, e_2, _b, e_3, _c;
    var realTransactions = [];
    var rules = [];
    try {
        for (var transactions_1 = __values(transactions), transactions_1_1 = transactions_1.next(); !transactions_1_1.done; transactions_1_1 = transactions_1.next()) {
            var transaction = transactions_1_1.value;
            var data = transaction.data;
            try {
                for (var data_1 = (e_2 = void 0, __values(data)), data_1_1 = data_1.next(); !data_1_1.done; data_1_1 = data_1.next()) {
                    var item = data_1_1.value;
                    var ruleName = item.ruleName, label = item.label, value = item.value, percent = item.percent;
                    if (!rules.includes(ruleName))
                        rules.push(ruleName);
                    var objectToStorage = {
                        ruleName: ruleName,
                        label: label,
                        value: value,
                        percent: percent,
                        times: 1,
                        total: value,
                    };
                    var indexByRuleLabel = getIndexByLabel(realTransactions, ruleName, label);
                    if (indexByRuleLabel === -1) {
                        realTransactions.push(objectToStorage);
                        continue;
                    }
                    realTransactions[indexByRuleLabel].total += value;
                    if (label !== "ExtraGuestRequired")
                        realTransactions[indexByRuleLabel].times += 1;
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (data_1_1 && !data_1_1.done && (_b = data_1.return)) _b.call(data_1);
                }
                finally { if (e_2) throw e_2.error; }
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (transactions_1_1 && !transactions_1_1.done && (_a = transactions_1.return)) _a.call(transactions_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    try {
        for (var rules_1 = __values(rules), rules_1_1 = rules_1.next(); !rules_1_1.done; rules_1_1 = rules_1.next()) {
            var ruleName = rules_1_1.value;
            var extraGuestIndex = getIndexByLabel(realTransactions, ruleName, "ExtraGuest");
            var extraGuestRequiredIndex = getIndexByLabel(realTransactions, ruleName, "ExtraGuestRequired");
            if (extraGuestIndex === -1 || extraGuestRequiredIndex === -1)
                continue;
            var _d = realTransactions[extraGuestIndex], extraGuestTotal = _d.value, times = _d.times;
            var extraGuestRequiredTotal = realTransactions[extraGuestRequiredIndex].value;
            realTransactions[extraGuestIndex].total = extraGuestTotal;
            realTransactions[extraGuestRequiredIndex].times = times;
            realTransactions[extraGuestRequiredIndex].total =
                extraGuestTotal * extraGuestRequiredTotal * times;
        }
    }
    catch (e_3_1) { e_3 = { error: e_3_1 }; }
    finally {
        try {
            if (rules_1_1 && !rules_1_1.done && (_c = rules_1.return)) _c.call(rules_1);
        }
        finally { if (e_3) throw e_3.error; }
    }
    var sorted = realTransactions.sort(function (prev, cur) {
        return prev.ruleName < cur.ruleName ? -1 : 0;
    });
    return sorted;
};
export var generateChargeText = function (label, value, percent, times, total, language) {
    var text = textCollection[label][language];
    text = text.replace("{ExtraGuest}", times);
    text = text.replace("{ExtraGuestRequired}", value);
    text = text.replace("{extraGuestRequiredNights}", times);
    text = text.replace("{StayNightsDiscount}", "(" + percent + "%)");
    text = text.replace("{BasePrice}", value);
    text = text.replace("{NightsToStay}", times);
    text = text.replace("{WeekendPrice}", value);
    text = text.replace("{WeekendNightsToStay}", times);
    text = text.replace("{LastMinuteDiscount}", "(" + percent + ")%");
    return text;
};
export var formatPrice = function (value, language) {
    var locale = language === "es" ? "es-MX" : "en-US";
    var currency = language === "es" ? "MXN" : "USD";
    var formatter = new Intl.NumberFormat(locale, {
        style: "currency",
        currency: currency,
    });
    return formatter.format(value);
};
export var calculateBungalowTotalByTransations = function (charges, transactions) {
    var e_4, _a;
    var bungalowTotal = 0;
    try {
        for (var transactions_2 = __values(transactions), transactions_2_1 = transactions_2.next(); !transactions_2_1.done; transactions_2_1 = transactions_2.next()) {
            var transaction = transactions_2_1.value;
            var label = transaction.label, total = transaction.total;
            var priceNeeded = label !== "ExtraGuest" && label !== "GuestRequired";
            bungalowTotal += priceNeeded ? total : 0;
        }
    }
    catch (e_4_1) { e_4 = { error: e_4_1 }; }
    finally {
        try {
            if (transactions_2_1 && !transactions_2_1.done && (_a = transactions_2.return)) _a.call(transactions_2);
        }
        finally { if (e_4) throw e_4.error; }
    }
    var value = (charges === null || charges === void 0 ? void 0 : charges.filter(function (charge) { return charge.label === "CleanningCharge"; })[0]).value;
    return bungalowTotal + value;
};
var getIndexByLabel = function (transactions, ruleName, label) {
    return transactions.findIndex(function (item) { return item.ruleName === ruleName && item.label === label; });
};
export var processAllBungalows = function (proposals) {
    var e_5, _a, e_6, _b;
    var _c, _d;
    var total = 0;
    var taxes = 0;
    var subtotal = 0;
    try {
        for (var proposals_1 = __values(proposals), proposals_1_1 = proposals_1.next(); !proposals_1_1.done; proposals_1_1 = proposals_1.next()) {
            var proposal = proposals_1_1.value;
            taxes = 0;
            total = 0;
            subtotal = 0;
            var bungalowtotal = 0;
            var iva = proposal.iva, ish = proposal.ish;
            try {
                for (var _e = (e_6 = void 0, __values(proposal.bungalows)), _f = _e.next(); !_f.done; _f = _e.next()) {
                    var bungalow = _f.value;
                    var transactions = bungalow.transactions, charges = bungalow.charges;
                    var processedTransactions = generateProcessedTransactionsForBungalow(transactions);
                    var extraGuestsRequired = (_c = processedTransactions.find(function (transaction) { return transaction.label === "ExtraGuestRequired"; })) === null || _c === void 0 ? void 0 : _c.value;
                    var guestRequired = (_d = processedTransactions.find(function (transaction) { return transaction.label === "GuestRequired"; })) === null || _d === void 0 ? void 0 : _d.value;
                    bungalow.extraGuestRequired = extraGuestsRequired;
                    bungalow.guestRequired = guestRequired;
                    bungalowtotal = calculateBungalowTotalByTransations(charges, processedTransactions);
                    bungalow.transactions = processedTransactions;
                    bungalow.total = bungalowtotal;
                    subtotal += bungalowtotal;
                }
            }
            catch (e_6_1) { e_6 = { error: e_6_1 }; }
            finally {
                try {
                    if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
                }
                finally { if (e_6) throw e_6.error; }
            }
            taxes = subtotal * (iva / 100) + subtotal * (ish / 100);
            total = subtotal + taxes;
            proposal.subtotal = subtotal;
            proposal.taxes = taxes;
            proposal.total = total;
        }
    }
    catch (e_5_1) { e_5 = { error: e_5_1 }; }
    finally {
        try {
            if (proposals_1_1 && !proposals_1_1.done && (_a = proposals_1.return)) _a.call(proposals_1);
        }
        finally { if (e_5) throw e_5.error; }
    }
    var orderedProposals = proposals.sort(function (first, second) { return first.total - second.total; });
    return orderedProposals;
};
export var optionsToSort = [
    { label: "Alfabeto ascendente  (A-Z)", short: "(A-Z)", id: "ASC" },
    { label: "Alfabeto descendente  (Z-A)", short: "(Z-A)", id: "DESC" },
];
