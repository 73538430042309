import isEmpty from "lodash/isEmpty";
export var distpachActionWhenSuccess = function (response, action, errorAction) {
    var _a;
    if ((response === null || response === void 0 ? void 0 : response.success) && !isEmpty(response.payload)) {
        if (action)
            action(response.payload);
    }
    else if (errorAction) {
        errorAction((_a = response === null || response === void 0 ? void 0 : response.errors[0]) === null || _a === void 0 ? void 0 : _a.message);
    }
};
