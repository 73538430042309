import React from "react";
import { TextInput } from "./styled";
var TextFilter = function (_a) {
    var _b = _a.column, filterValue = _b.filterValue, preFilteredRows = _b.preFilteredRows, setFilter = _b.setFilter;
    var count = preFilteredRows.length;
    return (React.createElement(TextInput, { value: filterValue || "", onChange: function (e) {
            setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }, placeholder: "Buscar en " + count + " registros." }));
};
export { TextFilter };
export default TextFilter;
