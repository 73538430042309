var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { SolidButton, Image, Price, Features, Amenities, } from "../../components";
import Bungalow from "./styled";
import { navigate } from "@reach/router";
var BungalowItem = function (props) {
    var _a;
    var dataBungalow = props.dataBungalow, _b = props.showPrice, showPrice = _b === void 0 ? true : _b, rest = __rest(props, ["dataBungalow", "showPrice"]);
    var slug = dataBungalow.slug, name = dataBungalow.name, mainPictureId = dataBungalow.mainPictureId, features = dataBungalow.features, pictures = dataBungalow.pictures, amenities = dataBungalow.amenities, pricePerNight = dataBungalow.pricePerNight;
    var mainPicture = mainPictureId
        ? pictures.filter(function (picture) { return picture.id === mainPictureId; })[0]
        : pictures.shift();
    var mainPictureUrl = (_a = mainPicture === null || mainPicture === void 0 ? void 0 : mainPicture.url) !== null && _a !== void 0 ? _a : "";
    var renderPrice = showPrice && (React.createElement(React.Fragment, null,
        React.createElement(Bungalow.Button, null,
            React.createElement(SolidButton, { children: "Ver detalles", onClick: function () { return navigate("bungalows/" + slug); } })),
        React.createElement(Bungalow.PriceContainer, null,
            React.createElement(Price, { showFrom: true, price: pricePerNight }))));
    return (React.createElement(Bungalow, null,
        React.createElement(Bungalow.ImageContainer, null,
            React.createElement(Image, { src: mainPictureUrl })),
        React.createElement(Bungalow.Title, null, name),
        React.createElement(Bungalow.Features, null,
            React.createElement(Features, { features: features, isEditing: false })),
        React.createElement(Bungalow.Amenities, null,
            React.createElement(Amenities, { amenitieData: amenities, showDetails: false })),
        renderPrice));
};
export { BungalowItem };
export default BungalowItem;
