var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import Component from "../";
import Styled from "../styled";
var TransparentButton = styled(Component)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", " {\n    max-width: 171px;\n    background-color: transparent;\n    color: ", ";\n    border-color: ", ";\n  }\n"], ["\n  ", " {\n    max-width: 171px;\n    background-color: transparent;\n    color: ", ";\n    border-color: ", ";\n  }\n"])), Styled.Button, function (props) { var _a, _b; return (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.colors) === null || _b === void 0 ? void 0 : _b.redff6; }, function (props) { var _a, _b; return (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.colors) === null || _b === void 0 ? void 0 : _b.redff6; });
export default TransparentButton;
var templateObject_1;
