import React, { useContext } from "react";
import Amenitie from "./styled";
import icons from "assets/icons";
import Context from "app/Context";
export var Amenities = function (props) {
    var isEnglish = useContext(Context).isEnglish;
    var amenitieData = props.amenitieData, showDetails = props.showDetails;
    var amenities = amenitieData.map(function (amenitie, index) { return (React.createElement(Amenitie, { key: "amenity-item-" + index },
        React.createElement(Amenitie.Icon, null,
            React.createElement(Amenitie.IconItem, { src: icons[amenitie.iconId] })),
        showDetails && (React.createElement(Amenitie.Name, null, isEnglish ? amenitie.englishName : amenitie.name)))); });
    return React.createElement(Amenitie.Container, null, amenities);
};
export default Amenities;
