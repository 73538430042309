import React from "react";
import { ImageEditable, Controls, Container, SelectableContainer, MainImageLabel, MainImageInput, } from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faRedo } from "@fortawesome/free-solid-svg-icons";
var Component = function (props) {
    var id = props.id, src = props.src, _a = props.showDeleteButton, showDeleteButton = _a === void 0 ? true : _a, _b = props.allowRestore, allowRestore = _b === void 0 ? true : _b, _c = props.isDeleted, isDeleted = _c === void 0 ? false : _c, onDelete = props.onDelete, onRestore = props.onRestore, size = props.size, multiple = props.multiple, onMainSelect = props.onMainSelect, _d = props.isMainPicture, isMainPicture = _d === void 0 ? false : _d, _e = props.isSelectable, isSelectable = _e === void 0 ? false : _e;
    var renderDeleteButton = showDeleteButton && !isDeleted && (React.createElement(Controls, { onClick: function () { return onDelete(id); } },
        React.createElement(Controls.Image, null,
            React.createElement(FontAwesomeIcon, { icon: faTimes })),
        React.createElement(Controls.Text, null, "Eliminar imagen")));
    var restore = function () {
        if (allowRestore && onRestore)
            onRestore(id);
    };
    var renderRestoreButton = isDeleted && onRestore && (React.createElement(ImageEditable.Restore, { onClick: restore },
        React.createElement(ImageEditable.RestoreBackground, null),
        React.createElement(Controls.Image, null,
            React.createElement(FontAwesomeIcon, { icon: faRedo })),
        React.createElement(Controls.Text, null, "Restaurar imagen")));
    var renderSelectable = isSelectable && onMainSelect && (React.createElement(SelectableContainer, null,
        React.createElement(MainImageInput, { type: "radio", name: "mainImage", checked: isMainPicture, id: "main-image-" + id, onChange: function () { return onMainSelect(id); } }),
        React.createElement(MainImageLabel, { htmlFor: "main-image-" + id }, "Imagen principal")));
    return (React.createElement(Container, null,
        React.createElement(ImageEditable, { size: size, multiple: multiple, style: {
                backgroundImage: "url(" + src + ")",
            } },
            renderRestoreButton,
            renderDeleteButton),
        renderSelectable));
};
export { Component as ImageEditable };
export default Component;
