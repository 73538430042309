var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import Context from "app/Context";
import React, { useContext, useEffect, useState } from "react";
import { useStoreActions, useStoreState } from "state/hooks";
import { generateReservationReference } from "utils/generateReservationReference";
export var Reservation = function (props) {
    var id = props.id;
    var _a = __read(useState(null), 2), url = _a[0], setUrl = _a[1];
    var language = useContext(Context).language;
    var reservation = useStoreState(function (state) { return state.reservation.reservation; });
    var _b = useStoreActions(function (actions) { return actions.reservation; }), getReservation = _b.getReservation, setReservation = _b.setReservation;
    var cleanReservation = function () {
        setReservation(null);
    };
    useEffect(function () {
        return cleanReservation;
    }, []);
    useEffect(function () {
        if (!id)
            return;
        getReservation({
            id: id,
        });
    }, [id]);
    useEffect(function () {
        if (!reservation)
            return;
        var pdfDocGenerator = generateReservationReference(reservation, language);
        pdfDocGenerator.getBlob(function (blob) {
            var url = URL.createObjectURL(blob);
            setUrl(url);
        });
    }, [reservation]);
    useEffect(function () {
        return function () {
            if (url !== null) {
                URL.revokeObjectURL(url);
            }
        };
    }, [url]);
    return (React.createElement("div", { className: "App" }, url && (React.createElement("div", null,
        React.createElement("object", { style: {
                width: "100%",
                height: "100vh",
            }, data: url, type: "application/pdf" },
            React.createElement("embed", { src: url, type: "application/pdf" }))))));
};
