var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { navigate } from "@reach/router";
import React, { useEffect, useState } from "react";
import { Container, Content } from "components/Layout";
import { processAllBungalows } from "utils/bungalowFactory";
import { useStoreActions, useStoreState } from "state/hooks";
import { AvailabilityResume, Loader, Proposal } from "components";
var Availability = function (props) {
    var _a = useStoreState(function (state) { return state.availability; }), search = _a.search, availability = _a.availability, isLoading = _a.isLoading;
    var _b = __read(useState([]), 2), proposalsToRender = _b[0], setPoposalsToRender = _b[1];
    var doSearch = useStoreActions(function (actions) { return actions.availability; }).doSearch;
    var createReservation = useStoreActions(function (actions) { return actions.reservation; }).createReservation;
    var proposals = (availability !== null && availability !== void 0 ? availability : {}).proposals;
    useEffect(function () {
        if (!search)
            navigate("/");
        doSearch({ search: search });
    }, [search]);
    useEffect(function () {
        if (!proposals)
            return;
        var orderedProposals = processAllBungalows(proposals);
        setPoposalsToRender(orderedProposals);
    }, [proposals]);
    var renderLoading = isLoading && React.createElement(Loader, null);
    var rendereAvailabilityResume = availability && (React.createElement(AvailabilityResume, { data: search, proposalCount: proposals === null || proposals === void 0 ? void 0 : proposals.length }));
    var onSubmitReservation = function (data) {
        var name = data.name, phone = data.phone, capacity = data.capacity, startDate = data.startDate, endDate = data.endDate, bungalows = data.bungalows, subtotal = data.subtotal, taxes = data.taxes, total = data.total;
        var bungalowsData = bungalows.map(function (bungalow) {
            var e_1, _a;
            var id = bungalow.id, name = bungalow.name, rules = bungalow.rules, extraGuestRequired = bungalow.extraGuestRequired, guestRequired = bungalow.guestRequired;
            var reducedRules = [];
            var _loop_1 = function (bungalowRule) {
                var id_1 = bungalowRule.rule.id;
                if (reducedRules.find(function (rule) { return rule === id_1; }))
                    return "continue";
                reducedRules.push(id_1);
            };
            try {
                for (var rules_1 = __values(rules), rules_1_1 = rules_1.next(); !rules_1_1.done; rules_1_1 = rules_1.next()) {
                    var bungalowRule = rules_1_1.value;
                    _loop_1(bungalowRule);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (rules_1_1 && !rules_1_1.done && (_a = rules_1.return)) _a.call(rules_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
            return {
                id: id,
                name: name,
                guests: guestRequired,
                extraGuests: extraGuestRequired,
                rules: reducedRules,
            };
        });
        var formData = {
            data: {
                client: {
                    name: name,
                    phone: phone,
                },
                reservation: {
                    totals: {
                        subtotal: subtotal,
                        taxes: taxes,
                        total: total,
                    },
                    arrival: startDate,
                    departure: endDate,
                    capacity: capacity,
                    bungalows: bungalowsData,
                },
            },
        };
        createReservation(formData).then(function (reservationId) {
            navigate("reservation/" + reservationId);
        });
    };
    var renderProposals = proposalsToRender &&
        (proposalsToRender === null || proposalsToRender === void 0 ? void 0 : proposalsToRender.map(function (proposal, index) { return (React.createElement(Proposal, { key: "proposal-" + index, data: proposal, option: index + 1, search: search, onSubmitReservation: onSubmitReservation })); }));
    return (React.createElement(Content, null,
        React.createElement(Container, null,
            renderLoading,
            rendereAvailabilityResume,
            renderProposals)));
};
export { Availability };
export default Availability;
