var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Dropdown, Label, Segment } from "components";
import { useFormContext } from "react-hook-form";
import React, { useContext, useEffect, useState } from "react";
import { useStoreActions, useStoreState } from "state/hooks";
import { Controls, DropContainer, NewButton, RuleSelection, Section, Selectable, } from "./styled";
import DateRange from "components/DateRange";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { formateDate } from "utils/dates";
import Context from "app/Context";
var Component = function () {
    var isEnglish = useContext(Context).isEnglish;
    var _a = __read(useState(null), 2), selectedRule = _a[0], setSelectedRule = _a[1];
    var _b = __read(useState(null), 2), dropDownSelectedItem = _b[0], setDropDownSelectedItem = _b[1];
    var _c = __read(useState({
        from: undefined,
        to: undefined,
    }), 2), dates = _c[0], setDates = _c[1];
    var _d = useFormContext(), watch = _d.watch, setValue = _d.setValue;
    var selectedRules = watch("rules");
    var startDate = watch("startDate");
    var endDate = watch("endDate");
    var rules = useStoreState(function (state) { return state; }).rule.rules;
    var getRules = useStoreActions(function (actions) { return actions; }).rule.getRules;
    useEffect(function () {
        getRules();
    }, []);
    var availableRules = rules === null || rules === void 0 ? void 0 : rules.filter(function (rule) { return !rule.isBaseRule; });
    var formatRules = availableRules.map(function (rule) {
        var id = rule.id, name = rule.name, description = rule.description;
        return {
            id: id,
            text: name + ": " + description,
        };
    });
    var onChangeSelectedRule = function (item) {
        var _a;
        var id = item.id;
        var ruleSelected = availableRules.filter(function (rule) { return rule.id === id; });
        var rule = (_a = ruleSelected[0]) !== null && _a !== void 0 ? _a : null;
        setSelectedRule(rule);
        setDropDownSelectedItem(item);
    };
    var onNew = function () {
        if (selectedRule === undefined || selectedRule === null)
            return;
        var id = selectedRule.id;
        var exists = Array.isArray(selectedRules) &&
            selectedRules.some(function (bungalowRule) {
                var currentId = bungalowRule.rule.id, currentStartDate = bungalowRule.startDate, currentEndDate = bungalowRule.ednDate;
                return (currentId === id &&
                    startDate === currentStartDate &&
                    endDate === currentEndDate &&
                    !bungalowRule.toDelete);
            });
        if (exists)
            return;
        var startDateFormatted = startDate === "" ? null : startDate;
        var endDateFormatted = endDate === "" ? null : endDate;
        var newRule = {
            rule: selectedRule,
            startDate: startDateFormatted,
            endDate: endDateFormatted,
            isNew: true,
        };
        var rules = __spreadArray(__spreadArray([], __read((selectedRules !== null && selectedRules !== void 0 ? selectedRules : [])), false), [newRule], false);
        setSelectedRule(null);
        setDropDownSelectedItem(null);
        setValue("rules", rules);
        setDates({
            from: undefined,
            to: undefined,
        });
    };
    var onRemove = function (bungalowRuleToDelete) {
        var index = selectedRules.findIndex(function (selectedOne) { return selectedOne === bungalowRuleToDelete; });
        var newBungalowRules = selectedRules;
        newBungalowRules[index].toDelete = true;
        setValue("rules", newBungalowRules);
    };
    var renderRules = selectedRules === null || selectedRules === void 0 ? void 0 : selectedRules.filter(function (bungalowRule) {
        return !bungalowRule.rule.isBaseRule && !bungalowRule.toDelete;
    }).map(function (bungalowRule, index) {
        var _a = bungalowRule.rule, id = _a.id, name = _a.name, description = _a.description, startDate = bungalowRule.startDate, endDate = bungalowRule.endDate, isNew = bungalowRule.isNew;
        var datesRange = "SIN RANGO DE FECHAS";
        if (startDate && endDate) {
            var startDateF = isNew ? startDate : new Date(startDate + " 00:00");
            var endDateF = isNew ? endDate : new Date(endDate + " 00:00");
            datesRange = formateDate(startDateF, isEnglish) + " - " + formateDate(endDateF, isEnglish);
        }
        return (React.createElement(RuleSelection.RuleItem, { key: "rule-" + id + "-" + index },
            React.createElement(RuleSelection.Data, null,
                React.createElement(RuleSelection.MainData, null,
                    React.createElement(RuleSelection.RuleName, null,
                        name,
                        ": "),
                    React.createElement(RuleSelection.RuleDescription, null, description)),
                React.createElement(RuleSelection.Extra, null,
                    React.createElement(RuleSelection.DateRangeLegend, null, datesRange))),
            React.createElement(RuleSelection.DeleteContainer, { onClick: function () { return onRemove(bungalowRule); } },
                React.createElement(FontAwesomeIcon, { icon: faTrash }),
                " Eliminar")));
    });
    return (React.createElement(RuleSelection, null,
        React.createElement(Segment, null,
            React.createElement(Section, null,
                React.createElement(Section.Title, null, "Reglas"),
                React.createElement(Section.Help, null, "Selecciona las reglas que aplican a este bungalow.")),
            React.createElement(Selectable, null,
                React.createElement(Controls, null,
                    React.createElement(DropContainer, null,
                        React.createElement(Label, null, "Regla"),
                        React.createElement(Dropdown, { isImg: false, options: formatRules, selectedItem: dropDownSelectedItem, onChange: onChangeSelectedRule })),
                    React.createElement(DateRange, { fromText: "Fecha de inicio", toText: "Fecha de t\u00E9rmino", fromValue: dates.from, toValue: dates.to })),
                React.createElement(NewButton, { children: "+ Agregar regla", onClick: onNew })),
            React.createElement(RuleSelection.Container, null, renderRules))));
};
export { Component as RuleSelection };
export default Component;
