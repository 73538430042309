var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
var UserForm = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 20px;\n  margin: 0 auto;\n  width: 80%;\n"], ["\n  padding: 20px;\n  margin: 0 auto;\n  width: 80%;\n"])));
var Controls = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  > div {\n    margin-bottom: 20px;\n  }\n"], ["\n  width: 100%;\n  > div {\n    margin-bottom: 20px;\n  }\n"])));
var Buttons = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n  width: 100%;\n"], ["\n  display: flex;\n  justify-content: flex-end;\n  width: 100%;\n"])));
Buttons.Save = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-left: 24px;\n  max-width: 202px;\n  width: 100%;\n"], ["\n  margin-left: 24px;\n  max-width: 202px;\n  width: 100%;\n"])));
Buttons.Cancel = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 140px;\n"], ["\n  width: 140px;\n"])));
export { UserForm, Controls, Buttons };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
