var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
var Calendars = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  > div {\n    box-sizing: border-box;\n    min-height: 255px;\n    width: 100%;\n  }\n"], ["\n  > div {\n    box-sizing: border-box;\n    min-height: 255px;\n    width: 100%;\n  }\n"])));
Calendars.Text = styled.p(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: 14px;\n  font-family: Roboto;\n  margin: 6px 0;\n  margin-bottom: 20px;\n"], ["\n  font-size: 14px;\n  font-family: Roboto;\n  margin: 6px 0;\n  margin-bottom: 20px;\n"])));
Calendars.Inputs = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  box-sizing: border-box;\n  padding: 15px 8px;\n  margin-top: 20px;\n  background-color: ", ";\n  width: 856px;\n  height: 104px;\n  border-radius: 4px;\n  display: flex;\n  > div {\n    padding: 2px 8px;\n  }\n"], ["\n  box-sizing: border-box;\n  padding: 15px 8px;\n  margin-top: 20px;\n  background-color: ", ";\n  width: 856px;\n  height: 104px;\n  border-radius: 4px;\n  display: flex;\n  > div {\n    padding: 2px 8px;\n  }\n"])), function (params) { var _a; return (_a = params === null || params === void 0 ? void 0 : params.theme) === null || _a === void 0 ? void 0 : _a.colors.whitef8f; });
Calendars.InputName = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  > div {\n    width: 256px;\n    height: 48px;\n  }\n"], ["\n  > div {\n    width: 256px;\n    height: 48px;\n  }\n"])));
Calendars.InputUrl = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  > div {\n    width: 457px;\n    height: 48px;\n  }\n"], ["\n  > div {\n    width: 457px;\n    height: 48px;\n  }\n"])));
Calendars.TrashIcon = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  color: ", ";\n  cursor: pointer;\n  font-size: 14px;\n  font-family: Roboto;\n  align-self: flex-end;\n"], ["\n  color: ", ";\n  cursor: pointer;\n  font-size: 14px;\n  font-family: Roboto;\n  align-self: flex-end;\n"])), function (props) { var _a, _b; return (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.colors) === null || _b === void 0 ? void 0 : _b.rede24; });
Calendars.AddItem = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  padding: 15px;\n  cursor: pointer;\n  font-size: 16px;\n  color: ", ";\n  font-family: Roboto;\n"], ["\n  padding: 15px;\n  cursor: pointer;\n  font-size: 16px;\n  color: ", ";\n  font-family: Roboto;\n"])), function (props) { var _a, _b; return (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.colors) === null || _b === void 0 ? void 0 : _b.primary; });
export default Calendars;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
