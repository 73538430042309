var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
var EditInformation = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  max-width: 1200px;\n  height: fit-content;\n  padding: 16px;\n  flex-wrap: wrap;\n  border-radius: 10px;\n  background-color: ", ";\n"], ["\n  display: flex;\n  max-width: 1200px;\n  height: fit-content;\n  padding: 16px;\n  flex-wrap: wrap;\n  border-radius: 10px;\n  background-color: ", ";\n"])), function (props) { var _a, _b; return (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.colors) === null || _b === void 0 ? void 0 : _b.white; });
var Title = styled.h2(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  height: fit-content;\n  color: ", ";\n  font-family: \"Roboto-Bold\";\n  font-size: 24px;\n  text-align: left;\n  margin: 0 0 20px 0;\n"], ["\n  width: 100%;\n  height: fit-content;\n  color: ", ";\n  font-family: \"Roboto-Bold\";\n  font-size: 24px;\n  text-align: left;\n  margin: 0 0 20px 0;\n"])), function (props) { var _a; return (_a = props.theme) === null || _a === void 0 ? void 0 : _a.fonts.secondary; });
var InputTitle = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n  height: fit-content;\n"], ["\n  width: 100%;\n  height: fit-content;\n"])));
var InputDescription = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  height: fit-content;\n  margin-top: 19px;\n"], ["\n  width: 100%;\n  height: fit-content;\n  margin-top: 19px;\n"])));
export { EditInformation, Title, InputTitle, InputDescription };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
