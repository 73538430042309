var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var Table = styled.table(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  user-select: none;\n  border-collapse: collapse;\n  width: 100%;\n  font-family: \"Roboto\";\n  font-size: 0.8rem;\n"], ["\n  user-select: none;\n  border-collapse: collapse;\n  width: 100%;\n  font-family: \"Roboto\";\n  font-size: 0.8rem;\n"])));
export var THead = styled.thead(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: sticky;\n  top: 0;\n  z-index: 2;\n"], ["\n  position: sticky;\n  top: 0;\n  z-index: 2;\n"])));
export var THeader = styled.th(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  left: 0;\n  top: 0;\n  z-index: 1;\n  background-color: white;\n  font-family: \"Roboto-Bold\";\n  min-width: 100px;\n  padding: 20px 10px;\n"], ["\n  left: 0;\n  top: 0;\n  z-index: 1;\n  background-color: white;\n  font-family: \"Roboto-Bold\";\n  min-width: 100px;\n  padding: 20px 10px;\n"])));
export var ColumnHeader = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  height: 30px;\n  align-items: center;\n  justify-content: start;\n"], ["\n  display: flex;\n  flex-direction: column;\n  height: 30px;\n  align-items: center;\n  justify-content: start;\n"])));
export var HeaderTitle = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  > svg {\n    margin-left: 5px;\n    color: ", ";\n  }\n"], ["\n  > svg {\n    margin-left: 5px;\n    color: ", ";\n  }\n"])), function (props) { var _a, _b; return (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.colors) === null || _b === void 0 ? void 0 : _b.grayadb; });
export var SelectionColumn = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  min-width: 50px;\n  align-items: center;\n  justify-content: center;\n"], ["\n  display: flex;\n  min-width: 50px;\n  align-items: center;\n  justify-content: center;\n"])));
export var TBody = styled.tbody(templateObject_7 || (templateObject_7 = __makeTemplateObject([""], [""])));
export var TRow = styled.tr(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  border-bottom: 1px solid ", ";\n  &:nth-child(even) {\n    background-color: #fafafa;\n  }\n"], ["\n  border-bottom: 1px solid ", ";\n  &:nth-child(even) {\n    background-color: #fafafa;\n  }\n"])), function (props) { var _a, _b; return (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.colors) === null || _b === void 0 ? void 0 : _b.grayced4; });
export var TCell = styled.td(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  user-select: none;\n  padding: 30px 5px;\n  text-align: center;\n"], ["\n  user-select: none;\n  padding: 30px 5px;\n  text-align: center;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
