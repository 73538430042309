var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { Image } from "components";
import Gallery from "./styled";
var ImageGallery = function (props) {
    var images = props.images, rest = __rest(props, ["images"]);
    var imageList = images.map(function (image, index) {
        return image.image && (React.createElement(Gallery.ImageContainer, { key: "img-" + index },
            React.createElement(Image, { src: image.image }),
            image.description && (React.createElement(Gallery.DescriptionImage, null, image.description))));
    });
    return React.createElement(Gallery, null, imageList);
};
export { ImageGallery };
export default ImageGallery;
