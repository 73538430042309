var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
var Alert = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-items: center;\n  background-color: ", ";\n  border-radius: 5px;\n  font-family: ", ";\n  color: ", ";\n  display: flex;\n  flex-direction: row;\n  font-size: 14px;\n  justify-content: center;\n  margin: 0 auto;\n  padding: 10px;\n  width: 90%;\n"], ["\n  align-items: center;\n  background-color: ", ";\n  border-radius: 5px;\n  font-family: ", ";\n  color: ", ";\n  display: flex;\n  flex-direction: row;\n  font-size: 14px;\n  justify-content: center;\n  margin: 0 auto;\n  padding: 10px;\n  width: 90%;\n"])), function (props) {
    return props.type === "success"
        ? props.theme.colors.greenDEF
        : props.type === "danger"
            ? props.theme.colors.redFFD
            : props.theme.colors.yellowFFF;
}, function (props) { return props.theme.fontFamily.primary; }, function (props) {
    return props.type === "success"
        ? props.theme.colors.green219
        : props.type === "danger"
            ? props.theme.colors.red9E0
            : props.theme.colors.yellowAE7;
});
Alert.Icon = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  flex: 0 0 auto;\n"], ["\n  flex: 0 0 auto;\n"])));
Alert.Message = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  flex: 1 0 auto;\n  letter-spacing: 1.5px;\n  padding: 0 10px;\n"], ["\n  flex: 1 0 auto;\n  letter-spacing: 1.5px;\n  padding: 0 10px;\n"])));
Alert.Close = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n  cursor: pointer;\n  flex: 0 0 3%;\n"], ["\n  color: ", ";\n  cursor: pointer;\n  flex: 0 0 3%;\n"])), function (props) { return props.theme.colors.gray; });
export default Alert;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
