import React, { useRef } from "react";
import CustomFileInput from "./styled";
var Component = function (props) {
    var id = props.id, multiple = props.multiple, accept = props.accept, onChange = props.onChange, size = props.size;
    var inputFileRef = useRef(null);
    var onClick = function () {
        var _a;
        (_a = inputFileRef === null || inputFileRef === void 0 ? void 0 : inputFileRef.current) === null || _a === void 0 ? void 0 : _a.click();
    };
    return (React.createElement(CustomFileInput, { size: size, multiple: multiple },
        React.createElement(CustomFileInput.Image, { onClick: onClick }),
        React.createElement(CustomFileInput.File, { type: "file", id: id, multiple: multiple, accept: accept, ref: inputFileRef, onChange: function (event) {
                onChange(event.target.files);
            } })));
};
export { Component as CustomFileInput };
export default Component;
