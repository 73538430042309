var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import has from "lodash/has";
import { useFormContext } from "react-hook-form";
import AttachImage from "./styled";
import { CustomFileInput, ImageEditable } from "components";
var Component = function (props) {
    var fieldName = props.fieldName, _a = props.size, size = _a === void 0 ? "default" : _a;
    var _b = __read(useState(undefined), 2), itemsToRender = _b[0], setItemsToRender = _b[1];
    var _c = useFormContext(), setValue = _c.setValue, watch = _c.watch;
    var files = watch(fieldName);
    var isNeededRenderFileInput = isEmpty(files);
    useEffect(function () {
        renderImageSelected();
    }, [files]);
    var onFileSelected = function (newFile) {
        if (newFile) {
            setValue(fieldName, __spreadArray([], __read(newFile), false));
        }
    };
    var onDelete = function () {
        setValue(fieldName, null);
    };
    var renderImageSelected = function () {
        var _itemsToRender = files === null || files === void 0 ? void 0 : files.map(function (file, index) {
            if (has(file, "id")) {
                var _file = file;
                return (React.createElement(ImageEditable, { id: _file.id, key: _file.id, src: _file.thumbnail, size: size, onDelete: onDelete }));
            }
            var result = URL.createObjectURL(file);
            return (React.createElement(ImageEditable, { id: index, key: fieldName, src: result, size: size, onDelete: onDelete }));
        });
        setItemsToRender(_itemsToRender);
    };
    var renderFileInput = isNeededRenderFileInput && (React.createElement(CustomFileInput, { id: "banner", size: size, accept: ".jpg, .JPG, .jpeg, .JPEG, .png, .PNG", onChange: onFileSelected }));
    return (React.createElement(AttachImage, null,
        itemsToRender,
        renderFileInput));
};
export { Component as SelectImage };
export default Component;
