import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import icons from "assets/icons";
import { AmenitySelectorContainer, SelectContainer, DeleteContainer, } from "./styled";
import { Dropdown, SubSegment } from "components";
export var Component = function (props) {
    var _a;
    var index = props.index, onChange = props.onChange, onRemove = props.onRemove, _b = props.amenities, amenities = _b === void 0 ? [] : _b, _c = props.amenitySelected, amenitySelected = _c === void 0 ? null : _c, _d = props.readonly, readonly = _d === void 0 ? false : _d;
    var selectedItem = amenitySelected
        ? {
            id: (_a = amenitySelected === null || amenitySelected === void 0 ? void 0 : amenitySelected.id) !== null && _a !== void 0 ? _a : "",
            text: amenitySelected === null || amenitySelected === void 0 ? void 0 : amenitySelected.name,
            image: icons[amenitySelected === null || amenitySelected === void 0 ? void 0 : amenitySelected.iconId],
        }
        : null;
    var formatAmenities = amenities.map(function (amenity) {
        var id = amenity.id, name = amenity.name, iconId = amenity.iconId;
        var newId = id !== null && id !== void 0 ? id : "";
        return {
            id: newId,
            text: name,
            image: icons[iconId],
        };
    });
    var onChangeItem = function (item) {
        var id = item.id;
        var amenitySelected = amenities.filter(function (amenity) { return amenity.id === id; });
        onChange(index, amenitySelected[0]);
    };
    var onRemoveItem = function () {
        onRemove(index);
    };
    return (React.createElement(AmenitySelectorContainer, null,
        React.createElement(SubSegment, null,
            React.createElement(SelectContainer, null,
                React.createElement(Dropdown, { isImg: true, selectedItem: selectedItem, options: formatAmenities, onChange: onChangeItem, readonly: readonly })),
            React.createElement(DeleteContainer, { onClick: onRemoveItem },
                React.createElement(FontAwesomeIcon, { icon: faTrash }),
                " Eliminar"))));
};
export { Component as AmenitySelector };
export default Component;
