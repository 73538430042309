var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useMemo } from "react";
import { StoreProvider } from "easy-peasy";
import { ThemeProvider } from "styled-components";
import store from "state/store";
import { checkBearerToken } from "utils/checkBearerToken";
import { Routes } from "views";
import { theme } from "style/theme";
import GlobalStyle from "style/Global";
import FontsProvider from "style/Fonts";
import Context from "./Context";
import { useLocalStorage } from "hooks/useLocalStorage";
checkBearerToken(store);
import NotificationProvider from "common/providers/NotificationProvider";
import { Toast } from "components";
export var App = function () {
    var _a = __read(useLocalStorage("language", "spanish"), 2), language = _a[0], setLanguage = _a[1];
    var value = useMemo(function () {
        var isEnglish = language === "english";
        var isSpanish = !isEnglish;
        return { language: language, setLanguage: setLanguage, isEnglish: isEnglish, isSpanish: isSpanish };
    }, [language]);
    return (React.createElement(StoreProvider, { store: store },
        React.createElement(ThemeProvider, { theme: theme },
            React.createElement(NotificationProvider, null,
                React.createElement(GlobalStyle, null),
                React.createElement(FontsProvider, null),
                React.createElement(Toast, null),
                React.createElement(Context.Provider, { value: value },
                    React.createElement(Routes, null))))));
};
