var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { createGlobalStyle } from "styled-components";
import regularRobotoFont from "style/Fonts/Roboto/Roboto-Regular.ttf";
import mediumRobotoFont from "style/Fonts/Roboto/Roboto-Medium.ttf";
import boldRobotoFont from "style/Fonts/Roboto/Roboto-Bold.ttf";
import boldSoraFont from "style/Fonts/Sora/static/Sora-Bold.ttf";
var FontsProvider = createGlobalStyle(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n@font-face {\n  font-family: 'Roboto';\n  src: url(", ") format('truetype');\n}\n@font-face {\n  font-family: \"Roboto-Medium\";\n  src: url(", ") format('truetype');\n}\n@font-face {\n  font-family: \"Roboto-Bold\";\n  src: url(", ") format('truetype');\n}\n@font-face {\n  font-family: \"Sora-Bold\";\n  src: url(", ") format('truetype');\n}\n"], ["\n@font-face {\n  font-family: 'Roboto';\n  src: url(", ") format('truetype');\n}\n@font-face {\n  font-family: \"Roboto-Medium\";\n  src: url(", ") format('truetype');\n}\n@font-face {\n  font-family: \"Roboto-Bold\";\n  src: url(", ") format('truetype');\n}\n@font-face {\n  font-family: \"Sora-Bold\";\n  src: url(", ") format('truetype');\n}\n"])), regularRobotoFont, mediumRobotoFont, boldRobotoFont, boldSoraFont);
export default FontsProvider;
var templateObject_1;
