var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
var Row = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 20px;\n  width: 100%;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 20px;\n  width: 100%;\n"])));
var SmallColumn = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 40%;\n"], ["\n  width: 40%;\n"])));
var LargeColumn = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 58%;\n"], ["\n  width: 58%;\n"])));
var Column = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 50%;\n"], ["\n  width: 50%;\n"])));
export { Row, Column, SmallColumn, LargeColumn };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
