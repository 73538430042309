var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
var Confirm = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  text-align: center;\n"], ["\n  text-align: center;\n"])));
Confirm.Text = styled.h5(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  letter-spacing: 2px;\n  font-family: ", ";\n"], ["\n  letter-spacing: 2px;\n  font-family: ", ";\n"])), function (props) { return props.theme.fontFamily.primary; });
Confirm.Buttons = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  justify-content: center;\n  margin-top: 24px;\n"], ["\n  align-items: center;\n  display: flex;\n  justify-content: center;\n  margin-top: 24px;\n"])));
Confirm.Delete = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-left: 24px;\n  max-width: 202px;\n  width: 100%;\n"], ["\n  margin-left: 24px;\n  max-width: 202px;\n  width: 100%;\n"])));
Confirm.Cancel = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 140px;\n"], ["\n  width: 140px;\n"])));
export default Confirm;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
