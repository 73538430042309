import Context from "app/Context";
import { BorderedButton } from "components/Button/variants";
import React, { useContext } from "react";
import { Wrapper } from "./styled";
function Component() {
    var _a = useContext(Context), setLanguage = _a.setLanguage, isEnglish = _a.isEnglish;
    var handleClick = function () {
        !isEnglish ? setLanguage("english") : setLanguage("spanish");
    };
    var title = isEnglish ? "Español" : "English";
    return (React.createElement(Wrapper, null,
        React.createElement(BorderedButton, { children: title, onClick: handleClick })));
}
export default Component;
export { Component as SwitchLanguage };
