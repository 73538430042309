var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import { mobileDevice } from "style/constants";
var DateRange = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  min-width: 320px;\n  width: 100%;\n  @media ", " {\n    min-width: 0;\n  }\n"], ["\n  min-width: 320px;\n  width: 100%;\n  @media ", " {\n    min-width: 0;\n  }\n"])), mobileDevice);
DateRange.InputsContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  justify-content: space-around;\n  > div {\n    flex: 1;\n    padding: 5px;\n  }\n"], ["\n  display: flex;\n  width: 100%;\n  justify-content: space-around;\n  > div {\n    flex: 1;\n    padding: 5px;\n  }\n"])));
DateRange.CalendarContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background-color: #fff;\n  border: ", ";\n  width: fit-content;\n  padding: 40px 0;\n  margin: 0 auto;\n  border-radius: 5px;\n  @media ", " {\n    max-width: 320px;\n    padding: 30px 30px;\n    z-index: 99999;\n  }\n"], ["\n  background-color: #fff;\n  border: ", ";\n  width: fit-content;\n  padding: 40px 0;\n  margin: 0 auto;\n  border-radius: 5px;\n  @media ", " {\n    max-width: 320px;\n    padding: 30px 30px;\n    z-index: 99999;\n  }\n"])), function (props) { var _a, _b; return "solid 1px " + ((_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.colors) === null || _b === void 0 ? void 0 : _b.gray); }, mobileDevice);
export default DateRange;
var templateObject_1, templateObject_2, templateObject_3;
