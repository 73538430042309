import React from "react";
import { HeaderImage } from "./styled";
import { Image } from "components";
var Component = function (props) {
    var image = props.image;
    return (React.createElement(HeaderImage, null,
        React.createElement(Image, { src: image })));
};
export default Component;
export { Component as HeaderImage };
