import React, { useContext } from "react";
import { getMonthYear } from "utils/dates";
import { faChevronRight, faChevronLeft, } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Navbar from "./styled";
import Context from "app/Context";
var Component = function (props) {
    var nextMonth = props.nextMonth, previousMonth = props.previousMonth, month = props.month, onPreviousClick = props.onPreviousClick, onNextClick = props.onNextClick, className = props.className, localeUtils = props.localeUtils;
    var isEnglish = useContext(Context).isEnglish;
    return (React.createElement(Navbar, null,
        React.createElement(Navbar.ArrowLeft, null,
            React.createElement(FontAwesomeIcon, { icon: faChevronLeft, onClick: function () { return onPreviousClick(); } })),
        React.createElement(Navbar.Caption, null, getMonthYear(month, isEnglish)),
        React.createElement(Navbar.ArrowRight, null,
            React.createElement(FontAwesomeIcon, { icon: faChevronRight, onClick: function () { return onNextClick(); } }))));
};
export default Component;
