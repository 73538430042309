var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from "react";
import { useFormContext, useFieldArray } from "react-hook-form";
import { has, isEmpty } from "lodash";
import { CustomFileInput, ImageEditable } from "components";
import { ImagesContainer, Container } from "./styled";
var Component = function (props) {
    var fieldName = props.fieldName, _a = props.size, size = _a === void 0 ? "default" : _a, _b = props.isSelectable, isSelectable = _b === void 0 ? false : _b;
    var _c = useFormContext(), control = _c.control, setValue = _c.setValue, watch = _c.watch;
    var _d = useFieldArray({
        control: control,
        name: fieldName,
        keyName: "key",
    }), files = _d.fields, append = _d.append, remove = _d.remove, update = _d.update;
    var mainPictureIdFromForm = watch("mainPictureId");
    var _e = __read(useState(undefined), 2), itemsToRender = _e[0], setItemsToRender = _e[1];
    var _f = __read(useState(0), 2), mainPictureId = _f[0], setMainPictureId = _f[1];
    useEffect(function () {
        setMainPictureId(mainPictureIdFromForm);
        generateItems();
    }, [files]);
    useEffect(function () {
        setValue("mainPictureId", mainPictureId);
        generateItems();
    }, [mainPictureId]);
    var totalUploadedFiles = files.filter(function (file) { return !file.isNew; }).length;
    var onDelete = function (item, index) {
        if (item.isNew) {
            remove(index);
        }
        else if (!item.toDelete) {
            update(index, __assign(__assign({}, item), { toDelete: true }));
        }
        else {
            update(index, __assign(__assign({}, item), { toDelete: false }));
        }
    };
    var onFileSelected = function (newFiles) {
        if (newFiles) {
            Array.from(newFiles).forEach(function (newfile) {
                append({
                    title: "",
                    englishTitle: "",
                    description: "",
                    englishDescription: "",
                    page: "",
                    type: "",
                    picture: [newfile],
                    isNew: true,
                });
            });
        }
    };
    var onMainSelect = function (id) {
        var mainImage = id;
        if (typeof id === "number")
            mainImage = id - totalUploadedFiles;
        setMainPictureId(mainImage);
    };
    var generateItems = function () {
        var _itemsToRender = files === null || files === void 0 ? void 0 : files.map(function (file, index) {
            if (!has(file, "isNew") &&
                (!isEmpty(file.picture) || !isEmpty(file.url))) {
                var _file = !isEmpty(file.picture)
                    ? file.picture[0]
                    : file;
                var selected_1 = mainPictureId === _file.id;
                return (React.createElement(ImageEditable, { id: _file.id, key: fieldName + "." + index, multiple: true, src: _file.thumbnail, size: size, isSelectable: isSelectable, onMainSelect: onMainSelect, isMainPicture: selected_1, isDeleted: file.toDelete, onDelete: function () { return onDelete(file, index); }, onRestore: function () { return onDelete(file, index); } }));
            }
            var result = file.picture && URL.createObjectURL(file.picture[0]);
            var selected = mainPictureId === index - totalUploadedFiles;
            return (React.createElement(ImageEditable, { id: index, key: fieldName + "." + index, multiple: true, src: result, size: size, isSelectable: isSelectable, onMainSelect: onMainSelect, isMainPicture: selected, isDeleted: file.toDelete, onDelete: function () { return onDelete(file, index); } }));
        });
        setItemsToRender(_itemsToRender);
    };
    return (React.createElement(Container, null,
        React.createElement(ImagesContainer, null,
            itemsToRender,
            React.createElement(CustomFileInput, { id: "banner", size: size, multiple: true, accept: ".jpg, .JPG, .jpeg, .JPEG, .png, .PNG", onChange: onFileSelected }))));
};
export { Component as SelectImages };
export default Component;
