import React from "react";
import { Router } from "@reach/router";
import { useStoreState } from "state/hooks";
import PrivateRoute from "utils/PrivateRoute";
import { TwoColumnsLayout, LeftColumn, RightColumn } from "components/Layout";
import { AdminMenu } from "components";
import { Profile } from "./Profile";
import { Home } from "./Home";
import { Bungalows } from "./Bungalows";
import { Rules } from "./Rules";
import { EditProfile } from "./EditProfile";
import { ChangePassword } from "./ChangePassword";
import { Reservations } from "./Reservations";
import { TerracePool } from "./TerracePool";
import { BeachClub } from "./BeachClub";
import { Activities } from "./Activities";
import { Ubication } from "./Ubication";
import { Users } from "./Users";
import { Config } from "./Config";
import { EditUser } from "./EditUser";
import { Amenities } from "./Amenities";
var Admin = function (props) {
    var parentPath = "/admin";
    var _a = useStoreState(function (store) { return store.auth; }).data, name = _a.name, lastName = _a.lastName;
    return (React.createElement(TwoColumnsLayout, null,
        React.createElement(LeftColumn, null,
            React.createElement(AdminMenu, { parentPath: parentPath, name: "Cesar Buenrostro" })),
        React.createElement(RightColumn, null,
            React.createElement(Router, null,
                React.createElement(PrivateRoute, { default: true, path: "perfil", component: Profile }),
                React.createElement(PrivateRoute, { path: "perfil/editar", component: EditProfile }),
                React.createElement(PrivateRoute, { path: "perfil/cambiar-contrasena", component: ChangePassword }),
                React.createElement(PrivateRoute, { path: "inicio", component: Home }),
                React.createElement(PrivateRoute, { path: "bungalows", component: Bungalows }),
                React.createElement(PrivateRoute, { path: "reglas", component: Rules }),
                React.createElement(PrivateRoute, { path: "terraza-y-alberca", component: TerracePool }),
                React.createElement(PrivateRoute, { path: "club-de-playa", component: BeachClub }),
                React.createElement(PrivateRoute, { path: "actividades", component: Activities }),
                React.createElement(PrivateRoute, { path: "contacto", component: Ubication }),
                React.createElement(PrivateRoute, { path: "reservaciones", component: Reservations }),
                React.createElement(PrivateRoute, { path: "configuracion", component: Config }),
                React.createElement(PrivateRoute, { path: "usuarios", component: Users }),
                React.createElement(PrivateRoute, { path: "usuarios/editar", component: EditUser }),
                React.createElement(PrivateRoute, { path: "amenidades", component: Amenities })))));
};
export { Admin };
export default Admin;
