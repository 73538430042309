var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { useFormContext } from "react-hook-form";
import { faTrash, faUndo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CustomInput, SubSegment } from "components";
import { MainContainer, InputName, InputUrl, TrashIcon } from "./styled";
var Component = function (props) {
    var index = props.index, calendar = props.calendar, removeCalendar = props.removeCalendar;
    var register = useFormContext().register;
    return (React.createElement(MainContainer, null,
        React.createElement(SubSegment, { restore: calendar.toDelete },
            React.createElement(InputName, null,
                React.createElement("input", __assign({ type: "hidden" }, register("calendars." + index + ".id"))),
                React.createElement(CustomInput, __assign({ label: "Nombre de calendario", placeholder: "Agrega el nombre" }, register("calendars." + index + ".name")))),
            React.createElement(InputUrl, null,
                React.createElement(CustomInput, __assign({ label: "URL de calendario", placeholder: "Agrega la URL" }, register("calendars." + index + ".url")))),
            React.createElement(TrashIcon, { onClick: removeCalendar },
                React.createElement(FontAwesomeIcon, { icon: calendar.toDelete ? faUndo : faTrash }),
                " ",
                calendar.toDelete ? "Restaurar" : "Eliminar"))));
};
export default Component;
export { Component as CalendarsEditor };
