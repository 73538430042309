var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { mobileDevice } from "style/constants";
import styled from "styled-components";
export var PageInfo = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: grid;\n  width: 100%;\n  height: fit-content;\n  grid-template:\n    \"image\" ", "\n    \"title\" 50px\n    \"description\" 80px\n    /100%;\n  margin: 48px 0 60px;\n"], ["\n  display: grid;\n  width: 100%;\n  height: fit-content;\n  grid-template:\n    \"image\" ", "\n    \"title\" 50px\n    \"description\" 80px\n    /100%;\n  margin: 48px 0 60px;\n"])), function (props) { return (props.isRequiredImage ? "130px" : "0px"); });
export var ImageContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 130px;\n  height: 130px;\n  grid-area: image;\n  justify-self: center;\n  height: 100%;\n  margin-bottom: 32px;\n  @media ", " {\n    margin-bottom: 21px;\n    height: 100px;\n    width: 100px;\n  }\n"], ["\n  width: 130px;\n  height: 130px;\n  grid-area: image;\n  justify-self: center;\n  height: 100%;\n  margin-bottom: 32px;\n  @media ", " {\n    margin-bottom: 21px;\n    height: 100px;\n    width: 100px;\n  }\n"])), mobileDevice);
export var TitleContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  grid-area: title;\n  margin-bottom: 16px;\n  justify-self: center;\n  @media ", " {\n    margin-bottom: 8px;\n  }\n"], ["\n  grid-area: title;\n  margin-bottom: 16px;\n  justify-self: center;\n  @media ", " {\n    margin-bottom: 8px;\n  }\n"])), mobileDevice);
export var Description = styled.p(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 70%;\n  max-width: 700px;\n  grid-area: description;\n  font-size: 18px;\n  font-family: \"Roboto\";\n  color: ", ";\n  justify-self: center;\n  text-align: center;\n  margin: 0;\n  @media ", " {\n    width: 90%;\n    font-size: 14px;\n    margin: 0px 16px;\n  }\n"], ["\n  width: 70%;\n  max-width: 700px;\n  grid-area: description;\n  font-size: 18px;\n  font-family: \"Roboto\";\n  color: ", ";\n  justify-self: center;\n  text-align: center;\n  margin: 0;\n  @media ", " {\n    width: 90%;\n    font-size: 14px;\n    margin: 0px 16px;\n  }\n"])), function (props) { var _a, _b; return (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.fonts) === null || _b === void 0 ? void 0 : _b.secondary; }, mobileDevice);
export default PageInfo;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
