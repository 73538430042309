var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import { mobileDevice } from "style/constants";
var Bungalow = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  border-radius: 10px;\n  box-shadow: 1px 0.5px 1px 1px rgba(109, 108, 108, 0.425);\n  display: grid;\n  grid-template:\n    \"image title button\" 20%\n    \"image features price\" 60%\n    \"image amenities link\" 20%\n    /35% 40% 25%;\n  margin: 20px auto;\n  min-height: 250px;\n  width: 100%;\n\n  @media ", " {\n    width: 90%;\n    height: fit-content;\n    grid-template:\n      \"image image\" 190px\n      \"title price\" auto\n      \"button button\" auto\n      \"features features\" auto\n      \"amenities amenities\" auto\n      \"link link\" auto\n      / 50% 50%;\n    padding: 16px;\n    box-shadow: none;\n  }\n"], ["\n  background-color: ", ";\n  border-radius: 10px;\n  box-shadow: 1px 0.5px 1px 1px rgba(109, 108, 108, 0.425);\n  display: grid;\n  grid-template:\n    \"image title button\" 20%\n    \"image features price\" 60%\n    \"image amenities link\" 20%\n    /35% 40% 25%;\n  margin: 20px auto;\n  min-height: 250px;\n  width: 100%;\n\n  @media ", " {\n    width: 90%;\n    height: fit-content;\n    grid-template:\n      \"image image\" 190px\n      \"title price\" auto\n      \"button button\" auto\n      \"features features\" auto\n      \"amenities amenities\" auto\n      \"link link\" auto\n      / 50% 50%;\n    padding: 16px;\n    box-shadow: none;\n  }\n"])), function (props) { var _a, _b; return (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.colors) === null || _b === void 0 ? void 0 : _b.white; }, mobileDevice);
Bungalow.ImageContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 90%;\n  height: 85%;\n  grid-column: 1/2;\n  grid-row: 1/4;\n  margin: 24px 0 24px 26px;\n  @media ", " {\n    width: 100%;\n    height: 100%;\n    grid-area: image;\n    margin: 0 0 0 0px;\n    justify-self: center;\n  }\n"], ["\n  width: 90%;\n  height: 85%;\n  grid-column: 1/2;\n  grid-row: 1/4;\n  margin: 24px 0 24px 26px;\n  @media ", " {\n    width: 100%;\n    height: 100%;\n    grid-area: image;\n    margin: 0 0 0 0px;\n    justify-self: center;\n  }\n"])), mobileDevice);
Bungalow.Title = styled.h2(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  grid-column: 2/3;\n  grid-row: 1/2;\n  color: ", ";\n  margin: 24px 0 26px 18px;\n  font-size: 24px;\n  font-weight: bold;\n  font-family: \"Sora-Bold\";\n  @media ", " {\n    height: auto;\n    min-height: 70px;\n    width: 100%;\n    grid-area: title;\n    margin: 0 0 0 0;\n    padding-top: 16px;\n    font-size: 18px;\n    font-weight: bold;\n  }\n"], ["\n  grid-column: 2/3;\n  grid-row: 1/2;\n  color: ", ";\n  margin: 24px 0 26px 18px;\n  font-size: 24px;\n  font-weight: bold;\n  font-family: \"Sora-Bold\";\n  @media ", " {\n    height: auto;\n    min-height: 70px;\n    width: 100%;\n    grid-area: title;\n    margin: 0 0 0 0;\n    padding-top: 16px;\n    font-size: 18px;\n    font-weight: bold;\n  }\n"])), function (props) { var _a, _b; return (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.fonts) === null || _b === void 0 ? void 0 : _b.secondary; }, mobileDevice);
Bungalow.Features = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  height: 50%;\n  flex-wrap: wrap;\n  grid-row: 2/3;\n  grid-column: 2/3;\n  align-self: start;\n  padding-left: 18px;\n  @media ", " {\n    display: flex;\n    grid-area: features;\n    flex-wrap: wrap;\n    align-self: start;\n    margin: 0;\n    padding: 17px 0;\n    border-top: 0.5px solid;\n    border-color: ", ";\n    padding-left: 0;\n  }\n"], ["\n  display: flex;\n  height: 50%;\n  flex-wrap: wrap;\n  grid-row: 2/3;\n  grid-column: 2/3;\n  align-self: start;\n  padding-left: 18px;\n  @media ", " {\n    display: flex;\n    grid-area: features;\n    flex-wrap: wrap;\n    align-self: start;\n    margin: 0;\n    padding: 17px 0;\n    border-top: 0.5px solid;\n    border-color: ", ";\n    padding-left: 0;\n  }\n"])), mobileDevice, function (props) { var _a, _b; return (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.colors) === null || _b === void 0 ? void 0 : _b.grayced4; });
Bungalow.Amenities = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  grid-row: 3/4;\n  grid-column: 2/3;\n  align-self: end;\n  margin-bottom: 24px;\n  padding-left: 18px;\n  @media ", " {\n    display: flex;\n    width: 100%;\n    grid-area: amenities;\n    flex-wrap: wrap;\n    align-self: start;\n    padding: 0;\n    margin: 0;\n    > div {\n      display: flex;\n      flex-wrap: wrap;\n      > div {\n        margin-bottom: 0.5rem;\n      }\n    }\n  }\n"], ["\n  grid-row: 3/4;\n  grid-column: 2/3;\n  align-self: end;\n  margin-bottom: 24px;\n  padding-left: 18px;\n  @media ", " {\n    display: flex;\n    width: 100%;\n    grid-area: amenities;\n    flex-wrap: wrap;\n    align-self: start;\n    padding: 0;\n    margin: 0;\n    > div {\n      display: flex;\n      flex-wrap: wrap;\n      > div {\n        margin-bottom: 0.5rem;\n      }\n    }\n  }\n"])), mobileDevice);
Bungalow.Button = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  align-self: center;\n  grid-row: 1/2;\n  grid-column: 3/4;\n  grid-area: 1/3/2/4;\n  height: 50%;\n  margin: 0 auto;\n  padding: 0 15%;\n  width: 70%;\n\n  @media ", " {\n    width: 90%;\n    height: 48px;\n    grid-area: button;\n    align-self: end;\n    justify-self: center;\n    margin: 0 0 25px 0;\n    > button {\n      width: 100%;\n    }\n  }\n"], ["\n  align-self: center;\n  grid-row: 1/2;\n  grid-column: 3/4;\n  grid-area: 1/3/2/4;\n  height: 50%;\n  margin: 0 auto;\n  padding: 0 15%;\n  width: 70%;\n\n  @media ", " {\n    width: 90%;\n    height: 48px;\n    grid-area: button;\n    align-self: end;\n    justify-self: center;\n    margin: 0 0 25px 0;\n    > button {\n      width: 100%;\n    }\n  }\n"])), mobileDevice);
Bungalow.PriceContainer = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  height: 90%;\n  grid-row: 2/3;\n  grid-column: 3/4;\n  margin: 45px 0rem 0rem 1rem;\n  padding-left: 1rem;\n  align-self: center;\n  border-left: 0.5px solid;\n  border-color: ", ";\n  @media ", " {\n    width: 100%;\n    height: fit-content;\n    display: flex;\n    grid-area: price;\n    border-left: 0;\n    margin: 0;\n    align-self: flex-start;\n    font-size: 14px;\n    flex-wrap: wrap;\n  }\n"], ["\n  height: 90%;\n  grid-row: 2/3;\n  grid-column: 3/4;\n  margin: 45px 0rem 0rem 1rem;\n  padding-left: 1rem;\n  align-self: center;\n  border-left: 0.5px solid;\n  border-color: ", ";\n  @media ", " {\n    width: 100%;\n    height: fit-content;\n    display: flex;\n    grid-area: price;\n    border-left: 0;\n    margin: 0;\n    align-self: flex-start;\n    font-size: 14px;\n    flex-wrap: wrap;\n  }\n"])), function (props) { var _a, _b; return (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.colors) === null || _b === void 0 ? void 0 : _b.grayced4; }, mobileDevice);
Bungalow.Anchor = styled.a(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  height: 19px;\n  grid-row: 3/4;\n  grid-column: 3/4;\n  justify-self: center;\n  align-self: end;\n  margin: 0 0 24px 0;\n  text-decoration: none;\n  cursor: pointer;\n  color: ", ";\n  font-size: 16px;\n  font-family: \"Roboto\";\n  @media ", " {\n    grid-area: link;\n    justify-self: end;\n    align-self: end;\n    margin: 16px 0;\n  }\n"], ["\n  height: 19px;\n  grid-row: 3/4;\n  grid-column: 3/4;\n  justify-self: center;\n  align-self: end;\n  margin: 0 0 24px 0;\n  text-decoration: none;\n  cursor: pointer;\n  color: ", ";\n  font-size: 16px;\n  font-family: \"Roboto\";\n  @media ", " {\n    grid-area: link;\n    justify-self: end;\n    align-self: end;\n    margin: 16px 0;\n  }\n"])), function (props) { var _a; return "0px 3px 8px 0px" + ((_a = props.theme) === null || _a === void 0 ? void 0 : _a.blue00c5); }, mobileDevice);
export default Bungalow;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
