var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
var Feature = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  height: 32px;\n  background-color: ", ";\n  border-radius: 4px;\n  padding: 2px 8px 2px 8px;\n  margin: 10px 16px 0 0;\n  align-items: center;\n  cursor: pointer;\n"], ["\n  display: flex;\n  height: 32px;\n  background-color: ", ";\n  border-radius: 4px;\n  padding: 2px 8px 2px 8px;\n  margin: 10px 16px 0 0;\n  align-items: center;\n  cursor: pointer;\n"])), function (props) { var _a, _b; return (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.colors) === null || _b === void 0 ? void 0 : _b.grayf8f; });
Feature.Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  flex-wrap: wrap;\n"], ["\n  display: flex;\n  flex: 1;\n  flex-wrap: wrap;\n"])));
Feature.Value = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 14px;\n  padding-right: 5px;\n  font-family: \"Roboto\";\n"], ["\n  color: ", ";\n  font-size: 14px;\n  padding-right: 5px;\n  font-family: \"Roboto\";\n"])), function (props) { var _a, _b; return "0px 3px 8px 0px" + ((_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.fonts) === null || _b === void 0 ? void 0 : _b.secondary); });
Feature.ClearButton = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 14px;\n"], ["\n  color: ", ";\n  font-size: 14px;\n"])), function (props) { var _a, _b; return (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.colors) === null || _b === void 0 ? void 0 : _b.gray; });
export default Feature;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
