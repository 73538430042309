var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import Component from "../";
import Styled from "../styled";
var InitialsInProfile = styled(Component)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", " {\n    background-color: ", ";\n    border: 7px solid ", ";\n    color: ", ";\n    padding: 10px;\n    margin-top: 2em;\n    font-size: 64px;\n    height: 124px;\n    width: 124px;\n  }\n"], ["\n  ", " {\n    background-color: ", ";\n    border: 7px solid ", ";\n    color: ", ";\n    padding: 10px;\n    margin-top: 2em;\n    font-size: 64px;\n    height: 124px;\n    width: 124px;\n  }\n"])), Styled.InitialName, function (props) { var _a; return (_a = props === null || props === void 0 ? void 0 : props.theme) === null || _a === void 0 ? void 0 : _a.colors.whitefff; }, function (props) { var _a; return (_a = props === null || props === void 0 ? void 0 : props.theme) === null || _a === void 0 ? void 0 : _a.colors.blue004e; }, function (props) { var _a; return (_a = props === null || props === void 0 ? void 0 : props.theme) === null || _a === void 0 ? void 0 : _a.colors.blue004e; });
export default InitialsInProfile;
var templateObject_1;
