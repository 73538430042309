import React from "react";
import { Router } from "@reach/router";
import { Public } from "./Public";
import { Admin } from "./Admin";
import { Signin } from "./Admin/Signin";
var Routes = function () {
    return (React.createElement(Router, null,
        React.createElement(Public, { path: "/*" }),
        React.createElement(Admin, { path: "admin/*" }),
        React.createElement(Signin, { path: "admin/iniciar-sesion" })));
};
export { Routes };
export default Routes;
