import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatPrice, generateChargeText } from "utils/bungalowFactory";
import { faArrowRight, faTimes } from "@fortawesome/free-solid-svg-icons";
import { BungalowContainer, Details, Icon, Price, Section, Title, Total, Text, Transaction, Line, RuleName, TransactionContainer, } from "./styled";
export var Component = function (props) {
    var id = props.id, name = props.name, charges = props.charges, transactions = props.transactions, total = props.total, _a = props.showDetails, showDetails = _a === void 0 ? false : _a, onClick = props.onClick, option = props.option;
    var iconToRender = showDetails ? faTimes : faArrowRight;
    var prevRuleName = "";
    var renderTransactions = transactions
        .filter(function (transaction) { return transaction.label !== "GuestRequired"; })
        .map(function (transaction, index) {
        var ruleName = transaction.ruleName, label = transaction.label, value = transaction.value, percent = transaction.percent, times = transaction.times, total = transaction.total;
        var text = generateChargeText(label, value, percent, times, total, "es");
        var isSameRule = prevRuleName !== ruleName;
        var line = isSameRule && prevRuleName !== "" && React.createElement(Line, null);
        var ruleNameToShow = (ruleName === null || ruleName === void 0 ? void 0 : ruleName.includes("Rule Base"))
            ? "Regular"
            : ruleName;
        var renderRuleName = (isSameRule || prevRuleName === "") && (React.createElement(RuleName, null,
            "Precio ",
            ruleNameToShow));
        prevRuleName = ruleName;
        return (React.createElement(TransactionContainer, { key: "transaction-" + index + "-" + option },
            line,
            renderRuleName,
            React.createElement(Transaction, null,
                React.createElement(Text, null, text),
                React.createElement(Price, { isDiscount: total < 0 }, formatPrice(total, "es")))));
    });
    var value = (charges === null || charges === void 0 ? void 0 : charges.filter(function (charge) { return charge.label === "CleanningCharge"; })[0]).value;
    var renderCharge = (React.createElement(Transaction, { key: "cleaning-charge}" },
        React.createElement(Text, null, "Tarifa de limpieza"),
        React.createElement(Price, { isDiscount: value < 0 }, formatPrice(value, "es"))));
    return (React.createElement(BungalowContainer, null,
        React.createElement(Section, null,
            React.createElement(Title, null, name),
            React.createElement(Total, null, formatPrice(total, "es")),
            React.createElement(Icon, { isOpen: showDetails, onClick: function () { return onClick(id); } },
                React.createElement(FontAwesomeIcon, { icon: iconToRender }))),
        React.createElement(Details, { isOpen: showDetails },
            renderTransactions,
            renderCharge)));
};
export default Component;
