import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "./styled";
var Component = function (props) {
    var children = props.children, open = props.open, onClose = props.onClose, _a = props.enableBackdropClick, enableBackdropClick = _a === void 0 ? false : _a, _b = props.enableCloseWithEsc, enableCloseWithEsc = _b === void 0 ? false : _b;
    var handleClose = function (event) {
        document.body.style.overflow = "unset";
        window.removeEventListener("keydown", handleKeydown);
        onClose(event);
    };
    var handleKeydown = function (event) {
        if (event.key === "Escape" && enableCloseWithEsc) {
            handleClose();
        }
    };
    useEffect(function () {
        if (open) {
            window.addEventListener("keydown", handleKeydown);
            document.body.style.overflow = "hidden";
        }
    }, [open]);
    if (!open) {
        return null;
    }
    return (React.createElement(Modal, { onClick: function (event) {
            if (enableBackdropClick) {
                onClose(event);
            }
        } },
        React.createElement(Modal.Wrapper, null,
            React.createElement(Modal.Controls, null,
                React.createElement(FontAwesomeIcon, { icon: faTimes, onClick: handleClose })),
            React.createElement(Modal.Content, null, children))));
};
export { Component as Modal };
export default Component;
