import React from "react";
import PersonCounter from "./styled";
import { IncrementDecrement } from "components/IncrementDecrement";
var Component = function (props) {
    var data = props.data, valueSelect = props.valueSelect, setValue = props.setValue;
    var title = data.title, description = data.description, _a = data.limit, limit = _a === void 0 ? 0 : _a;
    return (React.createElement(PersonCounter, null,
        React.createElement(PersonCounter.Title, null, title),
        React.createElement(PersonCounter.Description, null, description),
        React.createElement(PersonCounter.Select, null,
            React.createElement(IncrementDecrement, { limit: limit, valueSelect: valueSelect, setValueSelect: setValue }))));
};
export default Component;
export { Component as PersonCounter };
