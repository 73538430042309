var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from "react";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DropdownContainer, Header, HeaderContent, DropdownListContainer, DropdownList, ListItem, Icon, } from "./styled";
var Component = function (props) {
    var optionSelected = props.optionSelected, setOptionSelected = props.setOptionSelected, options = props.options;
    var _a = __read(useState(false), 2), isOpen = _a[0], setIsOpen = _a[1];
    var toggleDropdownListContainer = function (event) {
        event.stopPropagation();
        setIsOpen(!isOpen);
    };
    var selectOption = function (id) {
        setOptionSelected(options[id]);
        setIsOpen(false);
    };
    var renderOptions = function () {
        return options.map(function (option, index) { return (React.createElement(ListItem, { onClick: function () { return selectOption(index); } }, option.label)); });
    };
    var showDefault = optionSelected
        ? optionSelected === null || optionSelected === void 0 ? void 0 : optionSelected.short
        : "Selecciona una opcion";
    return (React.createElement(DropdownContainer, { isOpen: isOpen },
        React.createElement(Header, { onClick: toggleDropdownListContainer },
            React.createElement(HeaderContent, null,
                "Ordenar ",
                showDefault,
                React.createElement(Icon, { isOpen: isOpen },
                    React.createElement(FontAwesomeIcon, { icon: faCaretDown })))),
        isOpen && (React.createElement(DropdownListContainer, null,
            React.createElement(DropdownList, null, renderOptions())))));
};
export default Component;
export { Component as SortMenu };
