var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useContext } from "react";
import { useFormContext } from "react-hook-form";
import { useFieldArray } from "react-hook-form";
import { faTrash, faUndo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Segment, SubSegment, Label, TextButton, SelectImage, CustomInput, Title, } from "components";
import { Row, InputDescription, ImageEdit, InputTitle, InputLink, } from "./styled";
import Context from "app/Context";
var Component = function (props) {
    var _a = useContext(Context), isEnglish = _a.isEnglish, isSpanish = _a.isSpanish;
    var title = props.title, limit = props.limit;
    var _b = useFormContext(), register = _b.register, control = _b.control;
    var _c = useFieldArray({
        control: control,
        name: "servicesItems",
        keyName: "itemId",
    }), fields = _c.fields, append = _c.append, remove = _c.remove, update = _c.update;
    var addItem = function () {
        append({ title: "", href: "", description: "", picture: [], isNew: true });
    };
    var removeItem = function (item, index) {
        if (item.isNew) {
            remove(index);
        }
        else if (!item.toDelete) {
            update(index, __assign(__assign({}, item), { toDelete: true }));
        }
        else {
            update(index, __assign(__assign({}, item), { toDelete: false }));
        }
    };
    var renderItems = function () {
        return fields.map(function (item, index) {
            return (React.createElement(SubSegment, { key: "content-item-" + index, restore: item.toDelete },
                React.createElement(TextButton, { mode: "danger", onClick: function () { return removeItem(item, index); } },
                    React.createElement(FontAwesomeIcon, { icon: item.toDelete ? faUndo : faTrash }),
                    item.toDelete ? "Restaurar" : "Eliminar"),
                React.createElement(Row, { key: "item-" + index },
                    React.createElement(InputTitle, null,
                        isSpanish && (React.createElement(CustomInput, __assign({ label: "Título" }, register("servicesItems." + index + ".title")))),
                        isEnglish && (React.createElement(CustomInput, __assign({ label: "Title" }, register("servicesItems." + index + ".englishTitle"))))),
                    React.createElement(ImageEdit, null,
                        React.createElement(Label, null, "Imagen"),
                        React.createElement(SelectImage, { fieldName: "servicesItems." + index + ".picture" }))),
                React.createElement(InputDescription, null,
                    isSpanish && (React.createElement(CustomInput, __assign({ label: "Descripción" }, register("servicesItems." + index + ".description")))),
                    isEnglish && (React.createElement(CustomInput, __assign({ label: "Description" }, register("servicesItems." + index + ".englishDescription"))))),
                React.createElement(InputLink, null,
                    isSpanish && (React.createElement(CustomInput, __assign({ label: "Enlace" }, register("servicesItems." + index + ".href")))),
                    isEnglish && (React.createElement(CustomInput, __assign({ label: "Link" }, register("servicesItems." + index + ".href")))))));
        });
    };
    var limit_ = fields.length < limit;
    return (React.createElement(Segment, null,
        React.createElement(Title, { variant: "segment" }, title),
        renderItems(),
        limit_ && React.createElement(TextButton, { onClick: addItem }, "+ Agregar servicio")));
};
export default Component;
export { Component as ServicesEditor };
