export var getImageSize = function (size) {
    var height = "100px";
    switch (size) {
        case "medium":
            height = "150px";
            break;
        case "big":
            height = "250px";
            break;
        default:
            break;
    }
    return "" + height;
};
