var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
var MainContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  > div {\n    align-items: center;\n    box-sizing: border-box;\n    flex-direction: row;\n    justify-content: center;\n    min-height: 48px;\n    width: 100%;\n  }\n"], ["\n  > div {\n    align-items: center;\n    box-sizing: border-box;\n    flex-direction: row;\n    justify-content: center;\n    min-height: 48px;\n    width: 100%;\n  }\n"])));
var InputName = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-right: 12px;\n  width: 55%;\n  > div {\n    width: 100%;\n  }\n"], ["\n  margin-right: 12px;\n  width: 55%;\n  > div {\n    width: 100%;\n  }\n"])));
var InputUrl = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 30%;\n  > div {\n    width: 100%;\n  }\n"], ["\n  width: 30%;\n  > div {\n    width: 100%;\n  }\n"])));
var TrashIcon = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n  cursor: pointer;\n  font-size: 14px;\n  font-family: Roboto-Bold;\n  padding-top: 10px;\n  text-align: center;\n  width: 15%;\n"], ["\n  color: ", ";\n  cursor: pointer;\n  font-size: 14px;\n  font-family: Roboto-Bold;\n  padding-top: 10px;\n  text-align: center;\n  width: 15%;\n"])), function (props) { var _a, _b; return (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.colors) === null || _b === void 0 ? void 0 : _b.rede24; });
export { MainContainer, InputName, InputUrl, TrashIcon };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
