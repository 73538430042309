var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { mobileDevice, tabletDevice } from "style/constants";
import styled from "styled-components";
var Gallery = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  height: fit-content;\n  justify-content: center;\n  flex-wrap: wrap;\n"], ["\n  display: flex;\n  width: 100%;\n  height: fit-content;\n  justify-content: center;\n  flex-wrap: wrap;\n"])));
Gallery.ImageContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  width: 30%;\n  min-width: 390px;\n  height: 380px;\n  flex-wrap: wrap;\n  position: relative;\n  margin: 8px;\n  @media ", " {\n    max-height: 350px;\n  }\n  @media ", " {\n    width: 45%;\n    min-width: 90px;\n    height: 150px;\n  }\n"], ["\n  display: flex;\n  width: 30%;\n  min-width: 390px;\n  height: 380px;\n  flex-wrap: wrap;\n  position: relative;\n  margin: 8px;\n  @media ", " {\n    max-height: 350px;\n  }\n  @media ", " {\n    width: 45%;\n    min-width: 90px;\n    height: 150px;\n  }\n"])), tabletDevice, mobileDevice);
Gallery.DescriptionImage = styled.p(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 90%;\n  height: fit-content;\n  font-size: 14px;\n  font-family: \"Roboto\";\n  font-weight: bold;\n  color: ", ";\n  position: absolute;\n  bottom: 0;\n  margin: 16px;\n"], ["\n  width: 90%;\n  height: fit-content;\n  font-size: 14px;\n  font-family: \"Roboto\";\n  font-weight: bold;\n  color: ", ";\n  position: absolute;\n  bottom: 0;\n  margin: 16px;\n"])), function (props) { var _a, _b; return (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.colors) === null || _b === void 0 ? void 0 : _b.white; });
export default Gallery;
var templateObject_1, templateObject_2, templateObject_3;
