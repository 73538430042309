var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { CalendarsEditor, Title } from "components";
import { useFieldArray, useFormContext } from "react-hook-form";
import { Segment } from "components/Segment";
import Calendars from "./styled";
var Component = function () {
    var control = useFormContext().control;
    var _a = useFieldArray({
        control: control,
        name: "calendars",
        keyName: "key",
    }), fields = _a.fields, append = _a.append, remove = _a.remove, update = _a.update;
    var addCalendar = function () {
        append({ name: "", url: "", isNew: true });
    };
    var removeCalendar = function (calendar, index) {
        if (calendar.isNew) {
            remove(index);
        }
        else if (!calendar.toDelete) {
            update(index, __assign(__assign({}, calendar), { toDelete: true }));
        }
        else {
            update(index, __assign(__assign({}, calendar), { toDelete: false }));
        }
    };
    var renderCalendars = function () {
        return fields.map(function (calendar, index) {
            return (React.createElement(CalendarsEditor, { removeCalendar: function () { return removeCalendar(calendar, index); }, index: index, key: "calendards-" + index, calendar: calendar }));
        });
    };
    return (React.createElement(Calendars, null,
        React.createElement(Segment, null,
            React.createElement(Title, { variant: "segment" }, "Calendarios"),
            React.createElement(Calendars.Text, null, "Agrega los calendarios de los sitios web para sincronizar la disponibilidad de este bungalow."),
            renderCalendars(),
            React.createElement(Calendars.AddItem, { onClick: addCalendar }, "+ Agregar calendario"))));
};
export default Component;
export { Component as Calendars };
