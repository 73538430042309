var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import { mobileDevice } from "style/constants";
var PriceItem = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: inline-flex;\n  width: 100%;\n  height: 30%;\n  flex-wrap: wrap;\n  gap: 0.5px;\n"], ["\n  display: inline-flex;\n  width: 100%;\n  height: 30%;\n  flex-wrap: wrap;\n  gap: 0.5px;\n"])));
PriceItem.Value = styled.p(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  color: ", ";\n  font-weight: bolder;\n  font-size: 24px;\n  margin: 0rem 0.5rem 0rem 0;\n  font-family: \"Roboto\";\n  @media ", " {\n    width: fit-content;\n    margin: 0;\n    font-size: 18px;\n  }\n"], ["\n  width: 100%;\n  color: ", ";\n  font-weight: bolder;\n  font-size: 24px;\n  margin: 0rem 0.5rem 0rem 0;\n  font-family: \"Roboto\";\n  @media ", " {\n    width: fit-content;\n    margin: 0;\n    font-size: 18px;\n  }\n"])), function (props) { var _a, _b; return (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.colors) === null || _b === void 0 ? void 0 : _b.blue004e; }, mobileDevice);
PriceItem.From = styled.p(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  width: 100%;\n  gap: 0px;\n  font-size: 16px;\n  font-family: \"Roboto\";\n  @media ", " {\n    width: 100%;\n    margin-bottom: 5px;\n    font-size: 14px;\n  }\n"], ["\n  color: ", ";\n  width: 100%;\n  gap: 0px;\n  font-size: 16px;\n  font-family: \"Roboto\";\n  @media ", " {\n    width: 100%;\n    margin-bottom: 5px;\n    font-size: 14px;\n  }\n"])), function (props) { var _a, _b; return (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.colors) === null || _b === void 0 ? void 0 : _b.gray; }, mobileDevice);
PriceItem.Nigth = styled.p(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 16px;\n  font-family: \"Roboto\";\n  @media ", " {\n    font-size: 14px;\n    width: fit-content;\n    margin: 3px 0px 0px 8px;\n  }\n"], ["\n  color: ", ";\n  font-size: 16px;\n  font-family: \"Roboto\";\n  @media ", " {\n    font-size: 14px;\n    width: fit-content;\n    margin: 3px 0px 0px 8px;\n  }\n"])), function (props) { var _a, _b; return (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.colors) === null || _b === void 0 ? void 0 : _b.blue004e; }, mobileDevice);
export default PriceItem;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
