var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect } from "react";
import { useStoreActions, useStoreState } from "state/hooks";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { BankAccountForm, ActionButtons, Title, TaxesForm, Alert, } from "components";
import { MainColumn } from "components/Layout";
import { isNull } from "lodash";
var schema = yup.object().shape({
    config: yup.object().shape({
        account: yup.object().shape({
            name: yup
                .string()
                .transform(function (value) { return (isNull(value) ? undefined : value); })
                .required("El nombre de la cuenta es requerido"),
            number: yup
                .number()
                .transform(function (value) { return (isNaN(value) ? undefined : value); })
                .min(16, "16 digitos para tarjeta.")
                .required("Por favor ingrese un numero de cuenta"),
        }),
        taxes: yup.object().shape({
            iva: yup.number().required("El IVA es requerido"),
            ish: yup.number().required("El ISH es requerido"),
        }),
    }),
});
var Config = function () {
    var methods = useForm({
        defaultValues: {
            config: {},
            resolver: yupResolver(schema),
        },
    });
    var _a = useStoreActions(function (store) { return store; }).config, getConfig = _a.getConfig, saveConfig = _a.saveConfig, updateConfig = _a.updateConfig, cleanMessage = _a.cleanMessage;
    var _b = useStoreState(function (store) { return store; }).config, config = _b.config, isLoading = _b.isLoading, message = _b.message;
    var handleSubmit = methods.handleSubmit, reset = methods.reset;
    useEffect(function () {
        cleanMessage();
        getConfig({
            config: "config",
        });
    }, []);
    useEffect(function () {
        if (!config)
            return;
        var data = JSON.parse(config.value);
        reset({ config: data });
    }, [config]);
    var onSubmit = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var jsonData;
        return __generator(this, function (_a) {
            jsonData = JSON.stringify(data.config);
            if (config === null || config === void 0 ? void 0 : config.id) {
                updateConfig({
                    config: __assign(__assign({}, config), { value: jsonData }),
                });
            }
            else {
                saveConfig({
                    config: {
                        key: "config",
                        value: jsonData,
                    },
                });
            }
            return [2 /*return*/];
        });
    }); };
    var renderMessage = message && (React.createElement(Alert, { message: message.text, type: message.success ? "success" : "danger", cleanError: cleanMessage }));
    return (React.createElement(MainColumn, null,
        React.createElement(Title, null, "Configuraci\u00F3n"),
        renderMessage,
        React.createElement(FormProvider, __assign({}, methods),
            React.createElement(BankAccountForm, null),
            React.createElement(TaxesForm, null)),
        React.createElement(ActionButtons, { handleSubmit: handleSubmit(onSubmit) })));
};
export { Config };
export default Config;
