var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import Component from "..";
import Styled from "../styled";
var TextButton = styled(Component)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", " {\n    background-color: transparent;\n    width: fit-content;\n    color: ", ";\n    border: none;\n    border-color: unset;\n    padding: 5px;\n    cursor: pointer;\n    font-size: 16px;\n    user-select: none;\n    > svg {\n      margin-right: 10px;\n    }\n  }\n"], ["\n  ", " {\n    background-color: transparent;\n    width: fit-content;\n    color: ", ";\n    border: none;\n    border-color: unset;\n    padding: 5px;\n    cursor: pointer;\n    font-size: 16px;\n    user-select: none;\n    > svg {\n      margin-right: 10px;\n    }\n  }\n"])), Styled.Button, function (props) {
    var _a, _b, _c, _d;
    return props.mode === "danger"
        ? (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.colors) === null || _b === void 0 ? void 0 : _b.rede24
        : props.mode === "success"
            ? props.theme.colors.green219
            : (_d = (_c = props.theme) === null || _c === void 0 ? void 0 : _c.colors) === null || _d === void 0 ? void 0 : _d.blue00c5;
});
export default TextButton;
var templateObject_1;
