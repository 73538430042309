var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import { desktop, mobileDevice } from "style/constants";
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  max-width: ", "px;\n  width: inherit;\n  margin: 0 auto;\n  font-family: Roboto;\n"], ["\n  max-width: ", "px;\n  width: inherit;\n  margin: 0 auto;\n  font-family: Roboto;\n"])), desktop);
export var Content = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  flex-wrap: wrap;\n  gap: 16px;\n  width: 100%;\n  margin: 32px 0 80px 0px;\n"], ["\n  display: flex;\n  justify-content: center;\n  flex-wrap: wrap;\n  gap: 16px;\n  width: 100%;\n  margin: 32px 0 80px 0px;\n"])));
export var TwoColumnsLayout = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: grid;\n  grid-template:\n    \"LeftColumn RightColumn\" 100%\n    / 250px calc(100% - 250px);\n  width: 100%;\n"], ["\n  display: grid;\n  grid-template:\n    \"LeftColumn RightColumn\" 100%\n    / 250px calc(100% - 250px);\n  width: 100%;\n"])));
export var LeftColumn = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  grid-area: LeftColumn;\n  padding-right: 16px;\n"], ["\n  grid-area: LeftColumn;\n  padding-right: 16px;\n"])));
export var RightColumn = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  grid-area: RightColumn;\n  max-height: 100vh;\n  overflow-y: auto;\n"], ["\n  grid-area: RightColumn;\n  max-height: 100vh;\n  overflow-y: auto;\n"])));
export var RightColumnNoOverflow = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  grid-area: RightColumn;\n  height: auto;\n"], ["\n  grid-area: RightColumn;\n  height: auto;\n"])));
export var MainColumn = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  max-width: 1280px;\n  width: 90%;\n  min-width: 920px;\n  margin: 1em auto 0;\n"], ["\n  max-width: 1280px;\n  width: 90%;\n  min-width: 920px;\n  margin: 1em auto 0;\n"])));
export var BucketList = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  padding: 20px 0;\n  width: 100%;\n\n  > div {\n    margin-bottom: 20px;\n\n    &:last-of-type {\n      margin: 0;\n    }\n  }\n"], ["\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  padding: 20px 0;\n  width: 100%;\n\n  > div {\n    margin-bottom: 20px;\n\n    &:last-of-type {\n      margin: 0;\n    }\n  }\n"])));
export var ContentStandarMargin = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  margin: 1em auto;\n  max-width: 1280px;\n  min-width: 920px;\n  width: 90%;\n"], ["\n  margin: 1em auto;\n  max-width: 1280px;\n  min-width: 920px;\n  width: 90%;\n"])));
export var AlternativeLayout = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  max-width: 100%;\n  width: 100%;\n  margin: 46px auto 100px;\n  @media ", " {\n    margin-bottom: 0;\n  }\n"], ["\n  max-width: 100%;\n  width: 100%;\n  margin: 46px auto 100px;\n  @media ", " {\n    margin-bottom: 0;\n  }\n"])), mobileDevice);
export var Badge = styled.span(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  background-color: ", ";\n  border-radius: 3px;\n  color: ", ";\n  font-size: 0.7rem;\n  font-weight: 600;\n  padding: 3px;\n"], ["\n  background-color: ", ";\n  border-radius: 3px;\n  color: ", ";\n  font-size: 0.7rem;\n  font-weight: 600;\n  padding: 3px;\n"])), function (props) { return props.color; }, function (props) { return props.theme.colors.whitefff; });
export var GroupText = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 10px;\n\n  span:first-of-type {\n    font-weight: 900;\n  }\n\n  span:last-of-type {\n    color: ", ";\n  }\n\n  button {\n    font-size: 0.8rem;\n    text-align: left;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 10px;\n\n  span:first-of-type {\n    font-weight: 900;\n  }\n\n  span:last-of-type {\n    color: ", ";\n  }\n\n  button {\n    font-size: 0.8rem;\n    text-align: left;\n  }\n"])), function (props) { return props.theme.colors.gray495; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;
