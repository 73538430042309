var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
var Title = styled.h2(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  height: fit-content;\n  color: ", ";\n  font-family: \"Roboto-Bold\";\n  font-size: 24px;\n  text-align: left;\n  margin: 0 0 20px 0;\n"], ["\n  width: 100%;\n  height: fit-content;\n  color: ", ";\n  font-family: \"Roboto-Bold\";\n  font-size: 24px;\n  text-align: left;\n  margin: 0 0 20px 0;\n"])), function (props) { var _a; return (_a = props.theme) === null || _a === void 0 ? void 0 : _a.fonts.secondary; });
var Row = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  justify-content: space-between;\n"], ["\n  display: flex;\n  width: 100%;\n  justify-content: space-between;\n"])));
var InputTitle = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 75%;\n  height: fit-content;\n"], ["\n  width: 75%;\n  height: fit-content;\n"])));
var InputDescription = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  height: fit-content;\n"], ["\n  width: 100%;\n  height: fit-content;\n"])));
var ImageEdit = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  height: auto;\n  width: 20%;\n"], ["\n  height: auto;\n  width: 20%;\n"])));
export { Title, Row, InputTitle, InputDescription, ImageEdit };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
