import { format } from "date-fns";
import es from "date-fns/locale/es";
import en from "date-fns/locale/en-US";
export var formateDate = function (date, isEnglish) {
    return format(date, "EEEE d 'de' LLLL uuuu", { locale: isEnglish ? en : es });
};
export var formateDateNoDay = function (date, isEnglish) {
    return format(new Date(date), "d 'de' LLLL uuuu", {
        locale: isEnglish ? en : es,
    });
};
export var formatDay = function (date) {
    return format(date, "d");
};
export var getMonthYear = function (date, isEnglish) {
    return format(date, "LLLL uuuu", { locale: isEnglish ? en : es });
};
export var formatMonthDay = function (date, isEnglish) {
    return format(date, "LLLL dd", { locale: isEnglish ? en : es });
};
