var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useCallback, createContext } from "react";
export var NotificationContext = createContext({
    notification: null,
    addNotification: function () { },
    removeNotification: function () { },
});
export default function NotificationProvider(_a) {
    var children = _a.children;
    var _b = __read(useState(null), 2), notification = _b[0], setNotification = _b[1];
    var removeNotification = function () { return setNotification(null); };
    var addNotification = function (message, type) {
        return setNotification({ message: message, type: type });
    };
    var contextValue = {
        notification: notification,
        addNotification: useCallback(function (message, type) { return addNotification(message, type); }, []),
        removeNotification: useCallback(function () { return removeNotification(); }, []),
    };
    return (React.createElement(NotificationContext.Provider, { value: contextValue }, children));
}
