var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import { mobileDevice } from "style/constants";
var Carousel = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  overflow: hidden;\n  margin-bottom: 36px;\n"], ["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  overflow: hidden;\n  margin-bottom: 36px;\n"])));
Carousel.Wrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  display: flex;\n  width: 100%;\n"], ["\n  position: relative;\n  display: flex;\n  width: 100%;\n"])));
Carousel.ContentWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  overflow: hidden;\n  width: 100%;\n  height: 100%;\n"], ["\n  overflow: hidden;\n  width: 100%;\n  height: 100%;\n"])));
Carousel.Content = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  height: 300px;\n  justify-content: ", ";\n  transition: transform 250ms linear;\n  transform: translateX(\n    -", "%\n  );\n  @media ", " {\n    transform: translateX(-", "%);\n    justify-content: unset;\n  }\n  > * {\n    max-width: calc(\n      ", "\n    );\n    height: 300px;\n    flex-shrink: 0;\n    flex-grow: 1;\n    margin: 0 8px;\n    @media ", " {\n      max-width: 100%;\n      margin: 0;\n    }\n  }\n"], ["\n  display: flex;\n  height: 300px;\n  justify-content: ", ";\n  transition: transform 250ms linear;\n  transform: translateX(\n    -", "%\n  );\n  @media ", " {\n    transform: translateX(-", "%);\n    justify-content: unset;\n  }\n  > * {\n    max-width: calc(\n      ", "\n    );\n    height: 300px;\n    flex-shrink: 0;\n    flex-grow: 1;\n    margin: 0 8px;\n    @media ", " {\n      max-width: 100%;\n      margin: 0;\n    }\n  }\n"])), function (props) {
    return props.totalImages < 3 ? "center" : "unset";
}, function (props) { return props.currentIndex * (100 / props.imagesToShow); }, mobileDevice, function (props) { return props.currentIndex * 100; }, function (props) {
    var isLess = props.totalImages < 3;
    return isLess ? "390px" : 100 / props.imagesToShow + "%" + " - 16px";
}, mobileDevice);
var ArrowStyles = function (component) { return styled(component)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: absolute;\n  display: flex;\n  width: 36px;\n  height: 36px;\n  align-self: center;\n  border-radius: 100%;\n  background-color: ", ";\n  box-shadow: ", ";\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n  z-index: 1;\n"], ["\n  position: absolute;\n  display: flex;\n  width: 36px;\n  height: 36px;\n  align-self: center;\n  border-radius: 100%;\n  background-color: ", ";\n  box-shadow: ", ";\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n  z-index: 1;\n"])), function (props) { var _a, _b; return (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.colors) === null || _b === void 0 ? void 0 : _b.white; }, function (props) { var _a, _b; return "0px 3px 8px " + ((_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.colors) === null || _b === void 0 ? void 0 : _b.grayced4Transparent); }); };
Carousel.ArrowLeft = ArrowStyles(styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  left: 10px;\n"], ["\n  left: 10px;\n"]))));
Carousel.ArrowRight = ArrowStyles(styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  right: 10px;\n"], ["\n  right: 10px;\n"]))));
Carousel.ImageIndexContainer = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: none;\n  @media ", " {\n    display: inherit;\n    position: absolute;\n    bottom: 0;\n    right: 0;\n    margin: 9px;\n  }\n"], ["\n  display: none;\n  @media ", " {\n    display: inherit;\n    position: absolute;\n    bottom: 0;\n    right: 0;\n    margin: 9px;\n  }\n"])), mobileDevice);
export default Carousel;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
